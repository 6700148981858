import React, { Fragment } from 'react';
import { subDays } from 'date-fns';
import {
    Row,
    Col,
    Container
  } from "reactstrap";
import {
    setAppCustomTitle
} from "../../../reducers/ThemeOptions";
import { connect } from "react-redux";
import DateFilter from '../../../Common/DateFilter';
import Common from '../../Common';
import "./assets/index.css";
import {AgentDashboardSummaryBox} from "./Summary"
import { Graphdashboard } from './Graphdashboard';
import requestApi from '../../../services/requestApi.service';

class AgentDashboard extends React.Component {
    constructor(props) {
        super(props);
        const startDate = subDays(new Date(), 30); // Default to 7 days ago
        const endDate = new Date(); 
        this.state = {
            dateFilter: {
                Start_DateTime: [startDate, endDate],
                noofdays: 7
            },
            showLoader : false,
            summary : [],
            Loader : false,
        };
    }
    componentDidMount= () =>{
        this.getSummaryBox();
    }
    getSummaryBox = async () => {
        this.setState({Loader:true});
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_AGENT_DASHBOARD_SUMMARY",
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data
                this.setState({ summary: data,Loader:false});
            }
        } catch (error) {
            this.setState({Loader:false});
            console.error("Error fetching dashboard summary:", error);
        }
    }

    handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            let dateobj = {};
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            const timeDifference = endDate.getTime() - startDate.getTime();
            const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
            dateobj['Start_DateTime'] = [startDate, endDate];
            dateobj['noofdays'] = numberOfDays;
            this.setState({ 
                dateFilter: dateobj, 
                dashBoardData: [], 
                showLoader: true 
            }, async () => {
                try {
                    // Run all the async functions in parallel
                } catch (error) {
                    console.error('Error during date change operations:', error);
                } finally {
                    // Ensure the loader is turned off after all operations are complete
                    this.setState({ showLoader: false });
                }
            });
        }
    }
    getTitleContent = () => {
        return(
            <>
                <span>Overview</span>
            </>
        )
    }
    

    getTitleBody = () => {
        return(
            <DateFilter handleDateChange={this.handleDateChange} defaultSelectIndex={3}/>
        )
    }
 

    render() {
        let {summary,Loader} = this.state;
        return (
            <Fragment>
                <Common
                    mainLoader={   
                        Loader
                    }
                    title={"Dashboard"}
                    titleContent={
                        this.getTitleContent()
                    }
                    // titleBody={
                    //     this.getTitleBody()
                    // }
                    mainContent={
                        <Fragment>
                            <div className='dashboard-container w-100'>
                                <AgentDashboardSummaryBox summary = {summary}/>
                                <Graphdashboard />
                            </div>
                        </Fragment>
                    }
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentDashboard);
