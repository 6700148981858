import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import Select from "react-select";

const CratioMultiSelectInput = (props) => {
  const initialOptions = props.options;
  const defaultVal = props.value;
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    (async () => {
      let defaultValArr = defaultVal.split(",");
      let selectedOpt = [];
      for (let di = 0; di < defaultValArr.length; di++) {
        let dvalue = defaultValArr[di];
        for (let index in initialOptions) {
          let option = initialOptions[index];
          if (option.value === dvalue) {
            selectedOpt.push(option);
            //break;
          }
        }
      }
      setSelectedOption(selectedOpt);
    })();
  }, []);

  const onChange = async (selectedItems) => {
    setSelectedOption(selectedItems);
    if (props.onChangeEvent) {
      props.onChangeEvent(false, selectedItems, props.attributes);
    }
  };

  let dataValue = "";
  if (selectedOption !== undefined) {
    for (let index in selectedOption) {
      let option = selectedOption[index];
      dataValue += option.value + ",";
    }
    if (dataValue !== "") {
      dataValue = dataValue.slice(0, -1);
    }
  }

  return (
    <div
      className={cx(props.customClass)}
      {...props.attributes}
      data-value={dataValue}
    >
      <Select
        value={selectedOption}
        isMulti
        options={initialOptions}
        className={`basic-multi-select ${props.isError ? "is-invalid" : ""}`}
        classNamePrefix="wa-select"
        onChange={(selectedItems) => onChange(selectedItems)}
        isClearable={true}
        {...props.controls}
      />
    </div>
  );
};

export default CratioMultiSelectInput;
