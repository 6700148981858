import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  Button,
} from 'reactstrap';
import HeaderLogo from "../AppLogo";
import { useTheme } from "../../Context/ThemeContext";
import Link from "../../Link";
import { getAgencyInfo, isMobile } from "../../Common/Appcommon";

const Header = (props) => {
  const { theme } = useTheme();
  let {
    headerBackgroundColor,
    enableMobileMenuSmall,
    enableHeaderShadow,
    titleContent,
    titleBody,
    headerNotifyShow,
    demoPopup,
    demoHandler,
  } = props;
  let agencyLogoInfo = getAgencyInfo('FavIcon');
  let agencyLogo = "";
  if (agencyLogoInfo !== "" && agencyLogoInfo !== undefined && agencyLogoInfo !== null) {
    //agencyLogo = agencyLogoInfo;
    if (agencyLogoInfo.indexOf(",") > 0) {
      let agentLArr = agencyLogoInfo.split(",");
      agencyLogo = agentLArr[1];
    }
  }
  const handleClick = async () => {
    await demoHandler(false); 
  };
  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div"
          className={cx("app-header", {"header-notify" : headerNotifyShow}, headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
            "mobile-app-header" : isMobile()
          })}
          appear={true} timeout={1500} enter={false} exit={false}>
          <div>
            <Link icon={agencyLogo} />
            <HeaderLogo theme={theme} />
            <div className={cx("app-header__content", {
              "header-mobile-open": enableMobileMenuSmall,
            })}>
              <div className="app-header-left">
                <div className="app-header-title">{titleContent}</div>
              </div>
              {demoPopup && (
                <div className="demo-data-popup">
                  <div className="popup-content">
                    <span className="fw-bolder widget-subheading">
                     You are currently viewing demo data
                    </span>
                    <Button
                      className="ms-2 mb-1 theme-bg-g btn close-btn"
                      onClick={handleClick}
                      aria-label="Close"
                    >
                      <i className="fi fi-rr-cross-small"></i>
                    </Button>
                  </div>
                </div>
              )}
              <div className="app-header-right">
                <div className="d-flex align-center">
                  
                  <div className="col-md-12">{titleBody}</div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
