import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Col, Container, Form, FormGroup, InputGroup, Label, Row } from "reactstrap";
import requestApi from "../../services/requestApi.service";
import { setSessionValue, showToast, setLicenseErrorStatus } from "../../Common/Appcommon";
import { useTheme } from "../../Context/ThemeContext";

const CheckoutForm = forwardRef((props, ref) => {
    let { isUsagePage, selectedPlan, paymentDetails, billingDetails, noofMonths } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [cardDetails, setCardDetails] = useState({});
    const [showCardElement, setShowCardElement] = useState(true);
    const [error, setError] = useState(null);
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        if (paymentDetails && (paymentDetails["status"] === "Active" || (paymentDetails["upcoming_plans"] && paymentDetails["upcoming_plans"].length > 0))) {
            getCardDetails();
            setShowCardElement(false);
        }
    }, [paymentDetails]);

    const getCardDetails = async () => {
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "GET_CARD_DETAILS",
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                setCardDetails(data);
            }
        } catch (error) {
            console.error("Error fetching plan pricing:", error);
        }
    }
    
    useImperativeHandle(ref, () => ({
        processPayment,
    }));

    /**
     * @function processPayment send to stripe via api
     * @param {*} event event trigger by on submit
     * @returns {void}
     */
    const processPayment = async () => {
        //event.preventDefault();

        // if manage subscription update without card edit
        if(!showCardElement && isUsagePage){
            return true;
        }

        if(showCardElement){
            const cardElement = elements.getElement(CardNumberElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: billingDetails?.Name ?? "",
                    email: billingDetails?.Email_id ?? "",
                }
            });
            if (error) {
                setError(error.message);
                return;
            }
            const params = {
                ENTITY: "STRIPE_PROCESSOR",
                OPERATION: "CREATE_AUTH_INTENT",
                paymentMethodId: paymentMethod.id,
                billingDetails: billingDetails,
                selectedPlan: selectedPlan,
                noofMonths: noofMonths,
                isAuthOnly: showCardElement && isUsagePage,
                isOtherApiReq: true,
            };
            try {
                let response = await requestApi.processServerRequest(params);
                if (response.issuccess) {
                    let data = response.data;
                    let client_secret = data.client_secret ?? "";
                    // Confirm the Payment Intent to authorize the payment
                    const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(client_secret);
                    if (confirmError) {
                        setError(confirmError.message);
                    } else {
                        // Authorization was successful, proceed with capturing later
                        return capturePayment(paymentIntent.id);
                    }
                }
            } catch (error) {
                console.error("Error fetching plan pricing:", error);
            }
        }else{
            return capturePayment();
        }
        return false;
    };

    /**
     * 
     * @param {String} paymentIntentId paymentid from stripe API
     * @returns {void}
     */
    const capturePayment = async (paymentIntentId = "") => {
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "CAPTURE_PAYMENT_INTENT",
            paymentIntentId: paymentIntentId,
            billingDetails: billingDetails,
            selectedPlan: selectedPlan,
            noofMonths: noofMonths,
            isAuthOnly: isUsagePage,
            isOtherApiReq: true,
        };
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess && response.data){
            if(isUsagePage){
                showToast("Method Change successfully");
            }else{
                setLicenseErrorStatus("false");
                setSessionValue("licenseErrorMsg", "");
            }
            return true;
        }
        return false;
    }

    const handleCardEditCall = () => {
        setShowCardElement(!showCardElement);
    }
    
    const elementStyle = {
        base: {
            color: theme === "dark-theme" ? "#ffffff99" : "", 
        },
        invalid: {
          color: "#fa755a",
        },
    };

    return (
        <Container className="checkout-box-container">
            <Form>
                <Row className="checkout-grp">
                    <Col md={12}>
                        <FormGroup className="input-group-text-box">
                            {
                                showCardElement ? (
                                    <>
                                        {/* <div className="checkout-card">
                                        <div className="border p-3 rounded">
                                            <CardElement options={cardElementOptions}/>
                                        </div>
                                        </div> */}
                                        <Row lg={12} className="mt-3">
                                            <Col lg={12}>
                                                <label className="form-label mt-3">Card number</label>
                                                <div
                                                    style={{
                                                        border: "1px solid #e1e1e1",
                                                        borderRadius: "4px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <CardNumberElement options={{ style: elementStyle, showIcon: true, iconStyle: "solid" }} />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <label className="form-label mt-3">Expiry date</label>
                                                <div
                                                style={{
                                                    border: "1px solid #e1e1e1",
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                }}
                                                >
                                                <CardExpiryElement options={{ style: elementStyle }} />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <label className="form-label mt-3">Security code</label>
                                                <div
                                                style={{
                                                    border: "1px solid #e1e1e1",
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                }}
                                                >
                                                <CardCvcElement options={{ style: elementStyle, showIcon: true, iconStyle: "solid" }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <Label>Card Details</Label>
                                        <InputGroup >
                                            <div className="input-group-text">
                                                <i class="fi fi-rr-credit-card"></i>
                                            </div>
                                            <div className="input-group-text input-group-text-width">
                                                <div className="checkout-span-group">
                                                    <span>{cardDetails.brand}</span>
                                                    <span>****</span>
                                                    <span>****</span>
                                                    <span>****</span>
                                                    <span> {cardDetails.last4} </span>
                                                </div>
                                                <span className="ms-auto"> {cardDetails.exp_month}/{cardDetails.exp_year}</span>
                                            </div>
                                            <div className="input-group-text cursor-pointer">
                                                <i class="fi fi-rc-pencil" onClick={() => handleCardEditCall()}></i>
                                            </div>
                                        </InputGroup>
                                    </>
                                )
                            }
                            { 
                                showCardElement && Object.keys(cardDetails).length > 0 &&
                                <Button className="theme-bg-g mt-2" onClick={() => handleCardEditCall()}>
                                    Cancel
                                </Button>
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center m-2">
                        {error && <div className=" alert alert-danger">{error}</div>}
                    </Col>
                </Row>
            </Form>
        </Container>
    );
});

export default CheckoutForm;