import React, { useState, Fragment, useEffect } from 'react';
import Select from "react-select";
import { Button } from "reactstrap";
import { downloadFile, getContactLinkedData, getTemplatesFormDetails, loadFormData, populateFormDetails, showToast } from '../../Common/Appcommon';
import Loading from '../../Common/Loading';

const ExportPanel = (props) => {
    const contactform = "table_74";
    const [selectedList, setSelectedList] = useState({});
    const [options, setOptions] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const onListChange = async (selected) => {
        //console.log(selected)
        setSelectedList(selected);
    }
    useEffect(() => {
        (async () => {
            await loadListOptions();
        })();
    }, []);

    const loadListOptions = async () => {
        let listOptions = await getContactLinkedData("table_81");
        setOptions(listOptions);
    }

    const onExportContact = async () => {
        setShowLoading(true);
        let formDetail = await populateFormDetails(contactform);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail, "Recently Created");
        let tagList = [selectedList];
        tagList = JSON.stringify(tagList);
        let data = await loadFormData(contactform, formDetail, selectedTemplate, "", "linkspl@List@table_81@includes@"+tagList+"@AND#", true, [], "", 0, "csv");
        if (data.issuccess) {
            let formInfo = formDetail[0];
            let filename = formInfo[2];
            let {formData} = data;
            await downloadFile(filename, [formData]);
            showToast(filename + " Downloaded...");
            setSelectedList({});
            setShowLoading(false);
        }
    }

    return (
        <Fragment>
            <div className='export-panel'>
                <div className='header-desc pt-2 mb-3'>Export your contacts to a CSV file.</div>
                <label className={'form-label'} >Select contact list to export</label>
                <Select value={selectedList}
                    onChange={(e) => {
                        onListChange(e);
                    }} options={options}
                    className={`basic-multi-select`}
                    classNamePrefix="wa-select"
                />
                <div class="d-flex justify-end pt-3">
                    <Loading showLoader={showLoading} mainContent={
                        <Fragment>
                            <Button disabled={Object.keys(selectedList).length === 0} onClick={(e) => onExportContact(e)} size="lg" className='theme-bg-g'>Export CSV</Button>
                        </Fragment>
                    } />
                </div>
            </div>
        </Fragment>
    )
}

export default ExportPanel;