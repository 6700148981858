import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition } from 'react-transition-group';
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
import ReactApexChart from "react-apexcharts";

import { barChartsList } from "./Constant";
import ListTable from "../../Common/ListTable";

export default function DashboardGraph(props){
    const [chartOptionData, setChartOptionData] = useState({});  
    const [chartSeriesData, setChartSeriesData] = useState({});
    const [chartLabel, setChartLabels] = useState({});
    const [chartTypes, setChartTypes] = useState([]);
    const [tablesGraphDatas, setTablesGraphDatas] = useState([]);

    useEffect(() => {
        setChartOptionData({});
        setChartSeriesData({});
        setChartLabels({});
        setChartTypes([]);
        let dashboard = props.dashBoardData;
        dashboard.map((value, index) => {
            setDataForChart(value, barChartsList[index]);
        });
    }, [props.dashBoardData]);
    
    useEffect(() => {
        if (props.tablesGraphDatas !== tablesGraphDatas) {
            setTablesGraphDatas(props.tablesGraphDatas);
        }
    }, [props.tablesGraphDatas, tablesGraphDatas]);
    

    const setDataForChart = (data, chart) => {
        let {type, isHorizontal, colordistributed, tooltip} = chart;
        let isTooltipNeeded = false;
        let tooltipFormate = {};
        let yaxis = {};
        let label = data[3];
        let series = data[1];
        if(tooltip === 'time'){
            isTooltipNeeded = true
            tooltipFormate = {
                enabled: isTooltipNeeded,
                y: {
                    formatter: function (value) {
                        const hours = Math.floor(value / 3600);
                        const minutes = Math.floor((value % 3600) / 60);
                        const seconds = value % 60;
                        return `${hours}h ${minutes}m ${seconds}s`;
                    },
                },
            };
            yaxis = {
                labels: {
                    formatter: function (value) {
                        const hours = Math.floor(value / 3600);
                        const remainingSeconds = value % 3600;
                        const minutes = Math.floor(remainingSeconds / 60);

                        return `${hours}h ${minutes}m`;
                    },
                },
            }
        }else if(tooltip === 'dollar'){
            if(type === "total_spent"){
                tooltipFormate = {
                    enabled: true,
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                            `<div class="cus-tooltip-box">
                                <div class="cus-tooltip-header">
                                    <strong>${w.globals.labels[dataPointIndex]}</strong>
                                    <span style="background-color: ${w.globals.colors[seriesIndex]}; width: 15px; height: 15px; display: inline-block; margin-left: 8px; border-radius:50%;"></span>
                                </div>
                                <div class="cus-tooltip-body">
                                    <span> Call Spent: $${(series[seriesIndex][dataPointIndex])} </span> <br>
                                    <span> Call Minutes: ${label[dataPointIndex][seriesIndex][1]} </span> <br>
                                    <span> Avg. Cost: $${label[dataPointIndex][seriesIndex][0]} </span> <br>
                                </div>
                            </div>`
                        );
                    },
                };
            }else if(type === "goal_achieved"){
                tooltipFormate = {
                    enabled: true,
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                            `<div class="cus-tooltip-box">
                                <div class="cus-tooltip-header">
                                    <strong>${seriesIndex === 0 ? " Goal Complete" : "Goal Incomplete"}</strong>
                                    <span style="background-color: ${w.globals.colors[seriesIndex]}; width: 15px; height: 15px; display: inline-block; margin-left: 8px; border-radius:50%;"></span>
                                </div>
                                <div class="cus-tooltip-body">
                                    <span> ${seriesIndex === 0 ? "Complete" : "Incomplete"}: ${series[seriesIndex][dataPointIndex]}</span> <br>
                                    <span class='hide'> Per Goal Spent: $${label[dataPointIndex][seriesIndex]} </span>
                                </div>
                            </div>`
                        );
                    },
                };
            }
        }

        let options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: isHorizontal,
                    distributed: colordistributed,
                    startingShape: "flat",
                    endingShape: "rounded",
                    dataLabels: {
                        position: 'bottom',
                        enabled: false,
                    },
                }
            },
            xaxis: {
                categories: data[0],
                labels: {
                    rotate: -45,
                    rotateAlways:true,
                    hideOverlappingLabels: true,
                },    
            },
            yaxis: yaxis,
            tooltip: tooltipFormate,
            colors: ['#034737', '#1e90ff', '#ff6347','#ffa500'],
            //colors: ['#DDFBE8', '#F5E8FF', '#FDE3E5','#FDF5DE']
        };
        
        setChartOptionData((prev) => ({ ...prev, [type]: options }));
        setChartSeriesData((prev) => ({ ...prev, [type]: series }));
        setChartLabels((prev) => ({ ...prev, [type]: chart.label }));
        setChartTypes((prev) => [...prev, type]);
    }
    return(
        <Fragment>
            <div className="listview-container pb10 kv-main-wrapper h100per w-100">
                <CSSTransition component="div" classNames="TabsAnimation " appear={true}
                    timeout={0} enter={false} exit={false} >
                        <div className="summary-container">
                            <Row>
                                {   
                                    chartTypes !== undefined && chartTypes.map((type, index) => {
                                        if(typeof chartOptionData[type] !== undefined && typeof chartSeriesData[type] !== undefined){
                                            let label = chartLabel[type];
                                            return(
                                                <>
                                                    <Col lg="6" className="call-log-charts ">
                                                        <Card className={"main-card mb-3"}>
                                                            <CardBody>
                                                                <span className="widget-subheading">{label}</span>
                                                                <div className="bar mt-2">
                                                                    <ReactApexChart 
                                                                        options={chartOptionData[type]} 
                                                                        series={chartSeriesData[type]} 
                                                                        type="bar" 
                                                                        width={"100%"}
                                                                        height={450}
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </>
                                            )
                                        }
                                    })
                                }      
                                {
                                    tablesGraphDatas.length > 0 && tablesGraphDatas.map((val, index) => {
                                    
                                        return(
                                            <>
                                                <Col lg="6" className="call-log-charts">
                                                    <Card className={"main-card mb-3 "}>
                                                        <CardBody>
                                                        <span className="widget-subheading">{val.label}</span>
                                                        <div className="bar mt-2">
                                                            <ListTable isDashboard={true} data={val.data} columns={val.columns} />
                                                        </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </>
                                        )
                                    })
                                }                        
                            </Row>
                        </div>
                </CSSTransition>
            </div> 
        </Fragment>
    )

}
