import React, {Fragment, useEffect} from "react";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import agent_b from "./assets/images/agent_b.png";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import phone from "./assets/images/phone.svg";
import message_1 from "./assets/images/message_1.svg";
import Loading from "../../Common/Loading";

const AgentMainHeader = (props) => {
    let {rowData, fieldNames,isLoading} = props;
    let option = [
        {
            label : "Delete",
            value : "delete"
        },
        {
            label : "Duplicate",
            value : "duplicate"
        },
    ];
    let dropdownoption = [
        {
            label : "Webcall",
            value : "webcall",
            icon : phone
        },
        {
            label : "Chat",
            value : "chat",
            icon : message_1
        },
    ];

    let icon = agent_b;
    // let Gender = rowData['Gender'] !== undefined ? rowData['Gender'] : "";
    // if (Gender.toLowerCase() === "female") {
    //     icon = women_user;
    // }

    return(
        <Fragment>
            <div className="app-header-title">
                <div className='agent-main-container'>
                    {
                        Object.keys(rowData).length > 0 && 
                        <div className='container-heading'>
                            {/* <img src={icon} className="agent-img"/> */}
                            <span className="agent-icon"><i class="fi fi-ss-user-headset"></i></span> 
                            {rowData[fieldNames['Agent_Name']] !== undefined ? rowData[fieldNames['Agent_Name']] : "AI Agent"}
                        </div>
                    }
                </div>
            </div>
            <div className="header-main-body agent-header">
                <Loading 
                    showLoader={isLoading} 
                    mainContent={
                        <div>
                            <button className="btn btn-list theme-bg-g d-flex" onClick={e => props.handleDropDownChange("webcall")}><i class="fi fi-rr-phone-call pr-1"></i>Talk with AI Agent</button>
                        </div>
                    }
                />
               
                { false && <UncontrolledDropdown>
                    <DropdownToggle className="agent-test-btn">
                        Test Again <FontAwesomeIcon icon={faAngleDown} color='#e5e7eb' className='down-arrow theme-c-g me-4'/>
                    </DropdownToggle>
                    <DropdownMenu className="agent-dropdown-lists dropdown-menu-right dropdown-menu-rounded">
                        {
                            dropdownoption && 
                            dropdownoption.map((option, index) => {
                                let label = option.label;
                                let value = option.value;
                                let menuicon = option.icon;
                                return (
                                    <DropdownItem 
                                        className="dropdown-list" 
                                        onClick={e => props.handleDropDownChange(option, value)}
                                    >
                                        <img src={menuicon} />
                                        <span>
                                            {label}
                                        </span>
                                    </DropdownItem>
                                )
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
                }
               
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentMainHeader);