import React, { Fragment, useState, useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import { getSessionValue, clearSessionValue, getLogoutURL, clearLegacyCookie, handleErrorResponse } from '../../Common/Appcommon';
import requestApi from '../../services/requestApi.service';
import cx from "classnames";
import { useTheme } from '../../Context/ThemeContext';

const Profile = () => {
    const [isToggleTheme, setIsToggleTheme] = useState(false);
    const { theme, toggleTheme } = useTheme();
    useEffect(() => {
        if(theme === "dark-theme"){
            setIsToggleTheme(true);
        }
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);
    const logoutApp = async (e) => {
        //let activeSessionCount = 1;
        let params = {
            "ENTITY": "session_check",
        }
        let sessionResponse = await requestApi.processServerRequest(params);
        if (sessionResponse.issuccess) {
            //activeSessionCount = sessionResponse.data['activeSessions'];
            let params = {
                "ENTITY": "logout",
                'logoutfromall': false
            };
            clearLegacyCookie();
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                clearSessionValue();
                if (response.data !== "") {
                    window.location = response.data;
                } else {
                    window.location = getLogoutURL();
                }
            } else {
                handleErrorResponse(response.errormsg);
            }
        }
    }

    const updateThemeSelection = async (theme) => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "UPDATE_THEME",
            "THEME" : theme,
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            
        }
    }

    const onThemeChange = () => {
        setIsToggleTheme(!isToggleTheme);
        let themeOption = "light-theme";
        if (!isToggleTheme) {
            themeOption = "dark-theme";
        }
        toggleTheme(themeOption);
        updateThemeSelection(themeOption);
    }


    let userName = getSessionValue("loginuser");
    let userEmail = getSessionValue("loginemail");
    return (
        <Fragment>
            <Common titleContent={
                <span>Profile</span>
            } mainContent={
                <div className='container'>
                    <div className='agency-details'>
                        <div className='card-fields d-grid'>
                            <span class="heading">Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Email</span>
                            <span className='profile-desc'>{userEmail}</span>
                        </div>
                        <div className='card-fields hide'>
                            <span class="heading">Business Name</span>
                            <span className='profile-desc'>{userName}</span>
                        </div>
                        <div className='card-fields d-grid'>
                            <span class="heading">Theme</span>
                            <div className="switch has-switch mb-2 me-2" data-on-label="ON"
                                data-off-label="OFF" onClick={onThemeChange}>
                                <div className={cx("switch-animate", {
                                    "switch-on": isToggleTheme,
                                    "switch-off": !isToggleTheme,
                                })}>
                                    <input type="checkbox" />
                                    <span className={`switch-left bg-dark ${isToggleTheme ? 'pe-3 ps-3 pt-1' : ''}`}>Dark</span>
                                    <label>&nbsp;</label>
                                    <span className="switch-right bg-dark ps-2 pt-1">Light</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button onClick={(e) => logoutApp(e)} className='btn btn-light theme-bg-g custom-display'><i class="fi fi-bs-sign-out-alt"></i><span className='pl-1'>Log out</span></button>
                        </div>
                    </div>
                </div>
            } />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
