import React, { Fragment, useState, useEffect } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import CountUp from "react-countup"; 
import requestApi from '../../services/requestApi.service';
import Loading from "../../Common/Loading";

const CallLogAnalysisSummary = (props) => {
    let { agent, dateFilter,callType,whatsappFilters,statusFilters,lg = 6 } = props; 
    console.log("whatsappFilter : ",whatsappFilters);  
    console.log("statueFilter : ",statusFilters);  
    const [summaryData, setSummaryData] = useState([]);
    console.log("summaryData : ",summaryData);
    const [loading, setLoading] = useState(true);
    const getSummaryBox = async () => {
        setLoading(true); 
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_CALL_LOG_ANALYSIS_SUMMARY",
            FORMNAME: 'table_37',
            dateFilter: dateFilter,
            agent: agent,
            whatsappFilter: whatsappFilters,
            statueFilter: statusFilters,
            callType:callType,            
            isOtherApiReq: true,
        };

        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                setSummaryData(response.data);  
                setLoading(false); 
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        } finally {
            setLoading(false);  
        }
    };
    console.log("summaryData : ",summaryData);
    useEffect(() => {
        console.log('useffect : ');
        getSummaryBox();
    }, [agent, dateFilter, callType, whatsappFilters, statusFilters]);  

    return (
        <Fragment>
            <Loading 
                showLoader={loading} 
                mainContent={
                    <div className="summary-container">
                        <Row>
                            {summaryData.map((val, index) => {
                                const { header, body, prefix, suffix } = val;
                                const isFloat = body % 1 !== 0;  
                                
                                return (
                                    <Col md="6" lg={lg} key={index}>
                                        <div className="card mb-3 widget-chart text-start">
                                            <div className="widget-chart-content">
                                                <div className="widget-numbers">
                                                    <CountUp
                                                        start={0}
                                                        end={body}  
                                                        separator=","
                                                        decimals={isFloat ? 2 : 0}  
                                                        decimal="."
                                                        prefix={prefix}  
                                                        suffix={suffix}  
                                                        useEasing={false}
                                                        duration={3}  
                                                        className="vi-success-text"
                                                    />
                                                </div>
                                                <div className="footer">{header}</div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                }
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallLogAnalysisSummary);
