import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import { Input } from "reactstrap";

const CratioNumberInput = (props) => {
  const [value, setValue] = useState(!isNaN(props.value) ? props.value : 0);
  useEffect(() => {
    (async () => {})();
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = async (e) => {
    if (e.target.validity.valid) {
      let name = e.target.name;
      let value = e.target.value;
      if (props.onChange) {
        props.onChange(e, { name: name, value: value }, props.attributes);
      }
      setValue(value);
    }
  };
  return (
    <div
      className={cx(props.customClass)}
      {...props.attributes}
      data-value={value}
    >
      <Input
          className={`form-control ${props.inputClass}`}
        pattern="[0-9]*"
        type="text"
        value={value}
        onChange={(e) => onChange(e)}
        {...props.controls}
      />
    </div>
  );
};

export default CratioNumberInput;
