import React, { Fragment,useState,useEffect } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Label,Input,FormText,Button,Col,Row } from "reactstrap";
import { getServerDomainURL,showToast,handleErrorResponse,confirmBoxWithImage } from "../../Common/Appcommon";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import requestApi from '../../services/requestApi.service';
import TooltipItem from "../../Common/ToolTip";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Loading from "../../Common/Loading";



export const  FieldsMapping = ({ formInfo,onBackAction,mappingCol,webhookurl,webhookNameVal,formfildefault,leadstatus }) => {
    const baseUrl = getServerDomainURL();
    const webhookUrl = baseUrl+"/"+webhookurl;
    const [copied,setCopied] = useState(false);
    const [mappingInfo, setMappingInfo] = useState([]);
    const [mappingIds,setMappingIds] = useState([]);
    const [mappingCount,setMappingCount] = useState(0);
    const [currentMappings, setCurrentMappings] = useState([]);
    const [webhookName, setWebhookName] = useState(webhookNameVal);
    const [fieldsobject, setFieldObject] = useState({});
    const [mappingneeded,setMappingNeeded] = useState(false);
    const [mandataryFields, setMandataryFields] = useState("");
    const [pickListItems,setPickListName] = useState([]);
    const leadFormname = "table_74";
    const [assignTo ,setAssignedTo] = useState("");
    const [fieldsOptions, setFieldOptions] = useState([]);
    const [clickedMapId, setClickedMapId] = useState([]);
    // const [formFilOptions, setFormFilOptions] = useState(formfildefault);
    const [statusText, setstatusText] = useState(leadstatus !== "inactive" ? "active" : leadstatus);
    const fieldempty = mappingneeded ?  "Webhook Field/ Default value is required.":"";
    const [googleAdsFieldOptions, setGoogleAdsFieldOptions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [showLoading, setShowLoading] = useState(false);


    const form = useForm();
    const { handleSubmit,control, formState: { errors } } = form;

    let leadFormDetail = [];
    let payload = [];
    let mappingColumns = [];
    let mappingList = [];
    let obj = [];
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['id'] !== undefined && formInfo['id'] !== null ? formInfo['id'] : formInfo[0]) : "");
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['webhookurl'] !== undefined && formInfo['webhookurl'] !== null ? formInfo['webhookurl'] : formInfo[4]) : "");
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['formname'] !== undefined && formInfo['formname'] !== null ? formInfo['formname'] : formInfo[2]) :"");
    obj.push(formInfo !== undefined && formInfo !== null && formInfo !== "" ? (formInfo['identifier'] !== undefined && formInfo['identifier'] !== null ? formInfo['identifier'] : formInfo[3]) : "");
    useEffect(()=>{
        (async () => {
            await getFieldMappingInfo();
            await processEditInfo();
            await getLeadFieldsInfo();
        })();
    },[formInfo]);

    useEffect(()=>{
        (async () => {
            await processEditInfo();
        })();
    },[currentMappings,fieldsobject]);

    const copyUrl = () => {
        setCopied(true);
    }

    const fetchSuggestions = async (query,mappingInfo,mappingId,pickListItems,assignTo) => { 
        let suggestion = [];
        let label = "";
        for (let i = 0; i < mappingInfo.length; i++) {
            label = mappingInfo[mappingId]['fieldselected']['label'];
            if (query !== "" && label !== undefined) {
                if (label === assignTo) {
                    let params = {
                        "ENTITY": "CLOUDTELEPHONYLEADSPROCESS",
                        "OPERATION": 'USER_DROP_DOWN',
                    };
                    let response = await requestApi.processServerRequest(params, "WebV2");
                    suggestion = response.data[0];
                } else {
                    pickListItems.map((item, index) => {
                        if (label === item['label']) {
                            // suggestion = getPickListItems(item['value']);
                        }
                    });
                }
            } else {
                setSuggestions([]);
            }
        }            
        let  filteredSuggestions= [];      
        for(let i = 0; i < suggestion.length; i++) {
            let data = suggestion[i];
            if(data !== undefined && data !== null && data !== ""){
                if (data.toLowerCase().startsWith(query.toLowerCase())) {
                    filteredSuggestions[i] = data;
                }  
            }
        }  
        setSuggestions(filteredSuggestions);
    } 
    const handleSuggestionClick = (data, name,mappingId) => {
    const suggestionText = data.textContent; 
    mappingInfo[mappingId][name] = suggestionText;
    setSuggestions([]);
    };
    const handleWebhookChange = async (e) => {
        setWebhookName(e.target.value);
    }

    const getFieldMappingInfo = async () => {
        if(formInfo !== undefined && formInfo !== null){
            setShowLoading(true);
            let entity = "WEBHOOKSLEADSPROCESS";
            let params = {
                "ENTITY" : entity,
                "OPERATION" : 'GET_FIELD_MAPPING',
                "ID" : obj
            }
            let response = await requestApi.processServerRequest(params, "WebV2");
            if(response.issuccess){
                if(response.data[0].length === 0){
                    setMappingNeeded(false);
                }else{
                    setMappingNeeded(true);
                }
                await setCurrentMappings([...response.data[0]]);
                await setMandataryFields(response.data[1]);
            }else{
                handleErrorResponse(response.errormsg);
            }
        }
        setShowLoading(false);
    }
    const getLeadFieldsInfo = async (name) => {
        let formnameVal = leadFormname;
        let formname = "Contacts";
        if(name !== undefined){
          formname = name;
        }
        let entity = 'WEBHOOKSLEADSPROCESS';
        let params = {
            "ENTITY" : entity,
            "OPERATION" : 'GET_FORMFIELDS',
            "formname" : formname
        }
        let response = await requestApi.processServerRequest(params, "WebV2");
        if(response.issuccess){
            leadFormDetail = response.data;
        }else{
            handleErrorResponse(response.errormsg);
        }
       
        // let skipFields = [
        //     'Lead_ID',
        //     'wsapp_recent_activity',
        //     'wsapp_convo_status',
        //     'wsapp_unread_count',
        //     'wsapp_optin_status',
        //     'wsapp_recent_msg_id',
        //     'wsapp_enabled',
        //     'wsapp_blocked',
        //     'wsapp_opt',
        //     'wsapp_last_delivered',
        //     'wsapp_last_read',
        //     'wsapp_last_replied',
        //     'wsapp_last_sent_msg_id',
        //     'wsapp_last_sent_msg_id',
        //     'wsapp_completed_campaign',
        //     'lead_assigned_at',
        //     'first_touch_at',
        //     'call_did_status'
        // ];  
        if(leadFormDetail !== undefined ){
            let fieldDetails = leadFormDetail;
            let fields = [];
            let fieldsObj = {};
            fieldDetails && fieldDetails.map((field) => {
                let fieldName = field[0];
                let fieldLabel = field[1];
                let fieldType = field[2];
                let isConfigHide = parseInt(field[3]);
                let isViewHide = parseInt(field[4]);
                let defaultValue = field[5];
                if (!isConfigHide && !isViewHide) {
                    if (fieldType !== 'subtable' && fieldType !== 'reference_entityid' && fieldType !== "reference_group" && fieldType !== "form_history") {
                        // if (!skipFields.includes(fieldName)) {
                            if(fieldType === "ComboBox"){  
                                let pickListDetials = defaultValue.split(",");
                                let pickListName = pickListDetials[0]; 
                                    pickListItems.push({value: pickListName, label: fieldLabel});
                            }
                            if (fieldType === "entity_group") {
                                setAssignedTo(fieldLabel);
                            }
                            fields.push({
                                value: fieldName, label: fieldLabel
                            })
                            fieldsObj[fieldName] = {
                                value: fieldName, label: fieldLabel
                            };
                        // }
                    }
                }
                return true;
            });
            setFieldOptions([...fields]);
            await setFieldObject({...fieldsObj});
            await setPickListName([...pickListItems])
        }
    }

    const addField = () => {
        setMappingCount(mappingCount + 1);
    }

    const processEditInfo = async () => {      
        
        let curMappingIds = [];
        let curMappingCount = 0;
        let curGoogleAdsOptions = [];
        let curGoogleAdsFields = [];
        let formvalue = {};

        let params = {
            "ENTITY": "WEBHOOKSLEADSPROCESS",
            "OPERATION" : 'GET_PAYLOADFIELDS_MAPPING',
            "RandomID"  : obj
        }
        let response = await requestApi.processServerRequest(params,"WebV2");
        if(response.issuccess){
            mappingCol = response.data;

        }else{
            handleErrorResponse(response.errormsg);
        }
        for(let i=0;i<Object.keys(mappingCol).length;i++){
            const label = mappingCol[i] !== undefined ? mappingCol[i] : "";
            const value = mappingCol[i] !== undefined ? mappingCol[i] : "";
            curGoogleAdsFields[value] = {label,value};
            curGoogleAdsOptions.push({label,value});
        }
        if(currentMappings && currentMappings.length > 0){
            for(let i=0;i<currentMappings.length;i++){

                let googleadsfield = {};
                let label = '';

                if(currentMappings[i][1].includes('@') && currentMappings[i][1].length > 1){
                    label = currentMappings[i][1].split('@')[0];
                    if(curGoogleAdsFields[label]){
                        googleadsfield = curGoogleAdsFields[label];
                    }
                }

                const fieldname = currentMappings[i][0] ? currentMappings[i][0] : '';
                const defaultval = currentMappings[i][2] ? currentMappings[i][2] : '';
                formvalue = currentMappings[i][3] ? currentMappings[i][3] : '';
                formvalue = {value: formvalue, label: formvalue};
                const selectedField = fieldsobject[fieldname] ? fieldsobject[fieldname] : {};
                mappingInfo[i] = {
                    googleadsfield : googleadsfield,
                    fieldselected : selectedField,
                    fieldname : fieldname,
                    googleadsvalue : label,
                    defaultvalue : defaultval,
                };
                curMappingIds.push(i);
                curMappingCount++;
            }
        }else{
            curMappingIds.push(0);
            curMappingCount++;
        }
        
        await setMappingInfo([...mappingInfo]);
        await setMappingIds([...curMappingIds]);
        await setMappingCount(curMappingCount);
        await setGoogleAdsFieldOptions([...curGoogleAdsOptions]);

    }

    const onSave = async (e) => {
        let mappings = [];
        let mappedFields = [];
        if(mappingInfo){
            let maxMappingId = (+mappingIds[mappingIds.length - 1]);

            for(let i=0;i<=maxMappingId;i++){
                if(mappingInfo[i] !== undefined){

                    if(mappedFields.includes(mappingInfo[i].fieldname)){
                        showToast(`Duplicate values not allowed in Field Name. Please choose different option.`,"top-right","error");
                        return;
                    }
                    mappedFields.push(mappingInfo[i].fieldname);
                    mappings.push(mappingInfo[i]);
                }
            }
        }
        let formfilter = "";
        let formids = formInfo['identifier'];
        let entity = "WEBHOOKSLEADSPROCESS";
        formids = obj;
        formfilter = "Contacts";
        let params = {
            "ENTITY":entity,
            "OPERATION" : 'UPDATE_FIELD_MAPPING',
            "ID" : formids,
            "fields" : mappings,
            "formfilter" : formfilter,
            "webhookname" : webhookName,
            "mappingneeded" : mappingneeded
        }
        let response = await requestApi.processServerRequest(params,"WebV2");
        if(response.issuccess){
            if(response.data != ""){
                let header = response.data[0];
                let message = response.data[1];
                let isConfirm = await confirmBoxWithImage(header,message,"", "", "", true, "Yes", false, false);
                if(isConfirm){
                    params["skipmandotory"] = true;
                    response = await requestApi.processServerRequest(params,"WebV2");
                }
            }
            if(response.data == ""){
                onBackAction();
                showToast(`Saved successfully.`);
            }
        }else{
            handleErrorResponse(response.errormsg);
        }
    }
    const onLeadFieldChange = async (data, name,mappingId) => {
        if(mappingInfo[mappingId] === undefined){
            mappingInfo[mappingId] = {};
        }
        if(name === "defaultvalue"){
            setClickedMapId(mappingId);
        }
        mappingInfo[mappingId][name] = data;
        // fetchSuggestions(data,mappingInfo,mappingId,pickListItems,assignTo);
        if(name === "fieldselected" && data !== undefined && data.value !== undefined){
            mappingInfo[mappingId]['fieldname'] = data.value;
        }else if(name === "googleadsfield" && data !== undefined && data.value !== undefined){
            mappingInfo[mappingId]['googleadsvalue'] = data.value;
        }
        await setMappingInfo([...mappingInfo]);
    }

    for(let i=0;i<mappingCount;i++){

        let mappingId = undefined;
        if(mappingIds[i] !== undefined){
            mappingId = mappingIds[i];
        }else{
            if(mappingIds.length > 0){
                mappingId = (mappingIds[mappingIds.length-1]) + 1;
            }else{
                mappingId = 0;
            }
            mappingIds[i] = mappingId;
            setMappingIds([...mappingIds]);
        }


        mappingList.push(
            <Fragment>
                <Row className={'mt-2 mb-3'}>
                    <Col md={"10"}>
                        <Col md={"10"} className={`offset-1`}>
                            <Row className={`google-ads-mapper`}>
                                <Col md={"4"}>
                                    <Controller
                                        name={`lead_field_${mappingId}`}
                                        control={control}
                                        rules={{ required: (!mappingInfo[mappingId] || (mappingInfo[mappingId] && (mappingInfo[mappingId][`fieldname`] === undefined || mappingInfo[mappingId][`fieldname`] === null || mappingInfo[mappingId][`fieldname`] === ""))) ?`Field name is required.` : false}}
                                        render={({ field }) => {
                                            return (
                                            <Select
                                                {...field}
                                                id={`lead_field_${mappingId}`}
                                                name={`lead_field_${mappingId}`}
                                                onChange={(e) => {
                                                    onLeadFieldChange(e, "fieldselected",mappingId)
                                                    field.onChange(e);
                                                }}
                                                value={
                                                    mappingInfo[mappingId] && mappingInfo[mappingId][`fieldselected`] !== undefined ? mappingInfo[mappingId][`fieldselected`] : {}
                                                }
                                                options={fieldsOptions}
                                                className={`basic-multi-select `}
                                                classNamePrefix="wa-select"
                                                menuPlacement={"auto"}
                                                menuPosition={'absolute'}
                                            />
                                            );
                                        }}
                                    />
                                    {errors[`lead_field_${mappingId}`] && <em className={`error invalid-feedback`}>{errors[`lead_field_${mappingId}`].message}</em>}
                                </Col>
                                <Col md={"4"}>
                                    <div className={`googleads-column-name`}>
                                        <Controller
                                            name={`googleads_field_${mappingId}`}
                                            control={control}
                                            rules={{ required: ((!mappingInfo[mappingId] || (mappingInfo[mappingId] && (mappingInfo[mappingId][`defaultvalue`] === undefined || mappingInfo[mappingId][`defaultvalue`] !== undefined && mappingInfo[mappingId][`defaultvalue`] === "")))
                                                && (!mappingInfo[mappingId] || (mappingInfo[mappingId] && (mappingInfo[mappingId][`googleadsvalue`] === undefined || mappingInfo[mappingId][`googleadsvalue`] !== undefined && mappingInfo[mappingId][`googleadsvalue`] === "")))) ?`${fieldempty}` : false}}
                                            render={({ field }) => {
                                                return (
                                                <Select
                                                    {...field}
                                                    id={`googleads_field_${mappingId}`}
                                                    name={`googleads_field_${mappingId}`}
                                                    onChange={(e) => {
                                                        onLeadFieldChange(e, "googleadsfield",mappingId)
                                                        field.onChange(e);
                                                    }}
                                                    value={
                                                        mappingInfo[mappingId] && mappingInfo[mappingId][`googleadsfield`] !== undefined ? mappingInfo[mappingId][`googleadsfield`] : {}
                                                    }
                                                    options={googleAdsFieldOptions}
                                                    className={`basic-multi-select `}
                                                    classNamePrefix="wa-select"
                                                    menuPlacement={"auto"}
                                                    menuPosition={'absolute'}
                                                />
                                                );
                                            }}
                                        />
                                        {errors[`googleads_field_${mappingId}`] && <em className={`error invalid-feedback`}>{errors[`googleads_field_${mappingId}`].message}</em>}
                                    </div>
                                </Col>
                                <Col md={"4"}>
                                    <div className="faXMark">
                                        <Controller
                                            name={`default_value_${mappingId}`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    name={`default_value_${mappingId}`}
                                                    id="campaign-name"
                                                    placeholder={`Default Value`}
                                                    value={mappingInfo[mappingId] && mappingInfo[mappingId][`defaultvalue`] !== undefined ? mappingInfo[mappingId][`defaultvalue`] : ""}
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        onLeadFieldChange(e.target.value, "defaultvalue", mappingId);
                                                        field.onChange(e);
                                                    }}
                                                    className="form-control"
                                                />
                                            )}
                                        />
                                        {clickedMapId === mappingId && suggestions && (
                                            <ul
                                                className="drop-down-list"
                                                style={{
                                                    position: "absolute",
                                                    top: "calc(100% + 5px)",
                                                    left: 0,
                                                    width: "100%",
                                                    margin: 0,
                                                    padding: 0,
                                                    zIndex: 9999,
                                                }}
                                            >
                                                {suggestions.map((suggestion, index) => (
                                                    <li key={index} onClick={(e) => handleSuggestionClick(e.target, "defaultvalue", mappingId)}>
                                                        {suggestion}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        <i
                                            className={`fi fi-rr-cross-small ml-2 input-variation-remove ${mappingCount === 1 ? "d-none" : ""}`}
                                            onClick={() => removeField(i, mappingId)}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Fragment>
        );
    }

    const removeField = (removedIndex,variationId) => {
        if(mappingInfo[variationId] != undefined && mandataryFields.includes("#"+mappingInfo[variationId][`fieldselected`]['value']+"#")){
            showToast(`This field cannot be removed.`,'top-right','error');
        }else{
            delete mappingInfo[variationId];
            delete mappingIds.splice(removedIndex, 1);
            setMappingCount(mappingCount - 1);
            setMappingIds([...mappingIds]);
            setMappingInfo([...mappingInfo]);
        }
    }
    const shouldShowSaveButton = !mappingneeded;
    const shouldShowForm = statusText === 'active' ? true : false;
    const shouldHideForm = !mappingneeded;


    return(
        <Fragment>
            <div className=""> 
                <div className={'cursor-pointer'} onClick={(e) => {onBackAction(e)}}>
                    <FontAwesomeIcon className={`phone-icon`} icon={faArrowLeft}/> Go Back
                </div>
                <Loading showLoader={showLoading} mainContent={
                    <>
                        <form  name={'googleads_mapping'} onSubmit={handleSubmit(onSave)}>
                            <div className={`mb-3 formname-column-name offset-1 col-8`}>
                                {shouldShowForm && shouldHideForm &&
                                    <>
                                    <br></br>
                                        <Label className='font-bold'>Add Contacts via Webhooks</Label>
                                            <p>Send an API request to the specified webhook URL to add or update contacts. If the contact doesn't exist, it will be added. If it already exists, its details will be updated.</p>
                                    <br></br>
                                    </>
                                }
                                
                                <Label className={`googleads-column-header`} for="webhookUrl">Webhook URL</Label>
                                <div className={`flex`}>
                                    <Col md={12} className=''>
                                        <div className="d-flex align-items-center">
                                            <Input value={webhookUrl} className={`google-ads-webhook-url`} disabled={true} />
                                            <CopyToClipboard onCopy={copyUrl} text={webhookUrl}>
                                                <Button className='theme-bg-g'>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </Button>
                                            </CopyToClipboard>
                                        </div>
                                        {copied && (
                                            <FormText color="success" className="mb-2 ">Text has been copied.</FormText>
                                        )}
                                    </Col>
                                </div>
                                <div className={`formname-column-name`}>
                                    <Col md={"10"}>
                                        <div className={`googleads-column-header`}>{`Webhook Name`}</div>
                                    </Col>
                                    <Controller
                                        name="webhook_name"
                                        control={control}
                                        rules={{
                                            required: webhookName !== undefined && webhookName !== null && webhookName !== "" ? false : "Webhook name is required.", 
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <div>
                                                <Input 
                                                    {...field}
                                                    className="googleads-column-heade"
                                                    md="10"
                                                    type="text"
                                                    id="webhook_name"
                                                    autoComplete="off"
                                                    value={webhookName || ""} 
                                                    onChange={(e) => {
                                                        field.onChange(e); 
                                                        handleWebhookChange(e); 
                                                    }}
                                                />
                                                {error && (
                                                    <em className="error invalid-feedback">{error.message}</em>
                                                )}
                                            </div>
                                        )}
                                    />
                                    <Col md={"10"}>
                                        <div className={`googleads-column-header`}>{`Module Name`}</div>
                                    </Col>
                                        <Input value={"Contacts"} disabled={true}/>
                                </div>  
                            </div>
                            {shouldShowForm && !shouldHideForm && ( 
                                Object.keys(mappingList).length > 0 && (
                                    <div className={`ps-3 pt-2 pb-2 mb-3 mt-3 googleads-mapping-wrapper  "webhooks-mapping-wrapper"}`}>
                                        <Row className={'mt-2 mb-3'}>
                                            <Col md={"10"}>
                                                <Col md={"10"} className={`offset-1`}>
                                                    <Row>
                                                        <Col md={"4"}>
                                                            <div className={`googleads-column-header`}>{`Contact Fields`}
                                                            <span className="ms-2 mt-1">
                                                            <TooltipItem id={"1"} text={"The mandatory fields will be listed by default only for the first time."} content={<i class="fi fi-rr-info"></i>} /></span></div>
                                                        </Col>
                                                        <Col md={"4"}>
                                                            <div className={`googleads-column-header ps-4`}>{`Webhook Fields`}</div>
                                                        </Col>
                                                        <Col md={"4"}>
                                                            <div className={`googleads-column-header ps-4`}>{`Fallback Value`}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>

                                        {mappingList.map((column) => {
                                            return column;
                                        })}
                                        <Row className={'mt-2 mb-3'}>
                                            <Col md={'11'} className={`custom-pr-2`}>
                                                <Col md={'10'} className={`offset-9`}>
                                                    <div className={`webhooks-btn theme-bg-g mt-3`} onClick={addField} >
                                                        <FontAwesomeIcon icon={faPlus} className={`mr-1`}/> Add New Field
                                                    </div>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                            <div className={`text-center webhooksave ${shouldShowSaveButton ? ' mt-5': ""}`}>
                            <Button  type={'submit'} className={"theme-bg-g"}>
                                {`${shouldShowSaveButton ? 'Save' : 'Save Mapping'}`}
                            </Button>
                            </div>
                        </form>
                    </> 
                }/>
            </div>
        </Fragment>

    );
}

export default FieldsMapping;