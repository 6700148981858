import React, { Fragment} from 'react';
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";

const Loading = (props) => {
    let { showLoader, mainContent, customLoading } = props;
    
    let loaderType = "ball-clip-rotate";
    if(customLoading !== undefined){
        loaderType = customLoading;
    }
    return (
        <Fragment>
            <LoadingOverlay tag="div" active={showLoader}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#f5f5f5",
                        opacity: 0.5,
                        color: "red"
                    }),
                }}
                spinner={
                    <Loader 
                        styles={{
                            background: "#fff",
                            color: "#000000",
                            borderColor: "#000000"
                        }} 
                        color={"#000000"} 
                        active={showLoader} 
                        type={loaderType} 
                    />
                }
            >
                <div className='loading-container'>
                    {mainContent}
                </div>
            </LoadingOverlay>
        </Fragment>
    )
}

export default Loading;
