import React, { Fragment, Component } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";

//internal
import requestApi from "../../services/requestApi.service";
import { handleErrorResponse, parseJSON, setSessionValue, fetchAgencyDetails, findTimeDiff } from "../../Common/Appcommon";
import '../site.css';
import Link from "../../Link";
import AppLogo from "../../assets/utils/images/logo.png";

export default class InviteForm extends Component {
  constructor(props) {
    super(props);
    this.recoverFormRef = React.createRef();
    let invite_code = props.match.params.invite_code !== undefined && props.match.params.invite_code !== "" ? props.match.params.invite_code : "";
    const decodedData = atob(invite_code);
    const userData = parseJSON(decodedData);
    this.state = {
      errorNewPwd: { message: "", isValid: true },
      errorCnfPwd: { message: "", isValid: true },
      errorValidation: { message: "", isValid: false },
      userData: userData,
      brandLogo: "",
      agencyLogo: [],
      isLinkExpired: false
    };
  }

  componentDidMount = async () => {
    document.title = 'Signup Form | VM';
    let agencyData = await fetchAgencyDetails();
    if (agencyData.length > 0) {
      this.setState({
        agencyLogo: agencyData
      })
    } else {
      this.setState({
        brandLogo: process.env.REACT_APP_LIGHT_LOGO
      })
    }
    let { userData } = this.state;
    if (userData.invited_time !== undefined) {
      let diffHrs = await findTimeDiff(userData.invited_time);
      if (diffHrs >= 48) {
        this.setState({ isLinkExpired: true });
      }
    }
    await this.redirectToLegacy();

  };

  redirectToLegacy = async () => {
    const { userData } = this.state;
    let appid = userData.appid;
    let userid = userData.user;
    let params = {
      "ENTITY": "AIPROCESSOR",
      "OPERATION": "checkUserExists",
      'crmappid': appid,
      'crmuserid': userid,
    };
    let response = await requestApi.processServerRequest(params);
    if (response.issuccess) {
      window.location = "#/login";
    }
  }

  loginAction = async (e) => {
    e.preventDefault();
    let newPwdVal = this.recoverFormRef.current.querySelector(
      "input[name='newpassword']"
    );
    console.log("new password:", newPwdVal.value);

    let cnfPwdVal = this.recoverFormRef.current.querySelector(
      "input[name='cnfpassword']"
    );
    console.log("conf password:", cnfPwdVal.value);

    let newpwdError = { message: "", isValid: true };
    let cnfpwdError = { message: "", isValid: true };
    let validationError = { message: "", isValid: false };

    if (newPwdVal.value === "") {
      newpwdError.message = "New password can't be empty!";
      newpwdError.isValid = false;
    }

    if (cnfPwdVal.value === "") {
      cnfpwdError.message = "Confirm password can't be empty!";
      cnfpwdError.isValid = false;
    }else if (cnfPwdVal.value.length < 8) {
      cnfpwdError.message = "Password must be at least 8 characters long!";
      cnfpwdError.isValid = false;
    } else if (!/[0-9]/.test(cnfPwdVal.value)) {
      cnfpwdError.message = "Password must include at least one numeric digit!";
      cnfpwdError.isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(cnfPwdVal.value)) {
      cnfpwdError.message = "Password must include at least one special character!";
      cnfpwdError.isValid = false;
    } else if (/\s/.test(cnfPwdVal.value)) {
      cnfpwdError.message = "Password cannot contain spaces!";
      cnfpwdError.isValid = false;
    }else if (!/[a-zA-Z]/.test(cnfPwdVal.value)) {
      cnfpwdError.message = "Password must include at least one alphabetic character!";
      cnfpwdError.isValid = false;
    } else {
      cnfpwdError.message = "";
      cnfpwdError.isValid = true;
    }

    if (cnfpwdError.isValid && newpwdError.isValid) {
      if (cnfPwdVal.value !== newPwdVal.value) {
        validationError.message = "New & Confirm password should be same!";
        validationError.isValid = false;
      } else {
        validationError.isValid = true;
      }
    }
    if (validationError.isValid) {
      const { userData } = this.state;
      setSessionValue("appid", userData.appid);
      setSessionValue('loginusername', userData.username);
      setSessionValue('loginuserid', userData.usertableid);
      let queryParams = {
        "ENTITY": "AIPROCESSOR",
        "OPERATION": "SAVE_USER_DETAIL",
        "SIGNUP DATA": userData,
        "NEW_PWD": cnfPwdVal.value,

      };

      let response = await requestApi.processServerRequest(queryParams);
      if (response.issuccess) {
        let redirectUrl = '/#/login';
        window.location.href = redirectUrl;
        //clearLegacyCookie();
        // let appid = userData.appid;
        // let usertableid = response.data;
        // let params = {
        //     "ENTITY": "ai_login",
        //     "APPID": appid,
        //     "usertableid": usertableid,
        // };
        // setSessionValue("appid",appid);
        // let res = await requestApi.processServerRequest(params);
        // if (res.issuccess) {
        //   let loginres = res.data;
        //   let menus = loginres[5];
        //   let userName = loginres[1];
        //   let email = loginres[2];
        //   let orgData = loginres[4];
        //   let redirectUrl = '/#/login';
        //   setSessionValue('menus', JSON.stringify(menus));
        //   setSessionValue('loginemail', email);
        //   setSessionValue('loginuser', userName);
        //   updateOrganizationInfo(orgData);
        //   if (window.self !== window.top) {
        //       window.parent.location.href = redirectUrl;
        //   } else {
        //       window.location.href = redirectUrl;
        //   }
        // }else{
        //   handleErrorResponse("Technical Issues Occured.");
        // }
      } else {
        handleErrorResponse("Some technical errors occur during login. Please try again.");
      }

    }
    newPwdVal.value = "";
    cnfPwdVal.value = "";
    this.setState({
      errorNewPwd: newpwdError,
      errorCnfPwd: cnfpwdError,
      errorValidation: validationError,
    });
  };


  render() {
    let { brandLogo, agencyLogo, isLinkExpired } = this.state;
    let favIcon = "";
    if (agencyLogo.length > 0) {
      brandLogo = agencyLogo[2];
      favIcon = agencyLogo[1];
    }
    if (brandLogo === "") {
      return (
        <Fragment>...</Fragment>
      )
    }
    return (

      <Fragment>
        <div className="h-100">
          <Fragment>
            <Link icon={favIcon} />
            <div className="h-100 bg-dark-shadow bg-animation">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="6" className="mx-auto app-login-box">
                  <div className="app-logo-inverse mx-auto mb-3 text-center" />
                  <div className="modal-dialog w-100">
                    <div className="modal-content">
                      {!isLinkExpired &&
                        <Fragment>
                          <div className="modal-header">
                            <div className="h5 modal-title">
                              Set Password To Login
                            </div>
                          </div>
                          <div className="modal-body">

                            <div ref={this.recoverFormRef}>
                              <Form onSubmit={this.loginAction}>
                                <Row form>
                                  <Col md={12}>
                                    <FormGroup>
                                      <Label for="newPasswordLbl">New Password</Label>
                                      <Input
                                        className={
                                          !this.state.errorNewPwd.isValid
                                            ? "is-invalid form-control-blue"
                                            : "form-control-blue"
                                        }
                                        type="password"
                                        name="newpassword"
                                        id="newpassword"
                                        placeholder="New Password"
                                      />
                                      {!this.state.errorNewPwd.isValid && (
                                        <Label className="errors" for="errorNewPassword">
                                          {this.state.errorNewPwd.message}
                                        </Label>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <FormGroup>
                                      <Label for="cnfPasswordLbl">Confirm Password</Label>
                                      <Input
                                        className={
                                          !this.state.errorCnfPwd.isValid
                                            ? "is-invalid form-control-blue"
                                            : "form-control-blue"
                                        }
                                        type="password"
                                        name="cnfpassword"
                                        id="cnfpassword"
                                        placeholder="Confirm Password"
                                      />
                                      {!this.state.errorCnfPwd.isValid && (
                                        <Label className="errors" for="errorCnfPassword">
                                          {this.state.errorCnfPwd.message}
                                        </Label>
                                      )}
                                      {!this.state.errorValidation.isValid && (
                                        <Label className="errors" for="errorValidation">
                                          {this.state.errorValidation.message}
                                        </Label>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="divider" />
                                <div className="mt-4 d-flex align-items-center">
                                  <div className="ms-auto">
                                    <Button size="lg" type="submit" className='theme-bg-g' color="dark">
                                      Login to Dashboard
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Fragment>
                      }
                      {
                        isLinkExpired &&
                        <div className="modal-header text-center">
                          <div className="h5 modal-title text-center w-100">
                            <span>This Link was Expired please contact your admin.</span>
                          </div>
                        </div>
                      }
                    </div>


                  </div>
                </Col>
              </div>
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}
