import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, InputGroup, Row, Col } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import Switch from "react-switch";
import { stepConfigurations } from '../assets/css/constants';
import requestApi from '../../../services/requestApi.service';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const AddPlanModal = ({ isOpen, toggle, newPlanData, account_id, row, account_appid, isSubAccountView}) => {
    const { control, handleSubmit, formState: { errors }, trigger } = useForm();
    const [currentStep, setCurrentStep] = useState(0);
    const [rowData, setRowData] = useState(row);
    const emojiList = ['✔️', '👉', '😍', '🤝', '⏰', '🔥', '❌'];
    const [showEmojiIndex, setShowEmojiIndex] = useState(-1);

    const nextStep = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            setCurrentStep((prev) => Math.min(prev + 1, stepConfigurations.length - 1));
        }
    };

    const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

    const onSubmit = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_PLAN_PRICING",
            account_id: account_id,
            FORMINPUT: rowData,
            account_appid: account_appid,
            isSubAccountView: isSubAccountView,
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                newPlanData(rowData);
                toggle();
            }
        } catch (error) {
            console.error("Error save to Plan:", error);
        }
    };

    const handleChange = (e, type, name, maxlength) => {
        let value = "";
        if(type === "toggle"){
            setRowData((prevFormData) => ({
                ...prevFormData,
                [name]: e
            }));
        }else{
            value = e.target.value;
            if (type === "number"){
                if(/^\d*\.?\d*$/.test(value)){
                    setRowData((prevFormData) => ({
                        ...prevFormData,
                        [name]: value
                    }));
                }
                //showToast("Error! Only integers are allowed.", "top-right", "warning");
            }else if(maxlength >= value.length){
                setRowData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
            }
        }
        
    };
    
    const toggleEmojiOptions = (index) => {
        if(showEmojiIndex === index){
            index = -1;
        }
        setShowEmojiIndex(index);
    };

    const handleEmojiClick = (emoji) => {
        let name = "cus_icon_" + showEmojiIndex;
        setShowEmojiIndex(-1);
        setRowData((prevFormData) => ({
            ...prevFormData,
            [name]: emoji
        }));
    };

    const popover = (
        <Popover id="emoji-popover">
            <Popover.Body>
                {emojiList.map((emoji, index) => (
                    <span
                        key={index}
                        style={{ margin: '0 5px', fontSize: '1.5em', cursor: 'pointer' }}
                        onClick={() => handleEmojiClick(emoji)}
                    >
                        {emoji}
                    </span>
                ))}
            </Popover.Body>
        </Popover>
    );

    const renderFields = (fields) => {
        return fields.map(({ name, label, type, placeholder, requiredmsg, icon, options, suffix, groupclass, group, maxlength, minValue}, index) => (
            <Row className='add-plan-madal' key={name}>
                <FormGroup className={groupclass}>
                    <Label for={name}>{requiredmsg !== "" ? label : label + " (Optional)"}</Label>
                    <Controller
                        name={name}
                        control={control}
                        rules={{
                            required: (rowData[name] === undefined || rowData[name] === "") && (requiredmsg === undefined || requiredmsg !== "") ? requiredmsg : false,
                            validate: {
                                inRange: (value) => {
                                    if(!value){
                                        value = rowData[name];
                                    }
                                    if(type === "number" && minValue){
                                        const numberValue = parseFloat(value, 10);
                                        if (numberValue < minValue || !numberValue) return "Must be filled and greater than " + minValue;
                                    }
                                    return true;
                                },
                            },
                        }}
                        render={({ field }) => (
                            <Row>
                                <Col lg="12">
                                    <InputGroup>
                                        {
                                            group !== "custom_desc" && icon && (
                                                <div className="input-group-text">
                                                    <img src={icon} alt={`${label} icon`}/>
                                                </div>
                                            )
                                        }
                                        {
                                            group === "custom_desc" && icon && (
                                                <OverlayTrigger 
                                                    show={showEmojiIndex === index} 
                                                    trigger="click" 
                                                    placement="top" 
                                                    overlay={popover}
                                                    onToggle={e => {toggleEmojiOptions(index, name)}}
                                                    >
                                                    <div className="input-group-text cursor-pointer">
                                                        {
                                                            rowData['cus_icon_' + (index)] !== undefined ? (
                                                                rowData['cus_icon_' + (index)]
                                                            ) : (
                                                                emojiList[0]
                                                            )
                                                        }
                                                    </div>
                                                </OverlayTrigger>
                                            )
                                        }
                                        {
                                            type === "select" ? (
                                                <Select
                                                    {...field}
                                                    name={name}
                                                    options={options}
                                                />
                                            ) : (
                                                type === "toggle" ? (
                                                    <Switch 
                                                        height={15} 
                                                        width={30} 
                                                        name ={name} 
                                                        onColor={"#23ae73"} 
                                                        checked={rowData[name]} 
                                                        onChange={e => {handleChange(e, type, name)}}
                                                    />
                                                ) : (
                                                    <Input
                                                        {...field}
                                                        type={"text"}
                                                        placeholder={placeholder}
                                                        onChange={e => {handleChange(e, type, name, maxlength)}}
                                                        value={
                                                            group === "custom_desc" && 
                                                            rowData["cus_desc_" + (index)] !== undefined && 
                                                            rowData["cus_desc_" + (index)]["cus_desc_" + (index)] !== undefined ? 
                                                            rowData["cus_desc_" + (index)]["cus_desc_" + (index)] : (rowData[name] ? rowData[name] : "")
                                                        }
                                                    />
                                                )
                                            )
                                        }
                                        {
                                            suffix !== undefined && suffix !== "" && (
                                                <div className="input-group-text">
                                                    {suffix}
                                                </div>
                                            )
                                        }
                                    </InputGroup>
                                </Col>
                            </Row>
                        )}
                    />
                    {errors[name] && <span className="text-danger">{errors[name].message}</span>}
                </FormGroup>
            </Row>
        ));
    };

    const renderStepContent = () => {
        const stepConfig = stepConfigurations[currentStep];
        return (
            <div>
                {renderFields(stepConfig.fields)}
                {/* {stepConfig.customFields ? renderFields(customFields(stepConfig.customFields)) : null} */}
            </div>
        );
    };

    /* const getNavStates = (indx, length) => {
        let styles = [];
        for (let i = 0; i < length; i++) {
            if (i < indx) {
                styles.push("done");
            } else if (i === indx) {
                styles.push("doing");
            } else {
                styles.push("todo");
            }
        }
        return { current: indx, styles: styles };
    }; */

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={toggle}> {rowData && rowData.plan_id ? 'Edit Plan' : 'Create New Plan'}</ModalHeader>
            <Form>
                <ModalBody>
                    {renderStepContent()}
                </ModalBody>
                <ModalFooter>
                    <a className='cursor-pointer font-bold theme-c-g' onClick={toggle}>Cancel</a>
                    {currentStep > 0 && <Button className='theme-bg-g' onClick={prevStep}>Previous</Button>}
                    {currentStep < stepConfigurations.length - 1 ? (
                        <Button className='theme-bg-g' onClick={nextStep}>Next</Button>
                    ) : (
                        <Button className='theme-bg-g' onClick={handleSubmit(onSubmit)}>Submit</Button>
                    )}
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AddPlanModal;
