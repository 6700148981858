//External
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import Select from "react-select";

//internal
import CratioSearchDropDown from "../Common/CratioFields/CratioSearchDropDown";
import CratioNumberInput from "../Common/CratioFields/CratioNumberInput";
import CratioDatePicker from "../Common/CratioFields/CratioDatePicker";
import CratioTextInput from "../Common/CratioFields/CratioTextInput";
// import CratioSelectInput from "../Common/CratioFields/CratioSelectInput";
import CratioLookup from "../Common/CratioFields/CratioLookup";
import CratioMultiSelectInput from "../Common/CratioFields/CratioMultiSelectInput";
import { formatDate, getContactLinkedData, getPickListItems, loadListOptions } from "../../Common/Appcommon";

const {
  activities_date_operators_1,
  activities_date_operators_2,
  activities_operators,
  dateOperators,
  durationOperators,
  modify_dateOperators,
  numeric_operators,
  picklist_operators,
  recordModifyOptions_1,
  recordModifyOptions_2,
  text_operators,
  activities_operators_1,
  durationOperatorsWithWeeks,
  durationWithMin,
  calls_operators_1,
  durationDateTime,
  subtable_options
} = require("./Constants");

const SearchComponent = ({
  filterIndex,
  details,
  removeFilterComp,
  conditionChangeComp,
  defaultValueMapper,
  pushChanges,
  filterInfo,
  addFilterComp,
  isLastFilter,
  fieldChangeAction,
  filterType,
  searchStringType,
  subtableValueOptions
}) => {

  const [valueMapper, setValueMapper] = useState(defaultValueMapper);
  const searchCompEl = useRef(null);
  const fieldetails = details.fieldDetail ?? [];
  const errors = details.errors ?? [];
  const [condition, setCondition] = useState("AND");
  const [fieldOptions, setFieldOptions] = useState([]);
  const [defaultField, setDefaultField] = useState({});

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        let vMapper = await fetchValueMapperFromDOM();
        pushChanges(vMapper, filterIndex);
      })();
    }, 100);
    return () => { };
  }); //called at every component render

  useEffect(() => {
    (async () => {
      await prepareCondition(details);
      //execute after component rendered
    })();
    return () => {
      //execute after component removed
    };
  }, []); //only once called after first render

  useEffect(() => {
    (async () => {
      await prepareFilterFieldOptions(filterInfo, fieldetails);
      //execute after component rendered
    })();
    return () => {
      //execute after component removed
    };
  }, []); //only once called after first render

  const prepareFilterFieldOptions = async (filterInfo, dfd) => {
    let optionList = [];
    let defaultOption = {};
    let defaultFieldName = dfd.length > 0 ? dfd[2] : "";
    for (let fi = 0; fi < filterInfo.length; fi++) {
      let fd = filterInfo[fi];
      let formname = fd[1];
      let fieldName = fd[2];
      let fieldLabel = fd[3];
      let fieldType = fd[4];
      if (defaultFieldName === fieldName) {
        defaultOption = { value: fieldName, label: fieldLabel, fd: fd };
      }
      optionList.push({ value: fieldName, label: fieldLabel, fd: fd });
    }
    setFieldOptions(optionList);
    setDefaultField(defaultOption);
  }

  const prepareCondition = async (details) => {
    if (details.condition !== undefined) {
      let conditionVal = details.condition['condition_0'];
      setCondition(conditionVal);
    }
  }

  const onConditionValueChange = (e, index) => {
    let conditionval = "AND";
    if (condition === "OR") {
      conditionval = "AND";
    } else if (condition === "AND") {
      conditionval = "OR";
    }
    setCondition(conditionval);
    conditionChangeComp(conditionval, index);
  }

  const fetchValueMapperFromDOM = async () => {
    let vMapper = {};
    if (searchCompEl.current) {
      let childrens =
        searchCompEl.current.getElementsByClassName("fc-field dIB");
      for (let index in childrens) {
        let child = childrens[index];
        if (child instanceof HTMLElement && child.hasAttribute("data-type")) {
          let dataKey = child.getAttribute("data-key");
          let dataValue = child.getAttribute("data-value");
          let dataType = child.getAttribute("data-type");
          vMapper[dataKey] = dataValue;
        }
      }
    }
    return vMapper;
  };
  const onValueChange = async (e, data, attributes) => {
    let dataType = attributes["data-type"] ? attributes["data-type"] : false;
    let dataKey = attributes["data-key"] ? attributes["data-key"] : false;
    let dataWgt = attributes["data-wgt"] ? attributes["data-wgt"] : false;
    let value = false;
    let tmpMapper = { ...valueMapper };
    if (dataWgt && dataWgt === "dropdown") {
      value = data.value;
    } else if (dataWgt && dataWgt === "text") {
      value = data.value;
    } else if (dataWgt && dataWgt === "number") {
      value = data.value;
    } else if (dataWgt && dataWgt === "date") {
      value = formatDate(data, "yyyy-mm-dd");
    } else if (dataWgt && dataWgt === "datetime") {
      value = formatDate(data);
    } else if (dataWgt && dataWgt === "lookup") {
      value = data.label;
    } else if (dataWgt && dataWgt === "multiselect") {
      value = [];
      for (let index in data) {
        let option = data[index];
        value.push(option.value);
      }
    } else if (dataWgt && dataWgt === "multiselect-subtable") {
      value = data;
    }
    if (fieldetails[4] === "custom_filter" && fieldetails[2] === "Activities") {
      if (dataKey === "operator_0") {
        if (tmpMapper[dataKey] !== value) {
          //is changed
          tmpMapper = {};
          /*if (value === "overdue") {
            tmpMapper["operator_1"] = "activities";
          } else if (value === "activities_due") {
            tmpMapper["operator_3"] = "due_in";
            tmpMapper["value_0"] = "2";
            tmpMapper["value_1"] = "days";
          } else*/ if (
            value === "without_any_activities" ||
            value === "without_open_activities" ||
            value === "activities_due" ||
            value === "Overdue"
          ) {
            tmpMapper["value_0"] = "2";
            tmpMapper["value_1"] = "days";
          }
        }
      }
    }

    tmpMapper[dataKey] = value;
    setValueMapper({ ...tmpMapper });
  };

  const isTextFilter = (fd) => {
    if (
      fd[4] === "custom_filter" ||
      fd[4] === "Int" ||
      fd[4] === "Currency" ||
      fd[4] === "date" ||
      fd[4] === "Date_Time" ||
      fd[4] === "ComboBox" ||
      fd[4] === "reference" ||
      fd[4] === "entity_group" ||
      fd[4] === "form_history"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isComponentRequired = (component) => {
    let isRequired = false;
    if (
      fieldetails[4] === "custom_filter" &&
      fieldetails[2] === "Record_Action"
    ) {
      if (component === "value_0" || component === "value_1") {
        /*if (
          valueMapper["operator_2"] !== undefined &&
          valueMapper["operator_2"] === "in_the_last"
        ) {*/
        isRequired = true;
        //}
      }
    } else if (
      fieldetails[4] === "custom_filter" &&
      fieldetails[2] === "Activities"
    ) {
      isRequired = true;
      /*if (component === "value_0" || component === "value_1") {
        if (
          valueMapper["operator_2"] !== undefined &&
          valueMapper["operator_2"] === "in_the_last"
        ) {
          isRequired = true;
        } else if (
          valueMapper["operator_3"] !== undefined &&
          valueMapper["operator_3"] === "due_in"
        ) {
          isRequired = true;
        }
      } else if (component === "value_2") {
        if (
          valueMapper["operator_2"] !== undefined &&
          (valueMapper["operator_2"] === "on_date" ||
            valueMapper["operator_2"] === "before_date" ||
            valueMapper["operator_2"] === "between" ||
            valueMapper["operator_2"] === "after_date")
        ) {
          isRequired = true;
        }
      } else if (component === "value_3") {
        if (
          valueMapper["operator_2"] !== undefined &&
          valueMapper["operator_2"] === "between"
        ) {
          isRequired = true;
        }
      } else if (component === "operator_1") {
        if (
          valueMapper["operator_0"] !== undefined &&
          valueMapper["operator_0"] === "overdue"
        ) {
          isRequired = true;
        }
      } else if (component === "operator_2") {
        if (
          valueMapper["operator_0"] !== undefined &&
          (valueMapper["operator_0"] === "without_any_activities" ||
            valueMapper["operator_0"] === "activities_done")
        ) {
          isRequired = true;
        }
      } else if (component === "operator_3") {
        if (
          valueMapper["operator_0"] !== undefined &&
          valueMapper["operator_0"] === "activities_due"
        ) {
          isRequired = true;
        }
      }*/
    } else if (
      fieldetails[4] === "custom_filter" &&
      fieldetails[2] === "Calls"
    ) {
      isRequired = true;
    } else if (
      fieldetails[4] === "date" ||
      fieldetails[4] === "Date_Time" ||
      (fieldetails[4] === "form_history" &&
        (fieldetails[2] === "createdon" ||
          fieldetails[2] === "updatedon" ||
          fieldetails[2] === "viewedon"))
    ) {
      if (component === "value_0" || component === "value_1") {
        if (
          valueMapper["operator_0"] !== undefined &&
          (valueMapper["operator_0"] === "Before" ||
            valueMapper["operator_0"] === "After" ||
            valueMapper["operator_0"] === "Above" ||
            valueMapper["operator_0"] === "Below")
        ) {
          isRequired = true;
        }
      } else if (component === "value_2") {
        if (
          valueMapper["operator_0"] !== undefined &&
          (valueMapper["operator_0"] === "on_date" ||
            valueMapper["operator_0"] === "before_date" ||
            valueMapper["operator_0"] === "Custom" ||
            valueMapper["operator_0"] === "after_date")
        ) {
          isRequired = true;
        }
      } else if (component === "value_3") {
        if (
          valueMapper["operator_0"] !== undefined &&
          valueMapper["operator_0"] === "Custom"
        ) {
          isRequired = true;
        }
      }
    } else if (component === "value_0") {
      if (
        valueMapper["operator_0"] !== undefined &&
        valueMapper["operator_0"] !== "is empty" &&
        valueMapper["operator_0"] !== "is not empty"
      ) {
        isRequired = true;
      }
    }
    if(valueMapper["operator_0"] !== undefined &&
      valueMapper["operator_0"] === "is changed"
    ){
      isRequired = false;
    }
    return isRequired;
  };
  const formatValue = (value, defaultVal = "") => {
    return value !== undefined ? value : defaultVal;
  };

  const getActivityFilterLabelText = (filter) => {
    let filterLabel = "";
    if (filter === "without_open_activities") {
      filterLabel = "Without any open task in the last";
    } else if (filter === "without_any_activities") {
      filterLabel = "Without any task in the last";
    } else if (filter === "overdue") {
      filterLabel = "Task overdue for the last";
    } else if (filter === "activities_due") {
      filterLabel = "Task due in the next";
    }
    return filterLabel;
  }

  const prepareActivityDurationOperators = (filterTxt) => {
    let operators = durationOperatorsWithWeeks;
    if (filterTxt === "overdue" || filterTxt === "activities_due") {
      operators = durationWithMin;
    }
    return operators;
  }

  const prepareDateFieldDurationOperators = (fieldType) => {
    let operators = durationOperators;
    if (fieldetails[4] === "Date_Time" ||
      (fieldetails[4] === "form_history" &&
        (fieldetails[2] === "createdon" ||
          fieldetails[2] === "updatedon" ||
          fieldetails[2] === "viewedon"))) {
      operators = durationDateTime;
    }
    return operators;
  }

  const onSelectionFieldChange = (selectField, name, filterIndex) => {
    let { value, label, fd } = selectField;
    setDefaultField({ value: value, label: label, fd: fd });
    fieldChangeAction(fd, filterIndex);
  }

  return (
    <div className={cx("filter-component")} data-index={filterIndex}>
      {(
        <div className="fc-header hide">
          {fieldetails[3]}

          <span
            className="icon-option pull-right remove hide"
            onClick={(e) => {
              removeFilterComp(e, filterIndex);
            }}
          >
            <i className="pe-7s-trash"></i>
          </span>
        </div>
      )}
      <div ref={searchCompEl} className="fc-box d-flex justify-none">
        {<div class="fc-header-hz dIB">
          {filterType === "trigger" ? "" : fieldetails[3]}
          {filterType === "trigger" && <div className="col-md-12">
            <Select
              placeholder={'---Choose One---'}
              menuPlacement={"auto"}
              name={"filter-field"}
              id={"filter-field"}
              className={`basic-multi-select `}
              classNamePrefix="wa-select"
              value={Object.keys(defaultField).length === 0 ? false : defaultField}
              onChange={e => {
                onSelectionFieldChange(e, "filter-field", filterIndex);
              }}
              options={fieldOptions}
            />
          </div>
          }
        </div>}
        {
          //Record Action Filter
          fieldetails[4] === "custom_filter" &&
          fieldetails[2] === "Record_Action" && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={recordModifyOptions_1}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              <span class={"fc-field"}> {formatValue(valueMapper["operator_0"]) === "touched" ? "Not updated" : "Updated"} (Only user) in the last </span>
              {/*<CratioSearchDropDown
                  customClass={"fc-field dIB"}
                  options={recordModifyOptions_2}
                  issearchRequired={false}
                  defaultValue={formatValue(valueMapper["operator_1"])}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "operator",
                    "data-key": "operator_1",
                    "data-wgt": "dropdown",
                  }}
                />
                <CratioSearchDropDown
                  customClass={"fc-field dIB"}
                  options={modify_dateOperators}
                  issearchRequired={true}
                  defaultValue={formatValue(valueMapper["operator_2"])}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "operator_2",
                    "data-wgt": "dropdown",
                  }}
                />*/}
              {isComponentRequired("value_0") && (
                <CratioNumberInput
                  customClass={"fc-field dIB Input durationInput"}
                  value={formatValue(valueMapper["value_0"], "2")}
                  onChange={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "number",
                  }}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  controls={{ autoComplete: "off", name: "duration" }}
                />
              )}
              {isComponentRequired("value_1") && (
                <CratioSearchDropDown
                  customClass={"fc-field dIB"}
                  options={durationOperatorsWithWeeks}
                  issearchRequired={false}
                  defaultValue={formatValue(valueMapper["value_1"])}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_1",
                    "data-wgt": "dropdown",
                  }}
                />
              )}
            </>
          )
        }
        {
          //Activities Filter
          fieldetails[4] === "custom_filter" &&
          fieldetails[2] === "Activities" && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={activities_operators}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              <span class={"fc-field"}>
                {
                  getActivityFilterLabelText(formatValue(valueMapper["operator_0"]))
                }
              </span>
              {/*{isComponentRequired("operator_1") && (
                  <CratioSearchDropDown
                    customClass={"fc-field dIB"}
                    options={activities_operators_1}
                    issearchRequired={false}
                    defaultValue={formatValue(valueMapper["operator_1"])}
                    onChangeEvent={onValueChange}
                    attributes={{
                      "data-type": "operator",
                      "data-key": "operator_1",
                      "data-wgt": "dropdown",
                    }}
                  />
                )}
                {isComponentRequired("operator_2") && (
                  <CratioSearchDropDown
                    customClass={"fc-field dIB"}
                    options={activities_date_operators_1}
                    issearchRequired={true}
                    defaultValue={formatValue(valueMapper["operator_2"])}
                    onChangeEvent={onValueChange}
                    attributes={{
                      "data-type": "operator",
                      "data-key": "operator_2",
                      "data-wgt": "dropdown",
                    }}
                  />
                )}
                {isComponentRequired("operator_3") && (
                  <CratioSearchDropDown
                    customClass={"fc-field dIB"}
                    options={activities_date_operators_2}
                    issearchRequired={false}
                    defaultValue={formatValue(valueMapper["operator_3"])}
                    onChangeEvent={onValueChange}
                    attributes={{
                      "data-type": "operator",
                      "data-key": "operator_3",
                      "data-wgt": "dropdown",
                    }}
                  />
                )}*/}
              {isComponentRequired("value_0") && (
                <CratioNumberInput
                  customClass={"fc-field dIB Input durationInput"}
                  value={formatValue(valueMapper["value_0"])}
                  onChange={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "number",
                  }}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  controls={{ autoComplete: "off", name: "duration" }}
                />
              )}
              {isComponentRequired("value_1") && (
                <CratioSearchDropDown
                  customClass={"fc-field dIB"}
                  options={prepareActivityDurationOperators(formatValue(valueMapper["operator_0"]))}
                  issearchRequired={false}
                  defaultValue={formatValue(valueMapper["value_1"])}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_1",
                    "data-wgt": "dropdown",
                  }}
                />
              )}
              {/*isComponentRequired("value_2") && (
                  <CratioDatePicker
                    customClass={"fc-field dIB Input datePicker"}
                    value={formatValue(valueMapper["value_2"], "current")}
                    onChangeEvent={onValueChange}
                    inputClass={errors["value_2"] ? "is-invalid" : ""}
                    attributes={{
                      "data-type": "value",
                      "data-key": "value_2",
                      "data-wgt": "date",
                    }}
                  />
                )*/}

              {/*isComponentRequired("value_3") && (
                  <CratioDatePicker
                    customClass={"fc-field dIB Input datePicker"}
                    value={formatValue(valueMapper["value_3"], "current")}
                    onChangeEvent={onValueChange}
                    inputClass={errors["value_3"] ? "is-invalid" : ""}
                    attributes={{
                      "data-type": "value",
                      "data-key": "value_3",
                      "data-wgt": "date",
                    }}
                  />
                )*/}
            </>
          )
        }
        {
          //Calls Filter
          fieldetails[4] === "custom_filter" &&
          fieldetails[2] === "Calls" && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={calls_operators_1}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              <span class={"fc-field"}> {formatValue(valueMapper["operator_0"]) === "without_any_call" ? " Calls not done in the last" : "Calls done in the last"} </span>
              {isComponentRequired("value_0") && (
                <CratioNumberInput
                  customClass={"fc-field dIB Input durationInput"}
                  value={formatValue(valueMapper["value_0"], "2")}
                  onChange={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "number",
                  }}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  controls={{ autoComplete: "off", name: "duration" }}
                />
              )}
              {isComponentRequired("value_1") && (
                <CratioSearchDropDown
                  customClass={"fc-field dIB"}
                  options={durationOperatorsWithWeeks}
                  issearchRequired={false}
                  defaultValue={formatValue(valueMapper["value_1"])}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_1",
                    "data-wgt": "dropdown",
                  }}
                />
              )}
            </>
          )
        }
        {
          //Numeric and Currency Filter
          (fieldetails[4] === "Int" || fieldetails[4] === "Currency") && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={numeric_operators}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              {isComponentRequired("value_0") && (
                <CratioNumberInput
                  customClass={"fc-field dIB Input numericInput"}
                  value={formatValue(valueMapper["value_0"], "2")}
                  onChange={onValueChange}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "number",
                  }}
                  controls={{ autoComplete: "off", name: "numericInput" }}
                />
              )}
            </>
          )
        }
        {
          //Date and Datetime Filter
          (fieldetails[4] === "date" ||
            fieldetails[4] === "Date_Time" ||
            (fieldetails[4] === "form_history" &&
              (fieldetails[2] === "createdon" ||
                fieldetails[2] === "updatedon" ||
                fieldetails[2] === "viewedon"))) && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={dateOperators}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />

              {isComponentRequired("value_0") && (
                <CratioNumberInput
                  customClass={"fc-field dIB Input durationInput"}
                  value={formatValue(valueMapper["value_0"], "2")}
                  onChange={onValueChange}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "number",
                  }}
                  controls={{ autoComplete: "off", name: "duration" }}
                />
              )}

              {isComponentRequired("value_1") && (
                <CratioSearchDropDown
                  customClass={"fc-field dIB"}
                  options={prepareDateFieldDurationOperators(fieldetails[4])}
                  issearchRequired={false}
                  defaultValue={formatValue(valueMapper["value_1"])}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_1",
                    "data-wgt": "dropdown",
                  }}
                />
              )}

              {isComponentRequired("value_2") && (
                <CratioDatePicker
                  customClass={"fc-field dIB Input datePicker"}
                  value={formatValue(valueMapper["value_2"], new Date())}
                  onChangeEvent={onValueChange}
                  inputClass={errors["value_2"] ? "is-invalid" : ""}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_2",
                    "data-wgt": "date",
                  }}
                />
              )}

              {isComponentRequired("value_3") && (
                <CratioDatePicker
                  customClass={"fc-field dIB Input datePicker"}
                  value={formatValue(valueMapper["value_3"], new Date())}
                  inputClass={errors["value_3"] ? "is-invalid" : ""}
                  onChangeEvent={onValueChange}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_3",
                    "data-wgt": "date",
                  }}
                />
              )}
            </>
          )
        }
        {
          //ComboBox Filters
          fieldetails[4] === "ComboBox" && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={picklist_operators}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              {isComponentRequired("value_0") && (
                <CratioMultiSelectInput
                  customClass={"fc-field dIB Input selectInput"}
                  options={loadListOptions(fieldetails)}
                  value={formatValue(valueMapper["value_0"])}
                  onChangeEvent={onValueChange}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "multiselect",
                  }}
                  controls={{ name: "selectInput" }}
                />
              )}
            </>
          )
        }
        {
          //ComboBox Filters
          fieldetails[4] === "subtable" && 
          (
            fieldetails[2] === "table_81_0_table_81" || 
            fieldetails[2] === "table_82_0_table_82"
          ) && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={subtable_options}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              {isComponentRequired("value_0") && (
                <>
                  <CratioMultiSelectInput
                    customClass={"fc-field dIB Input selectInput"}
                    options={subtableValueOptions[fieldetails[2]]}
                    value={formatValue(valueMapper["value_0"])}
                    onChangeEvent={onValueChange}
                    inputClass={errors["value_0"] ? "is-invalid" : ""}
                    attributes={{
                      "data-type": "value",
                      "data-key": "value_0",
                      "data-wgt": "multiselect-subtable",
                    }}
                    controls={{ name: "selectInput" }}
                  />
                </>
              )}
            </>
          )
        }
        {
          //Reference and Assigned To Filters
          (fieldetails[4] === "reference" ||
            fieldetails[4] === "entity_group" ||
            (fieldetails[4] === "form_history" &&
              (fieldetails[2] === "table_6_0_createdusername" ||
                fieldetails[2] === "table_6_1_updatedusername" ||
                fieldetails[2] === "table_6_2_viewedusername"))) && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={picklist_operators}
                issearchRequired={false}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              {isComponentRequired("value_0") && (
                <CratioLookup
                  customClass={"fc-field dIB referenceInput"}
                  value={formatValue(valueMapper["value_0"])}
                  refdetails={fieldetails[35]}
                  onChangeEvent={onValueChange}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  valueProp={filterType === "trigger" && searchStringType !== "form" ? "value" : "label"}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "lookup",
                  }}
                  controls={{ name: "referenceInput" }}
                />
              )}
            </>
          )
        }
        {
          //Remaining Filters
          isTextFilter(fieldetails) && fieldetails[4] !== "subtable" && (
            <>
              <CratioSearchDropDown
                customClass={"fc-field dIB"}
                options={text_operators}
                issearchRequired={true}
                defaultValue={formatValue(valueMapper["operator_0"])}
                onChangeEvent={onValueChange}
                attributes={{
                  "data-type": "operator",
                  "data-key": "operator_0",
                  "data-wgt": "dropdown",
                }}
              />
              {isComponentRequired("value_0") && (
                <CratioTextInput
                  customClass={"fc-field dIB Input textInput"}
                  value={formatValue(valueMapper["value_0"])}
                  onChange={onValueChange}
                  inputClass={errors["value_0"] ? "is-invalid" : ""}
                  attributes={{
                    "data-type": "value",
                    "data-key": "value_0",
                    "data-wgt": "text",
                  }}
                  controls={{ autoComplete: "off", name: "textInput" }}
                />
              )}
            </>
          )
        }
        {(
          <div class="fc-remove-hz dIB">
            <span
              className="icon-option"
              onClick={(e) => {
                removeFilterComp(e, filterIndex);
              }}
            >
              <i className="lnr-circle-minus"></i>
            </span>
          </div>
        )}
        {isLastFilter && filterType === "trigger" && (
          <div class="fc-add-hz dIB">
            <span
              className="icon-option"
              onClick={(e) => {
                addFilterComp(e);
              }}
            >
              <i className="lnr-plus-circle"></i>
            </span>
          </div>
        )}
        

      </div>
      {errors["message"] && (
          <div class="filter-componen errors">
            <span>{errors["message"]}</span>
          </div>
        )}
      <div className="fc-condition">
        <a className="cursor-pointer condition-txt" onClick={(e) => onConditionValueChange(e, filterIndex)}>{condition}</a>
      </div>
    </div>
  );
};

export default React.memo(SearchComponent);
