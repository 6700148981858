import React, { Fragment, useContext, useState } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col,Modal,ModalBody,ModalHeader,ModalFooter, Button, Label,Input } from "reactstrap";
import requestApi from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import { confirmBox, handleErrorResponse, showToast } from '../../Common/Appcommon';
import Switch from "react-switch";
import { useTheme } from '../../Context/ThemeContext';

const AccountGeneral = (props) => {
    let { accountInfo } = props;
    const { handleSubmit: handleActionFormSubmit, control, formState: { errors } } = useForm();
    const [formData, setFormData] = useState(accountInfo);
    const [showPopup ,setShowPopup] = useState(false);
    const [isDelete ,setDelete] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showButtonLoading , setshowButtonLoading] = useState(false);
    const [orgName , setOrgName] = useState('');
    const statueBtnLst = ['Activate','Deactivate']; //'Maintanance','Suspended'
    const [showStsButtonLoading , setshowStsButtonLoading] = useState(false);
    const [isActive, setIsActive] = useState(
        accountInfo.data_row?.status !== "" && accountInfo.data_row?.status !== undefined && accountInfo.data_row?.status !== null
            ? accountInfo.data_row.status.trim().toLowerCase() === "active"
            : false
    );
    const { theme, toggleTheme } = useTheme();
    formData["appid"] = formData.data_row.appid !== undefined && formData.data_row.appid !== "" ? formData.data_row.appid : "";
    const subaccountDetails = async () => {

    }

    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleInputChange = (e) =>{
        let value = e.target.value;
        setOrgName(value);
        if(value !== undefined && value !== "" && value === formData["account_name"]){
            setDelete(true);
        }else{
            setDelete(false);
        }
    }
    const toggle = () => {
        toggleModal();
    }

    const toggleModal = () => {
        setShowPopup(!isModalOpen);
        setModalOpen(!isModalOpen);
        setOrgName('');
        setDelete(false);
    }

    const getModalContent = () => {
        return (
            <>
                <Modal isOpen={isModalOpen}>
                    <ModalHeader toggle={toggle}>Delete Sub Account</ModalHeader>
                    <div className='ms-3 mb-2 del-subaccount-header'>
                    Are you sure you want to permanently delete your Account? This action cannot be undone.
                    </div>
                    <div className='bb-1'></div>
                    <ModalBody>
                        <Fragment>
                            <Label>To confirm, please type your Sub Account name: </Label>
                            <span className='ms-2 font-bold'>{formData['account_name']}</span>
                            <Controller
                                name="orgName"
                                control={control}
                                rules={{ required: "Organization Name is required" }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        id="orgName"
                                        autoComplete="off"
                                        placeholder="Enter Sub Account Name "
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            field.onChange(e);
                                        }}
                                        value={orgName}
                                        className={`form-control ${errors.orgName ? 'is-invalid' : ''}`}
                                    />
                                )}
                            />
                            {errors.orgName && <em className="mb-2 error invalid-feedback">{errors.orgName.message}</em>}
                        </Fragment>
                    </ModalBody>
                    <div className='delete-sub-account-popup mb-1'>
                        <ModalFooter>
                            <Loading showLoader={showButtonLoading} mainContent={
                                    <Fragment>
                                        <Button disabled={!isDelete} className='btn btn-danger' onClick={deleteTheAccount}> 
                                        Delete Sub Account
                                        </Button>
                                    </Fragment>
                            } />
                        </ModalFooter>
                    </div>

                </Modal>
            </>
        );
    };
    
    const deleteTheAccount = async() => {
        let {data_row} = accountInfo;
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'DELETE_SUBACCOUNT',
            'ACCOUNT_ID' : data_row.account_id,
            "SUBACCOUNT_APPID" : data_row.appid
        };
        setshowButtonLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            showToast("Success! Your account has been deleted.");
            setshowButtonLoading(false);
            toggleModal();
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }else{
            let errormsg = response.errormsg ?? "Your account not deleted.Please try after some time."
            handleErrorResponse(errormsg);
            setshowButtonLoading(false);
            toggleModal();
        }
    }

    const updateSubScrSts = async(status,value) => {
        let confirm = await confirmBox("Are you sure you want to " +status+ "?", "Press "+status+" to confirm", 'warning', status, 'Cancel');
        if (!confirm) {
            return;
        }
        let {data_row} = accountInfo;
        let params = {
            'ENTITY': 'AIPROCESSOR',
            'OPERATION' : 'UPDATE_SUBACC_SUBSCRIBE_STATUS',
            'STATUS' : value,
            'ACCOUNT_ID' : data_row.account_id,
            "SUBACCOUNT_APPID" : data_row.appid
        };
        setshowStsButtonLoading(true);
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            if(value.toLowerCase() === "active"){
                setIsActive(true);
                showToast("Sub account Enabled successfully!");      
            }else if(value.toLowerCase() === "deactive"){
                setIsActive(false);
                showToast("Sub account disabled successfully!");      
            }
            setshowStsButtonLoading(false);
        }else{
            let errormsg = response.errormsg ?? "Status was not Updated"
            handleErrorResponse(errormsg);
            setshowStsButtonLoading(false);
        }
    }
    return (
        
        <Fragment>
            <div className='account-general-info'>
                <form id={`subaccount-form`} name={'subaccount'} onSubmit={handleActionFormSubmit((data) => subaccountDetails(data))}>
                    <div className='field-details'>
                        <div className='general-div'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >Sub Account Name</span>
                                    <span className='agents-general-desc'>Max 100 characters limit</span>
                                </div>
                                </Col>
                                <Col md={4}>
                                <div>
                                    <Controller
                                        name={"account_name"}
                                        control={control}
                                        rules={{ required: formData["account_name"] === undefined ? "Please provide Company Name" : "" }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type={"text"}
                                                    name={"account_name"}
                                                    id={"account_name"}
                                                    value={formData["account_name"]}
                                                    autoComplete={"off"}
                                                    disabled={true}
                                                    onChange={(e) => {
                                                        onInputChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    className={`form-control ${errors["account_name"] ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors["account_name"] && <em className={`error invalid-feedback`}>{errors["account_name"].message}</em>}
                                </div>
                                </Col>
                            </div>
                        </div>
                        <div className='general-div hide'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >APP ID</span>
                                    <span className='agents-general-desc'>Sub Account ID</span>
                                </div>
                                </Col>
                                <Col md={4}>
                                <div>
                                    <Controller
                                        name={"appid"}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type={"text"}
                                                    name={"appid"}
                                                    id={"appid"}
                                                    value={formData["appid"]}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        onInputChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    disabled
                                                    className={`form-control ${errors["appid"] ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors["appid"] && <em className={`error invalid-feedback`}>{errors["appid"].message}</em>}
                                </div>
                                </Col>
                            </div>
                        </div>
                        <div className='agency-details hide'>
                            <div className='card-fields d-flex'>
                                <Col md={2}>
                                <div className='d-grid'>
                                    <span class="heading" >Assistants Limit </span>
                                    <span className='header-desc'>Max number of assistants</span>
                                </div>
                                </Col>
                                <Col md={4}>
                                <div>
                                    <Controller
                                        name={"assistants"}
                                        control={control}
                                        rules={{ required: false }}
                                        render={({ field }) => {
                                            return (
                                                <input
                                                    {...field}
                                                    type={"text"}
                                                    name={"assistants"}
                                                    id={"assistants"}
                                                    value={formData["assistants"] === undefined ? 10 : formData["assistants"]}
                                                    autoComplete={"off"}
                                                    onChange={(e) => {
                                                        onInputChange(e);
                                                        field.onChange(e);
                                                    }}
                                                    disabled
                                                    className={`form-control ${errors["assistants"] ? 'is-invalid' : ''}`}
                                                />
                                            );
                                        }}
                                    />
                                    {errors["assistants"] && <em className={`error invalid-feedback`}>{errors["assistants"].message}</em>}
                                </div>
                                </Col>
                            </div>
                        </div>
                        <div className='general-div'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >Disable Sub Account</span>
                                    <span className='agents-general-desc'>Are you sure you want to disable this account? Once disabled, sub-account users will lose access and will no longer be able to log in.</span>
                                </div>
                                </Col>
                                   <Col className='ps-1' md={3}>
                                   <div className="sb-sts-btn-grp">
                                    <Loading showLoader={showStsButtonLoading} mainContent={
                                        <Switch
                                            onColor={theme === "dark-theme" ?  "#343a40" :"#808080"}
                                            offColor="#DC2626"
                                            onChange={() => updateSubScrSts(!isActive ? 'Enable' : 'Disable', isActive ? 'Deactive' : 'active')} 
                                            checked={!isActive ? true : false}
                                            checkedIcon={
                                                <div className='disable-sub-btn pt-1 ps-4'>
                                                Enable
                                            </div>
                                            }
                                            uncheckedIcon={
                                                <div className='enable-sub-btn pt-1 pe-4'>
                                                Disable
                                            </div>
                                            }
                                            width={90}
                                        />
                                    } />
                                       
                                       {/* {statueBtnLst && statueBtnLst.map((status, index) => {
                                           let bclass = '';
                                           let sts = status.toLowerCase();
                                           let dsbl = false;
                                           let value = "";
                                           
                                           if (sts === "activate") {
                                               if (isActive) {
                                                   dsbl = true; // Disable if active
                                               }
                                               value = "Active";
                                               bclass = 'sub-sts-active';
                                           } else if (sts === "deactivate") {
                                               value = "Deactive";
                                               if (!isActive) {
                                                   dsbl = true; // Disable if not active
                                               }
                                               bclass = 'sub-sts-pym-fail';
                                           } else if (sts === "maintanance") {
                                               bclass = 'sub-sts-maintanace';
                                           } else if (sts === "suspended") {
                                               bclass = 'sub-sts-suspend';
                                           }
                                           
                                           return (
                                             
                                           );
                                       })} */}
                                   </div>
                               </Col>
                               
                            </div>                            
                        </div>
                        <div className='general-div'>
                            <div className='card-fields d-flex'>
                                <Col md={4}>
                                <div className='d-grid'>
                                    <span class="heading" >Delete Sub Account </span>
                                    <span className='agents-general-desc'>Are you sure you want to permanently delete your Account? This action cannot be undone.</span>
                                </div>
                                </Col>
                                <Col className='pt-3' md={3}>
                                <a onClick={toggleModal} className="btn btn-danger btn-hover-shine">
                                    Delete Sub Account
                                </a>
                                </Col>
                            </div>                            
                        </div>
                        {showPopup && getModalContent()}
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountGeneral);
