export const CallVolumeChart = [
    {
        label: 'Calls Volume Analysis', 
        value: 'Won/Lost', 
        isHorizontal: true, 
        type: 'bar', 
        colordistributed: false, 
        tooltip: 'count',
        download : false, 
        operation: 'GET_CALL_VOLUME',
        dataLabel:true,
        height:"384%",
        width:"95%",
    }    
];

export const WinLoseChart = [
    {
        label: 'Won/Lost Analysis ', 
        value: 'Won/Lost', 
        isHorizontal: true, 
        type: 'bar', 
        colordistributed: false, 
        tooltip: 'count',
        download : false, 
        operation: 'GET_CALL_WIN_LOSS',
        dataLabel:true,
        height:"384%",
        width:"95%",
    }    
];

export const CallDurationTable = [
    {
        label: "Duration Analysis",
        operation: "FETCH_CALL_DURATION_STATS",
        columns: [
            {
                name: "Call Categories",
                selector: row => row["duration"],
                sortable: true,
                width: '28%',
                style: {
                    backgroundColor: '#f4f4f4',
                }
            },
            {
                name: "Calls",
                selector: row => row["calls"],
                sortable: true,
                width: '18%',
            },
            {
                name: "Minutes",
                selector: row => row["minutes"],
                sortable: true,
                width: '18%',
            },
            {
                name: "Average",
                selector: row => row["average"],
                sortable: true,
                width: '18%',
            },
            {
                name: "% of Calls",
                selector: row => row["percentageOfCalls"],
                sortable: true,
                width: '18%',
            },
        ],
    },
];


export const CallTypeOption = [
    { value: 'total_calls', label: 'All Calls' },
    { value: 'web_Call', label: 'Web Call' },
    { value: 'Inbound', label: 'Inbound' },
    { value: 'Outbound', label: 'Outbound' }
];

export const CallAnalyticsTypeOption = [
    {value: 'all_calls', label: 'All Calls'},
    { value: 'connected', label: 'Connected' },
    { value: 'not_connected', label: 'Not Connected' },
    { value: 'goal_complete', label: 'Goal Complete' },
    { value: 'goal_incomplete', label: 'Goal Incomplete' }
]

export const CallHeartMapChart = [
    {
        label: 'Heat Map', 
        type: 'heatmap', 
        colordistributed: false, 
        tooltipName: 'callHeatMap',
        toolbar : false, 
        operation: 'GET_HEAT_MAP_DATA',
        height:450,
        width:"100%",
        colorRange : [
            { from: 76, to: 100, color: '#034737', name: 'Above 75%'},
            { from: 51, to: 75, color: '#046950', name: '51 to 75%'},
            { from: 26, to: 50, color: '#058965', name: '26 to 50%'},
            { from: 1, to: 25, color: '#06A57A', name: '1 to 25%'},
            { from: -3, to: 0.9, color: '#07C392', name: 'No Calls (NA)'},
        ],
        analyticsLable:{
            "connected":"Total Calls vs Connected Calls",
            "not_connected":"Total Calls vs Not Connected Calls",
            "goal_complete":"Total Calls vs Goal Complete",
            "goal_incomplete":"Total Calls vs Goal Incomplete",
        }
    }    
];

export const ABTesting = [
    {
        label: "AB Testing",
        operation: "GET_ABTESTING_DETAILS",
        columns: ["Agent","AI Agent A","AI Agent B"], /*,"Winning %"*/
        subHeader : ['Calls','Goal','Call Duration','Call End Reasons']
    }
];