import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Controller } from "react-hook-form";
import Select from"react-select";
import Switch from "react-switch";
import cx from "classnames";
import { multiDial, durationList } from './assets/Constant';

const VariationContainer = forwardRef((props, ref) => {
    const { control, isEnabled, variationsList, maxCount } = props;
    // State to hold the variations
    const [variations, setVariations] = useState([]);
    const [showVariation, setShowVariation] = useState(false);
    const validations = {};

    useEffect(() => {
        setShowVariation(isEnabled ?? false);
        setVariations(variationsList ?? []);
    }, [isEnabled])

    useImperativeHandle(ref, () => ({
        returnVariationValues,
    }));

    const returnVariationValues = () => {
        return ({variations: variations, followup_enabled: showVariation});
    }

    // Function to handle change in variation input
    const handleInputChange = (e, name, cur_variation) => {
        let { value } = e.target;
        if(/^\d*\.?\d*$/.test(value)){
            value = value ? parseInt(value) : 0;
            setVariations(prevState =>
                prevState.map(variation =>
                    variation.id === cur_variation.id ? { ...variation, [name]: value } : variation
                )
            );
        }
    };

    const handleSwitchChange = () => {
        setShowVariation(!showVariation)
    };

    const handleSelectChange = (name, selectedOption, index) => {
        setVariations((prevFormData) => {
            const updatedFormData = [...prevFormData]; // Make a shallow copy of the array
            updatedFormData[index] = {
                ...updatedFormData[index], // Copy the existing object properties
                [name]: selectedOption, // Update the specific field
            };
            return updatedFormData; // Return the updated array
        });
    };    

    // Function to add a new variation
    const addVariation = () => {
        let newObj = {};
        newObj["id"] = Date.now();
        newObj["duration"] = variations.length + 1;
        newObj["duration_type"] = {
            label: "Hours",
            value: "hours"
        };
        newObj["multi_dial"] = {
            value: "2_times",
            label: "Dial 2 times in a row"
        };
        setVariations([...variations, newObj]);
    };

    // Function to remove a variation
    const removeVariation = (id) => {
        setVariations(variations.filter(variation => variation.id !== id));
    };

    const requiredController = (type, index) => {
        let response = true;
    
        // Get the current variation
        const cur_variation = variations[index] ?? {};
        const cur_duration = cur_variation["duration"] ?? 0;
        const cur_duration_type = cur_variation["duration_type"] ?? {};
        const cur_dur_min = cur_duration_type["min"] ?? 0;
    
        if (type === "duration") {
            // Validation for minimum duration
            if ((cur_duration < 10 && cur_duration_type["value"] === "minutes") || cur_duration === 0) {
                response = "Follow-ups should be sent after at least 10 minutes.";
            } else {
                const cur_total_min = cur_duration * cur_dur_min;
    
                // Check for overlapping durations
                for (let i = 0; i < variations.length; i++) {
                    if (i !== index) {
                        const other_variation = variations[i];
                        const other_duration = other_variation["duration"] ?? 0;
                        const other_dur_min = other_variation["duration_type"]["min"] ?? 0;
                        const other_total_min = other_duration * other_dur_min;
                        if (cur_total_min === other_total_min) {
                            response = "Follow-ups should be at least 10 minutes apart from each other.";
                            break; // Stop further checks
                        }
                    }
                }
            }
        }
    
        return response;
    };
    

    return (
        <div className="">
            <Row>
                <Col lg={10}>
                    Schedule Follow-ups (Max 5 times, should be at least 10 minutes apart from each other.)
                </Col>
                <Col lg={1}>
                    <Switch
                        onColor={"#034737"}
                        onChange={() => handleSwitchChange()} 
                        checked={showVariation}
                        checkedIcon={
                            <div className='off-switch-btn ms-1'>
                                On
                            </div>
                        }
                        uncheckedIcon={
                            <div className='on-switch-btn'>
                                Off
                            </div>
                        }
                        width={60}
                        height={20}
                    />
                </Col>
            </Row>
            {
                showVariation && variations && variations.map((variation, index) => (
                    <Row key={variation.id} className='border border-2 rounded-2 mt-3 me-0 ms-0'>
                        <Col lg={3} className={"mt-3"}>
                            <label htmlFor={"duration_" + variation.id}>Send After</label>
                            <Controller 
                                name={"duration_" + variation.id}
                                control={control}
                                rules={{
                                    validate: () => requiredController("duration", index)
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <input
                                            {...field}
                                            type={"text"}
                                            className={cx("form-control", {"is-invalid" : error})}
                                            placeholder='Duration'
                                            value={variation["duration"]}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                                handleInputChange(e, "duration", variation)
                                            }}
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                            
                        </Col>
                        <Col lg={4} className={"mt-3 mb-3"}>
                            <label htmlFor={"duration_type_" + variation.id}>Duration Type</label>
                            <Controller 
                                name={"duration_type_" + variation.id}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={durationList}
                                            onChange={e => handleSelectChange("duration_type", e, index)}
                                            value={variation["duration_type"]}
                                            classNamePrefix="wa-select"
                                            className="basic-select"
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={4} className={"mt-3"}>
                            <label htmlFor={"multi_dial_" + variation.id}>Multi-dial</label>
                            <Controller 
                                name={"multi_dial_" + variation.id}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={multiDial}
                                            onChange={e => handleSelectChange("multi_dial", e, index)}
                                            value={variation["multi_dial"]}
                                            classNamePrefix="wa-select"
                                            className="basic-select"
                                        />
                                        {error && (
                                            <em className="error invalid-feedback d-flex mb-1">{error.message}</em>
                                        )}
                                    </>
                                )}
                            />
                        </Col>
                        <Col lg={1} className={"mt-3"}>
                            <label>Remove</label>
                            <div className="d-flex justify-content-center cursor-pointer" onClick={e => removeVariation(variation.id)}>
                                <i class={"fi fi-rr-circle-xmark fs-4"}></i>
                            </div>
                        </Col>
                    </Row>
                ))
            }
            {
                showVariation && variations.length < maxCount && 
                <Row>
                    <Col className='mt-3'>
                        <div className="d-flex justify-content-center">
                            <i class="fi fi-rr-add fs-1 cursor-pointer" onClick={addVariation}></i>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    );
});

export default VariationContainer;
