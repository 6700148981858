import React, { Fragment, useEffect, useRef, useState } from 'react';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from '../Common';
import {Container, Label,Input,Col,Button} from "reactstrap";
import requestApi from '../../services/requestApi.service';
import { getServerDomainURL,handleErrorResponse,showToast,confirmBox,populateFormDetails,getTemplatesFormDetails,loadFormData,prepareLabelNameMapper,prepareTableData,getOrganizationInfo,prepareCustomTableData } from '../../Common/Appcommon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import FieldsMapping from './FieldsMapping';
import Switch from "react-switch";
import "./index.css";
import aiData from "../../assets/utils/images/agent_no_records.png";
import ListTable from '../../Common/ListTable';
import NoRecordFound from '../Common/NoRecordFound';
import Loading from '../../Common/Loading';



export const  Webhooks = () =>{

    const [adForms, setAdForms] = useState({});
    const [showLoader, setShowLoading] = useState(false);
    const [searchStr, setSearchString] = useState('');

    const [showMapping, setShowMapping] = useState(false);
    const [showViewLog, setShowViewLog] = useState(false);
    const [adFormInfo, setAdFormInfo] = useState({});
    const [formFilDefault, setFormFilDefault] = useState({});
    const [webhookurl, setWebhookUrl] = useState("");
    const [formFilterOptions, setFormFilterOptions] = useState({});
    const [webhookName, setWebhookName] = useState("");
    const [data, setData] = useState([]);
    const [column, setColumns] = useState([]);
    const [webhookdata, setWebhookdata] = useState([]);
    const [webhookcolumn, setWebhookcolumn] = useState([]);
    const [randomid, setRandomId] = useState("");

    let appid = ""; 

    useEffect(() => {
        (async () => {
            await getFormsList("");
        })();
    },[]);

    let accountinfo = {
        data_row: {
            appid : getOrganizationInfo("appid"),
        }
    };
    const fetchWebhooks = async (formInfo) => {
        setShowLoading(true);
        if (accountinfo !== undefined) {
            appid = accountinfo.data_row.appid;
        }    
        let adFilterSearch = "";
        if(formInfo.data_row !== undefined && formInfo.data_row['identifier'] !== ""){
            let webhookFormid =  formInfo.data_row['identifier'];
            adFilterSearch = "nondate@table_68@formid@is@"+webhookFormid+"@AND#";
        }
        let formDetail = await populateFormDetails("table_68",appid);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail, "Recently Created");
        let webhookDataArr = await loadFormData("table_68", formDetail, selectedTemplate, appid, adFilterSearch, true, [], "");
        if (webhookDataArr.issuccess) {
            let { formData, listFields } = webhookDataArr;
            let nameLabelMapper = await prepareLabelNameMapper(formDetail[1]);
            let tableData = await prepareTableData(formData, listFields, false, formDetail[1]);
            setData(tableData.data);
            setColumns(tableData.columns);
        }
        setShowLoading(false);
    }
    const handleInputChange = (e) => {
        let value = e.target.value;
        setSearchString(value);
        if (value !== undefined && value !== null) {
            getFormsList(value)
        }
    };


    const showEditScreen = async (formData) => {
        setAdFormInfo(formData.data_row !== undefined ?formData.data_row : formData);
        setShowMapping(true);
        let formname = "Contacts";
        let webhookid = formData.data_row !== undefined  ? (formData.data_row['identifier'] !== undefined ? formData.data_row['identifier'] : "") : formData[3];
        setFormFilDefault({value: formname, label: formname});
        setWebhookName(formData.data_row !== undefined ? (formData.data_row['webhookname'] !== undefined ? formData.data_row['webhookname'] : "") : formData[6]);
        setWebhookUrl("Customize/Webhooks/webhook.php?id="+webhookid);
        let params = {
            "ENTITY": "WEBHOOKSLEADSPROCESS",
            "OPERATION" : 'GET_FORMFILTERFIELDS'
        }
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let fields = [];
            let dfields = response.data;
            for(let i=0; i< dfields.length;i++){
                const label = dfields[i][0] !== undefined ? dfields[i][0] : "";
                fields.push({
                    value: label, label: label
                })
            }
            setFormFilterOptions(fields);
        }else{
            handleErrorResponse(response.errormsg);
        }
    }

    const showViewLogScreen = async (formData) => {
        setShowViewLog(true);
        setShowMapping(false);
        setRandomId(formData['id']);
        await fetchWebhooks(formData);
    }

    const getFormsList = async (searchValue) => {
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION" : 'GET_WEBHOOKS',
            "SearchStr": searchValue,
        }
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            let webhhokfields = data[0] ?? [];
            let columndetails = data[1] ?? [];
            await prepareWebhookData(webhhokfields, columndetails);
        }else{
            handleErrorResponse(response.errormsg);
        }
    }

    const prepareWebhookData = async (formData, listFields) => {
        let data = [];
        let columns = [];
        let tableData = await prepareCustomTableData(formData, listFields, false, false);
        data = tableData.data;
        columns = tableData.columns;
        columns.push(
            {
                name: "EDIT",
                selector: 'edit',
                cell: (row) => (
                    <div className={`le-clickable`} onClick={() => { showEditScreen(row) }}>
                        {`EDIT`}
                    </div>
                ),
            },
            {
                name: "VIEW LOG",
                selector: 'view_log',
                cell: (row) => (
                    <div className={`le-clickable`} onClick={() => { showViewLogScreen(row) }}>
                        {`VIEW`}
                    </div>
                ),
            },
            {
                name: "DELETE",
                selector: 'delete',
                cell: (row) => (
                    <div className={`le-clickable`} onClick={() => { deletewebhookpopup(row) }}>
                        {`DELETE`}
                    </div>
                ),
            },
            {
                name: "ON/OFF",
                selector: 'on_off',
                cell: (row) => (
                    <div className={`pr-5`}>
                        <Switch
                            onColor={"#23ae73"}
                            checked={(row.data_row['ispaused'] === 'active')}
                            onChange={(e) => { handleCheckboxChange(e, row); }}
                        />
                    </div>
                ),
            },
        );        
        setWebhookdata(data);
        setWebhookcolumn(columns);
       
    }
    const processAddInfo = async () => {
        const baseUrl = getServerDomainURL();
        const webhookUrl = baseUrl+"/Customize/Webhooks/webhook.php";
        let params = {
            "ENTITY" : "WEBHOOKSLEADSPROCESS",
            "OPERATION" : "UPDATE_FIELDPAYLOAD_DETAILS",
            "randomid" : Math.floor((Math.random()*1000000)+1),
            "url" : webhookUrl
        }
        let response = await requestApi.processServerRequest(params);
        if(response.issuccess){
            setAdFormInfo(response.data[0]);
            await getFormsList("");
            await showEditScreen(response.data[0]);
        }else{
            handleErrorResponse(response.errormsg);
        }
    }

    const onBackAction = async () => {
        setShowViewLog(false);
        setShowMapping(false);
        setAdFormInfo({});
        setWebhookName("");
        await getFormsList("");
    }

    const handleCheckboxChange = async (e,formData) => {
        if(formData.data_row['ispaused'] == 'inactive' ){
                let params = {
                    "ENTITY": "WEBHOOKSLEADSPROCESS",
                    "OPERATION" : 'PAUSE_WEBHOOK',
                    "UNIQUEID" : formData.data_row['identifier'],
                    "ISPAUSED" : "active"
                }
                let response = await requestApi.processServerRequest(params);
                if(response.issuccess){
                    await getFormsList();
                    showToast(`Webhook activated successfully.`);
                }
        }else{
            let isPaused = await confirmBox("Pause Webhook","Are you sure you want to pause this webhook?",'warning', 'Pause', 'Cancel');
            if(isPaused){
            let params = {
                "ENTITY": "WEBHOOKSLEADSPROCESS",
                "OPERATION" : 'PAUSE_WEBHOOK',
                "UNIQUEID" : formData.data_row['identifier'],
                "ISPAUSED" : "inactive"
            }
            let response = await requestApi.processServerRequest(params);
            if(response.issuccess){
                await getFormsList("");
                showToast(`Webhook paused successfully.`);
            }
        }
        }
    }

    const deletewebhookpopup = async (formData) => {
        let isDelete = await confirmBox("Are you sure you want to delete?", "Press Delete to confirm", 'warning', 'Delete', 'Cancel');
        if(isDelete){
            let params = {
                "ENTITY": "WEBHOOKSLEADSPROCESS",
                "OPERATION" : 'DELETE_WEBHOOK',
                "UNIQUEID" : formData.data_row !== undefined && formData.data_row !== null ? formData.data_row['identifier'] : formData[3],
                "MAPPINGID" : formData.data_row !== undefined && formData.data_row !== null ? formData.data_row['id'] : formData[0]
            }
            let response = await requestApi.processServerRequest(params);
            if(response.issuccess){
                await getFormsList("");
                showToast(`Deleted successfully.`);
            }
        }
    }


    return(
        
        <Fragment>
            <Common title="Profile Settings" titleContent={
                <div className='d-grid'>
                    <span>Webhooks</span>

                </div>
            }
            titleBody={
                <Fragment>
                    {
                        !showMapping &&  !showViewLog &&
                        <div className='d-flex align-center justify-end'>
                            <Col md={7}  >
                                <div className='le-form-row'>
                                    <div className="input-with-icon">
                                        <span className="search-icon">
                                            <i class="icon-color fi fi-rs-search"></i>
                                        </span>
                                        <Input
                                            id="userName"
                                            autoComplete="off"
                                            placeholder="Search by name"
                                            onChange={(e) => {
                                                handleInputChange(e);
                                            }}
                                            value={searchStr}
                                            className='search-input'
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={5} className='pl-2'>
                                <Button onClick={(e) => {processAddInfo(e)}} size="lg" className='theme-bg-g compact-padding' color="dark"><FontAwesomeIcon icon={faPlus} /> Create Webhook</Button>
                            </Col>
                        </div>
                    }
                </Fragment>
            } mainContent={
                <Container className='mt-3 ms-3 max-width-65'>
                    <div>
                    
                    {
                        showMapping &&
                        <FieldsMapping
                            showMapping={showMapping}
                            formInfo={adFormInfo}
                            onBackAction={onBackAction}
                            screen ={"webhooks"}
                            mappingCol = ""
                            leadstatus = "active"
                            formfildefault = {formFilDefault}
                            webhookurl ={webhookurl}
                            formFilterOptions = {formFilterOptions}
                            webhookNameVal = {webhookName}
                        />
                    }
                    {
                        !showMapping && !showViewLog && (
                            webhookdata.length > 0 && webhookcolumn.length > 0 ? (
                                <ListTable data={webhookdata} columns={webhookcolumn} />
                            ) : (
                                <NoRecordFound
                                    img={aiData}
                                    isOnlyContent={true}
                                    content={"No Numbers Found."}
                                />
                            )
                        )
                    }

                    {
                        !showLoader && showViewLog && !showMapping ? (
                            <>
                                <a className="cursor-pointer" onClick={onBackAction}>
                                    <span className="d-flex align-center">
                                        <i className="fi fi-br-arrow-left pr-1"></i>
                                        <span>Go Back</span>
                                    </span>
                                </a>
                                {data.length > 0 && column.length > 0 ? (
                                    <ListTable data={data} columns={column}/>
                                ) : (
                                    <NoRecordFound
                                        img={aiData}
                                        isOnlyContent={true}
                                        content={"No Numbers Found."}
                                    />
                                )}
                            </>
                        ): <Loading showLoader={showLoader} /> 
                    }
                    </div>
                </Container>
            } />
        </Fragment>

    );
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);