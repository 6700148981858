import React, { Fragment, useState, useEffect,useMemo } from 'react';
import usercontact from "./assets/images/transcript-contact.svg";
import SearchBox from "./SearchBar";
import { formatTime,getSessionValue } from '../../Common/Appcommon';

const CallsTranscript = (props) => {
    let { rowdata, nameLabelMapper } = props; 
    let [parsedTranscript, setParsedTranscript] = useState([]); 
    let [filteredSummary, setFilteredSummary] = useState([]);
    let [searchTerm, setSearchTerm] = useState("");
    const mappedCallTranscript = useMemo(() => {
        return rowdata?.[nameLabelMapper?.["Call_Transcript"]];
    }, [rowdata, nameLabelMapper]);
   
    useEffect(() => {
        const handler = setTimeout(() => {
            try {
                if (mappedCallTranscript) {
                    let transdata = JSON.parse(mappedCallTranscript);
                    let proName = getSessionValue("agent_provider_name");
                    if(proName === "retell" ){
                        let reTransdata = transdata.map(({ content,role, ...rest }) => ({
                            ...rest,
                            message: content,
                            role: role === "agent" ? "bot" : role
                        })); 
                        transdata = reTransdata;
                    }else if(proName === "bland"){
                        let reTransdata = transdata.map(({ text, user, created_at, ...rest }) => {
                            let utcDate = new Date(created_at);
                            let localTime = utcDate.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                              });
                            return {
                                ...rest,
                                message: text,
                                role: user === "assistant" ? "bot" : user,
                                time : localTime,
                            };
                        });
                        transdata = reTransdata;
                    }
                    setParsedTranscript(transdata);
                } else {
                    setParsedTranscript([]);
                }
            } catch (error) {
                console.error("Failed to parse Call_Summary:", error);
                setParsedTranscript("");
            }
        }, 100); // Debouncing with 300ms

        return () => clearTimeout(handler); // Cleanup the timeout
    }, [mappedCallTranscript]);


    useEffect(() => {
        //if(parsedTranscript !== ""){
            loadTranscript();
        //}
    }, [parsedTranscript, searchTerm]); 

    let handleSearch = (term) => {
        setSearchTerm(term.toLowerCase()); 
    };

    let loadTranscript = () => {
        let filtered =[];        
        if(parsedTranscript !== ""){
            filtered = parsedTranscript.filter((field) => {
                return field.message && field.message.toLowerCase().includes(searchTerm);
            });
        }
        setFilteredSummary(filtered);
    };

    return (
        <Fragment>
            <SearchBox onSearch={handleSearch} value={parsedTranscript}/>
            <div className='call-transcript-container'>
                {filteredSummary.length > 0 ? (
                    filteredSummary.map((field, index) => (
                        <div className='mb-3' key={index}>
                            {field.role === "bot" && (
                                <div className='transcript-bot-msg'>
                                    <div className='transcript-dur'>
                                        <span className='transcript-bot-name'>AI Agent</span>
                                        <span className='transcript-bot-dur-span'> {field.secondsFromStart ? " | " +formatTime(field.secondsFromStart):""}</span>
                                    </div>
                                    <div className='transcript-bot-msg-width'>
                                        <div>{field.message}</div>
                                    </div>
                                </div>
                            )}
                            {field.role === "user" && (
                                <div className='transcript-user-msg'>
                                    <div className='transcript-user-header'>
                                        <span>{field.secondsFromStart ? formatTime(field.secondsFromStart) + " | " : field.time ? field.time + " | " : ""}</span>
                                        <span>User</span>
                                    </div>
                                    <div className='transcript-bot-msg-width'>
                                        <div>{field.message}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div className='mt-5'>No results found for your search.</div>
                )}
            </div>
        </Fragment>
    );
};

export default CallsTranscript;
