import React, { Fragment, useEffect, useState } from "react";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import Common from "../Common";
import Filter from "./Filter";
import { Col, CardBody } from "reactstrap";
import "./index.css";
import requestApi from "../../services/requestApi.service";
import { CallAnalyticsTypeOption, CallHeartMapChart } from "./constant";
import { subDays } from "date-fns";
import HeatMapChart from "./HeatMapChart";
import { isMobile } from "../../Common/Appcommon";

const CallHeatmap = () => {
    const [chartData, setChartData] = useState([]); 
    const [agent, setAgent] = useState("all_agent");
    const [callType, setCallType] = useState('connected');
    const [label,setLabel] = useState('Total Calls vs Connected Calls')
    const startDate = subDays(new Date(), 30); 
    const endDate = new Date(); 
    const [dateFilter, setDateFilter] = useState({
        Start_DateTime: [startDate, endDate],
        noofdays: 30,
    });
    const [showLoader, setShowLoader] = useState(false);
    const mobile = isMobile();


    const generateParams = (chartConfig) => {
        return {
            ENTITY: "AIPROCESSOR",
            OPERATION: chartConfig.operation,
            FORMNAME: "table_37",
            FILTEROPTION: [dateFilter], 
            agent: agent,
            CallAnalytics: callType,
            isOtherApiReq: true,
        };
    };

    const getContent = async () => {
        const params = generateParams(CallHeartMapChart[0]);
        setShowLoader(true);
        try {
            const response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                const data = response.data;
                if (data && data.length > 0) {
                    console.log(data);
                    setChartData(data);
                }
            }
            setShowLoader(false); 
        } catch (error) {
            console.error("Error fetching chart data:", error);
            setShowLoader(false); 
        }
    };

    const handleAgentValue = (selectedAgent) => {
        setAgent(selectedAgent.value); 
    };

    const handleDateValue = (selectedDate) => {
        setDateFilter(selectedDate); 
    };

    const handlecallTypeValue = (selectedCallType) => {
        setCallType(selectedCallType.value);
        setLabel(CallHeartMapChart[0].analyticsLable[selectedCallType.value]); 
    };

    const setMainContent = () => {
        return (
            <Col md={12}>
                {mobile && 
                    getTitleBody()
                }
                <CardBody className="mt-1">
                    <HeatMapChart 
                        chartData={chartData}
                        Loader={showLoader} 
                        defaultChartConfig={CallHeartMapChart[0]} 
                        tooltipLabel={label}  
                    />
                </CardBody>
            </Col>

        );
    };

    useEffect(() => {
        getContent();
    }, [dateFilter, agent,callType]);

    const getTitleBody = () =>{
        let limited = CallAnalyticsTypeOption.slice(1,5);
        return (
            <Filter
                getAgentvalue={handleAgentValue} 
                getdate={handleDateValue}
                getcallType={handlecallTypeValue}
                CallTypeOption={limited} 
                needCallFilter={true}             
                needAgentFilter={true}           
                needDateFilter={true}
            />
        );
    }
    return (
        <Fragment>
            <Common
                titleBody={
                    !mobile &&  getTitleBody()
                }
                title={
                    "Calls Analytics"
                }
                titleContent={
                    "Heat Map"
                }
                mainContent={
                    setMainContent()
                } 
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallHeatmap);
