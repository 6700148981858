import React, { useState, Fragment } from 'react';
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import Common from '../Common';
import { Button, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../Common/Loading';
import { useEffect } from 'react';
import ListTable from '../../Common/ListTable';
import { populateFormDetails, getTemplatesFormDetails, loadFormData, prepareLabelNameMapper, prepareTableData, loadDefaultFieldDetail, getContactLinkedData, confirmBox, showToast, getSelectedFormIds } from '../../Common/Appcommon';
import aiData from "../../assets/utils/images/agent_no_records.png"
import NoRecordFound from '../Common/NoRecordFound';
import FormModal from './FormModal';
import "./assets/index.css";
import ListViewHeader from '../Common/ListViewHeader';
import requestApiService from '../../services/requestApi.service';
import Select from "react-select";
import MassUpdateModal from './MassUpdateModal';
import { ContactStatusOptions } from './Constant';

const Contacts = (props) => {
    let contactform = "table_74";
    const [showLoading, setShowLoading] = useState(false);
    const [isOpenForm, setOpenForm] = useState(false);
    const [isNew, setIsNew] = useState(true);
    let [formD, setFormDetail] = useState([]);
    const [data, setData] = useState([]);
    const [column, setColumns] = useState([]);
    const [searchStr, setSearchString] = useState('');
    const [formId, setFormId] = useState("");
    const [dataRow, setDataRow] = useState({});
    const [selectedRows, setSelectedRows] = useState("");
    const [clearRows, setClearRows] = useState(false);
    const [isMoreOption, setIsMoreOptionNeeded] = useState(false);
    const allStatus = ContactStatusOptions;
    const [defaultStatus, setDefaultStatus] = useState();
    const [defTagOptions, setdefTagOptions] = useState();
    const [tagOptions, setTagOptions] = useState({ value: 'All', label: 'All' });
    const [deflistOptions, setdefListOptions] = useState();
    const [listOptions, setListOptions] = useState({});
    const [customFilter, setCustomFilter] = useState("");
    const [isMassUpdate, setIsMassUpdate] = useState(false);
    let isAddContact = false;

    let appid = "";
    let data_row = {};
    let { accountInfo } = props;
    if (accountInfo !== undefined) {
        data_row = accountInfo.data_row;
        appid = data_row.appid;
    }

    useEffect(() => {
        const loadContacts = async () => {
            setShowLoading(true);
            await fetchContacts("");
        };
        loadContacts();
    }, []);

    const loadOtherLinkedData = async (formname) => {
        let params = {
            ENTITY: "WHATSAPPPROCESS",
            OPERATION: "GET_LINKED_DATA",
            FORMNAME: formname,
            LINKED_FORMS: ['table_81', 'table_82']
        };
        let linkedData = [];
        let response = await requestApiService.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            linkedData = data;
        }
        return linkedData;
    }

    const loadTagListData = async (tagData, idvalue, data, label) => {
        let contactTag = tagData !== undefined && tagData !== null && tagData !== "" ? tagData[idvalue] : "";
        let tagDataValue = "";
        if (contactTag !== undefined && contactTag !== null && contactTag !== "" && contactTag.length > 0) {
            tagDataValue = contactTag.map((tag, index) => {

                return (
                    <div key={index} className={`badge badge-info`}>
                        {tag}
                    </div>
                );
            });

        }
        if (tagDataValue !== "") {
            tagDataValue = (
                <div className="sb-sts-btn-grp sb-sts-tbl">
                    {tagDataValue}
                </div>

            )
        }
        data[label] = tagDataValue;
        return data;
    }

    const fetchContacts = async (searchValue, advSearch = "") => {
        setShowLoading(true);
        let formDetail = await populateFormDetails(contactform, appid);
        setFormDetail(formDetail);
        let { templates, selectedTemplate } = await getTemplatesFormDetails(formDetail, "Recently Created");
        let usersData = await loadFormData(contactform, formDetail, selectedTemplate, appid, advSearch, true, [], searchValue);
        if (usersData.issuccess) {
            let linkedData = await loadOtherLinkedData(contactform);
            let tagData = linkedData['table_82'];
            let listData = linkedData['table_81'];
            let { formData, listFields } = usersData;
            let tagFd = await loadDefaultFieldDetail("Tags", "Tags");
            let listFd = await loadDefaultFieldDetail("Lists", "Lists");
            let cfieldDetails = formDetail[1];
            listFields.push(tagFd);
            listFields.push(listFd);
            cfieldDetails.push(tagFd);
            cfieldDetails.push(listFd);
            let nameLabelMapper = await prepareLabelNameMapper(cfieldDetails);
            for (let fi = 0; fi < formData.length; fi++) {
                let data = formData[fi];
                let idvalue = data[contactform + "_id"];
                let accountLabel = nameLabelMapper['vm_contact_status'];
                let dataValue = data[accountLabel];
                if (dataValue !== undefined && dataValue !== null && dataValue !== "") {
                    let bclass = "sub-sts-active";
                    if (dataValue.toLowerCase() === "unsubscribed") {
                        bclass = "sub-sts-pym-fail";
                    }
                    dataValue = (
                        <div className={`badge ${bclass}`}>
                            {dataValue}
                        </div>
                    );
                }

                data = await loadTagListData(tagData, idvalue, data, "Tags");
                data = await loadTagListData(listData, idvalue, data, "Lists");
                data[accountLabel] = dataValue;
                formData[fi] = data;
            }
            if (searchValue === "") {
                const options = await getContactLinkedData("table_81");
                setListOptions(options);
                const tags = await getContactLinkedData("table_82");
                setTagOptions(tags);
            }
            let tableData = await prepareTableData(formData, listFields, false, cfieldDetails, true, onEditContact);
            setData(tableData.data);
            setColumns(tableData.columns);
            setShowLoading(false);
        }
    }

    const onEditContact = (row) => {
        let id = row[contactform + "_id"];
        setDataRow(row);
        setFormId(id);
        setIsNew(false);
        setOpenForm(true);
    }

    const onAddContact = () => {
        setDataRow({});
        setFormId("");
        setIsNew(true);
        toggleModal();
    }
    const toggleModal = async (isRefresh = false) => {
        setOpenForm(!isOpenForm);

        if (isRefresh) {
            await fetchContacts("");
        }
    };
    const handleInputChange = (e) => {
        let value = e.target.value;
        setSearchString(value);
        setShowLoading(true);
        if (value !== undefined && value !== null) {
            fetchContacts(value);
        }
    };

    const getHeaderTitleBody = (isAddContact) => {
        if (!isAddContact) {
            return (<div className='d-flex align-center'>
                <Col md={5} className=''>
                    <Button size="lg" className='theme-bg-g compact-padding' color="dark" onClick={onAddContact}><FontAwesomeIcon icon={faPlus} /> Add Contact</Button>
                </Col>
            </div>)
        } else {
            return (
                <div>
                    <Button size="lg" className='theme-bg-g' color="dark" onClick={onAddContact}><FontAwesomeIcon icon={faPlus} /> Add Contact</Button>
                </div>
            )
        }
    }

    const onDeleteContacts = async (selectableRows) => {
        let rowCount = selectableRows.length;
        let confirm = await confirmBox("Are you sure you want " + rowCount + " Records to delete?", "Press Delete to confirm", 'warning', 'Delete', 'Cancel');
        if (!confirm) {
            rowCount = 0;
            return;
        }

        let formIds = getSelectedFormIds(selectableRows);
        if (rowCount > 0) {
            let params = {
                "ENTITY": "FORMPROCESS",
                "OPERATION": "DELETE",
                "FORMNAME": contactform,
                "MODULENAME": "Contacts",
                "FORMTYPE": "",
                "Check Reference": true,
                "FORM_INPUTFIELDS": formIds,
            };
            let response = await requestApiService.processServerRequest(params, "WebV2");
            if (response.issuccess) {
                setClearRows(true);
                setSelectedRows('');
                setIsMoreOptionNeeded(false);
                await fetchContacts("",customFilter);
                showToast("Contacts deleted successfully.");
            }
        }
    }

    const onUpdateContacts = () => {
        setIsMassUpdate(true);
    }

    const toggleMassUpdatePanel = async (isRefresh = false) => {
        setIsMassUpdate(!isMassUpdate);
        if (isRefresh) {
            await fetchContacts();
            setClearRows(true);
            setSelectedRows('');
            setIsMoreOptionNeeded(false);
            setTimeout(() => {
                setClearRows(false);
            }, 500)
        }
    }

    const onStatusChange = async (selectedOption, value) => {
        let newAdFilterSearch = "";
        let customList = [];
        let customStatus = "";
        let updatedFilter = { ...customFilter };
        if (value !== undefined && value !== null && value !== "") {
            if (value === "contact_status") {
                setDefaultStatus(selectedOption);
                if(selectedOption !== undefined && selectedOption !== null){
                    let contactStatus = selectedOption.value !== undefined && selectedOption.value !== null && selectedOption.value !== "" ? selectedOption.value : "";
                    newAdFilterSearch = "nondate@table_74@VM_Contact_Status@is@"+contactStatus+"@AND#";
                }
                updatedFilter["contact_status"] = newAdFilterSearch;
            } else if (value === "list_status") {
                setdefListOptions(selectedOption);
                if(selectedOption !== undefined && selectedOption !== null){
                    customList.push(selectedOption); 
                    customStatus += `linkspl@List@table_81@includes@${JSON.stringify(customList)}@AND#`; 
                }else{
                    customStatus += ""; 
                }
                updatedFilter["list_status"] = customStatus;
            } else if (value === "tag_status") {
                setdefTagOptions(selectedOption);
                if(selectedOption !== undefined && selectedOption !== null){
                    customList.push(selectedOption); 
                    customStatus += `linkspl@Tag@table_82@includes@${JSON.stringify(customList)}@AND#`;
                }else{
                    customStatus += ""; 
                }
                updatedFilter["tag_status"] = customStatus;
            }
            setCustomFilter(updatedFilter);
            let combinedFilters = "";
            for (let key in updatedFilter) {
                if (updatedFilter[key]) {
                    combinedFilters += updatedFilter[key];
                }
            }
            fetchContacts("", combinedFilters);
        }
    };


    const getListHeaderContent = () => {

        return (
            <Fragment>
                <div className={`listheader-content p-fixed d-flex col-md-6 justify-end align-center ${isMoreOption ? "visible" : "hide"}`}>
                    <Col md={3} className='btn-width custom-3-col-md-3'>
                        <Button size="lg" className='theme-bg-g ' color="dark" onClick={onUpdateContacts}><i class="fi fi-sc-pencil"></i> Update Contacts</Button>
                    </Col>
                    <Col md={3} className='btn-width '>
                        <Button size="lg" className='theme-bg-g ' color="dark" onClick={() => onDeleteContacts(selectedRows)}><i class="fi fi-ss-trash"></i> Delete Contacts</Button>
                    </Col>
                </div>
                <div className={`listheader-content d-flex col-md-10 justify-end align-center ${!isMoreOption ? "visible" : "non-visible"}`}>
                    <Col md={4} className='btn-width custom-col-md-4'>
                        <div className="dropdown-container pe-2">
                            <Select
                                placeholder="Filter By List"
                                name="list_form"
                                id="list_form"
                                className=""
                                classNamePrefix="sub-select wa-select"
                                value={deflistOptions}
                                options={listOptions}
                                onChange={(e) => onStatusChange(e, "list_status")}
                                isClearable={true}
                                />
                        </div>
                        {/* <Button size="lg" className='theme-bg-g ' color="dark" onClick={(e) => handleStatusChanges("Tags")}><i class="fi fi-sc-pencil"></i> Filter by Tag</Button> */}
                    </Col>
                    <Col md={4} className='btn-width custom-col-md-4'>
                        <div className="dropdown-container pe-2">
                            <Select
                                placeholder="Filter By Tag"
                                name="tag_form"
                                id="tag_form"
                                className=""
                                classNamePrefix="sub-select wa-select"
                                value={defTagOptions}
                                options={tagOptions}
                                onChange={(e) => onStatusChange(e, "tag_status")}
                                isClearable={true}    
                                />
                        </div>
                        {/* <Button size="lg" className='theme-bg-g ' color="dark" onClick={(e) => handleStatusChanges("Lists")}><i class="fi fi-ss-trash"></i> Filter by List</Button> */}
                    </Col>
                    <Col md={4} className='btn-width custom-2-col-md-4'>
                        <div className="dropdown-container">
                            <Select
                                placeholder="Filter By Status"
                                name="contact_status"
                                id="contact_status"
                                className="contact-status"
                                classNamePrefix="wa-select"
                                value={defaultStatus}
                                options={allStatus}
                                onChange={(e) => onStatusChange(e, "contact_status")}
                                isClearable={true}
                                />
                        </div>
                        {/* <Button size="lg" className='theme-bg-g ' color="dark" onClick={(e) => handleStatusChanges("Status")}><i class="fi fi-ss-trash"></i> Filter by Status</Button> */}
                    </Col>
                    <Col md={3} className='btn-width custom-1-col-md-3 pl-2'>
                        <Button size="lg" className='theme-bg-g ' color="dark"><i class="fi fi-ss-trash"></i> Search</Button>
                    </Col>
                </div>
            </Fragment>
        )

    }


    const handleSelectedRowsChange = (selectedRowsState) => {
        console.log("selectedRowsState: ",selectedRowsState)
        setSelectedRows(selectedRowsState.selectedRows);
        setIsMoreOptionNeeded(selectedRowsState.selectedRows.length > 0);
    };

    const onFormSubmit = () => {

    }

    return (
        <Fragment>
            <Common title={"Contacts"}
                titleContent={
                    <span>Contacts<span class="count">{data.length}</span></span>
                }
                titleBody={getHeaderTitleBody(isAddContact)}
                mainContent={
                    <Fragment>
                        <div className='main-table-list'>
                            {isOpenForm && formD !== null && <FormModal isModalOpen={isOpenForm} isNew={isNew} onSubmit={onFormSubmit} formId={formId} formDetail={formD} dataRow={dataRow} toggleModal={toggleModal} />}
                            {isMassUpdate && formD !== null && <MassUpdateModal
                                isModalOpen={isMassUpdate}
                                toggleModal={toggleMassUpdatePanel}
                                formDetail={formD}
                                setFormDetail={setFormDetail}
                                selectedRows={selectedRows}
                            />}
                            <Loading showLoader={showLoading} mainContent={
                                <Fragment>
                                    <div className='data-container-contacts'>
                                        <ListViewHeader formLabel={"Contacts"} content={getListHeaderContent()} />
                                    </div>
                                    <div className="data-container">
                                        {data.length > 0 && column.length > 0 &&
                                            <ListTable selectableRows={true} clearRows={clearRows} onSelectable={handleSelectedRowsChange} data={data} columns={column} />
                                        }
                                        {
                                            data.length === 0 &&
                                            <NoRecordFound
                                                img={aiData}
                                                isOnlyContent={true}
                                                content={"No Numbers Found."}
                                            />
                                        }
                                    </div>
                                </Fragment>
                            } />
                        </div>
                    </Fragment>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
