import React, {Component, Fragment} from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { encode as base64_encode } from 'base-64';

import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import "./assets/index.css";
import Common from "../Common";
import { Button, Col, Input, Row } from "reactstrap";
import { filterOptions } from "./assets/Constant";
import { 
    isMobile,
    formatDate 
} from "../../Common/Appcommon";
import ListTable from "../../Common/ListTable";
import requestApiService from "../../services/requestApi.service";

class OutboundService extends Component {
    formname = "table_83";
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            filterOption: {},
            searchValue: "",
            formData: [],
            columns: [],
            mainLoader: true
        }
    }

    componentDidMount(){
        const {type} = this.state;
        let options = {};
        if(filterOptions && filterOptions[type] && filterOptions[type][0]){
            options = filterOptions[type][0];
        }
        this.setState({
            filterOption: options
        }, () => {
            this.loadOutboundDatas();
        })
    }

    loadOutboundDatas = async () => {
        let { type, filterOption, searchValue } = this.state;
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_OUTBOUND_DETAILS",
            type: type,
            formname: this.formname,
            filterOption: filterOption,
            searchStr: searchValue,
        }
        let response = await requestApiService.processServerRequest(params);
        if(response.issuccess){
            let formData = response.data;
            let tableData = this.customTableView(formData);
            this.setState({
                formData: tableData.data,
                columns: tableData.columns,
                mainLoader: false
            })
        }
    }

    /**
     * Handles changes in the dropdown select input.
     * 
     * @function handleChange
     * @param {Object} selectedOption - The selected option object from the dropdown.
     * @returns {void}
     */
    handleChange = (selectedOption) => {
        this.setState({ 
            filterOption: selectedOption, 
            mainLoader: true 
        }, () => {
            // Ensure `loadOutboundDatas` is called after state update
            this.loadOutboundDatas();
        });
    };

    /**
     * Handles changes in the search input field.
     * 
     * @function handleSearchChange
     * @param {Object} event - The event object from the input field.
     * @returns {void}
     */
    handleSearchChange = (event) => {
        const searchValue = event.target.value;
        this.setState({ 
            searchValue, 
            mainLoader: true 
        }, () => {
            // Ensure `loadOutboundDatas` is called after state update
            this.loadOutboundDatas();
        });
    }

    onViewAccountAction = (row) => {
        if(row[this.formname + "_id"] && this.state.type){
            let reqObj = {};
            reqObj["filterValue"] = {
                fieldId: row[this.formname + "_id"]
            };
            reqObj["filterType"] = this.state.type;
            let req = JSON.stringify(reqObj);
            req = base64_encode(req);
            window.location.hash = `#/logs/${req}`;
        }
    }

    customTableView = (tableData) => {
        let data = tableData[0];
        let field = tableData[1][0] ?? [];
        let alias = tableData[1][1] ?? [];
        let column = [];
        alias && alias.map((value, index) => {
            let row = {};
            row["name"] = value;
            if(field[index] === "Name"){
                row["cell"] = (row) => {
                    let name = row[field[index]];
                    let updated_username = row["updatedusername"] ?? "";
                    let updatedon = row["updatedon"];
                    updatedon = formatDate(updatedon, "dd-mmm-yyyy h:MM TT");
                    updated_username = updated_username ? updated_username + ", " + updatedon : "";
                    return (
                        <div>
                            <div className="mt-2 mb-2">
                                <a className='cursor-pointer fw-bold text-decoration-underline' onClick={e => this.handleRowClick(row)}>
                                    <span className='d-flex align-center'>
                                        <span>{name}</span>
                                    </span>
                                </a>
                            </div>
                            <div className="mb-2">
                                {updated_username} 
                            </div>
                        </div>
                    )
                };
            }

            if(field[index] === "Schedule_Time"){
                row["cell"] = (row) => {
                    let Schedule_Time = row[field[index]];
                    Schedule_Time = formatDate(Schedule_Time, "dd-mmm-yyyy h:MM TT");
                    return (
                        <div>
                            <div className="mt-2 mb-2">
                                {Schedule_Time}
                            </div>
                        </div>
                    )
                };
            }

            if(field[index] === "Status"){
                row["cell"] = (row) => {
                    let Schedule_Time = row[field[index]];
                    return (
                        <div>
                            <div className="mt-2 mb-2">
                                {Schedule_Time}
                            </div>
                        </div>
                    )
                };
            }

            column.push(row);
        });

        column.push(
            {
                name: "View Log",
                selector: 'view_log',
                maxWidth: '105px !important',
                cell: (row) => (
                    <div className="m-auto">
                        <Button onClick={() => this.onViewAccountAction(row)} className="access-account-btn theme-bg-g">
                            <span>View Log</span>
                            <i class="ml-2 fi fi-bs-arrow-up-right-from-square"></i>
                        </Button>
                    </div>
                ),
                
            },

        );

        return {data: data, columns: column};
    }

    handleRowClick = (row) => {
        let { type } = this.state;
        if(row){
            let formid = row[this.formname + "_id"];
            // Update the URL hash to navigate to the "edit" page for the current type
            window.location.hash = `#/${type}/edit/${formid}`;
        }else{
            // Update the URL hash to navigate to the "new" page for the current type
            window.location.hash = `#/${type}/new`;
        }
    };

    /**
     * Renders the filter container header with search and dropdown filter options.
     * The header adapts based on the type prop, which determines titles and options.
     * 
     * @function getFilterContainer
     * @returns {JSX.Element} A React fragment containing the filter header.
     */
    getFilterContainer = () => {
        const {type, searchValue, filterOption} = this.state;

        // Set dynamic titles and button labels based on the type prop
        let title = "Campaigns";
        let titlebtnLabel = "New Campaign";
        let options = filterOptions[type];

        if (type === "automation") {
            title = "Automations";
            titlebtnLabel = "New Automation";
        }
        // Render the filter container
        return (
            <div className="body-header ps-3 pe-3">
                <Row lg={12} md={12}>
                    { 
                        isMobile() ? (
                            <Fragment>
                                <Col md={3} lg={4} className="mt-4 ">
                                    <span className="outbound-title w-50">
                                        {title}
                                    </span>
                                </Col>
                                <Col md={3} className="mt-3 pr-0">
                                    <Input
                                        type="text"
                                        onChange={this.handleSearchChange}
                                        value={searchValue}
                                        placeholder="Search by Name"
                                        className="form-control"
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col md={3} className="mt-3 pr-0">
                                    <Select
                                        isClearable={false}
                                        onChange={this.handleChange}
                                        options={options}
                                        defaultValue={options[0]}
                                        value={filterOption}
                                        className="basic-select"
                                        classNamePrefix="outbound-select wa-select"
                                    />
                                </Col>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Col md={4} className="pr-0">
                                    <Input
                                        type="text"
                                        onChange={this.handleSearchChange}
                                        value={searchValue}
                                        placeholder="Search by Name"
                                        className="form-control"
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col md={4} className="pr-0">
                                    <Select
                                        isClearable={false}
                                        onChange={this.handleChange}
                                        options={options}
                                        defaultValue={options[0]}
                                        value={filterOption}
                                        className="basic-select"
                                        classNamePrefix="outbound-select wa-select"
                                    />
                                </Col>
                                <Col md={4} className="pl-0">
                                    <Button 
                                        onClick={(e) => this.handleRowClick()} 
                                        className="btn-hover-shine theme-bg-g custom-display add-btn ms-auto me-4 " 
                                    >
                                        <i class="fi fi-br-plus"></i>
                                        <span>{titlebtnLabel}</span>
                                    </Button>
                                </Col>
                            </Fragment>
                        )
                    }
                </Row>
            </div>
        );
    }

    render() {
        const {type, columns, formData, mainLoader} = this.state;
        let title = "Campaigns";
        let titlebtnLabel = "New Campaign";
        if(type === "automation"){
            title = "Automations";
            titlebtnLabel = "New Automation";
        }
        return (
            <Fragment>
                <Common 
                    title={title} 
                    titleContent={
                        <span>{title}</span>
                    }
                    mainLoader={mainLoader}
                    titleBody={
                        !isMobile() ? (
                            this.getFilterContainer()
                        ) : (
                            <Button 
                                onClick={(e) => this.handleRowClick()} 
                                className="btn-hover-shine theme-bg-g custom-display add-btn me-auto me-4 " 
                            >
                                <i class="fi fi-br-plus"></i>
                                <span>{titlebtnLabel}</span>
                            </Button>
                        )
                    }
                    mainContent={
                        <div className="outbound-main-container">
                            {
                                isMobile() && this.getFilterContainer()
                            }
                            <div className="">
                                <ListTable data={formData} columns={columns} />
                            </div>
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboundService);
