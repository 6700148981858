import React, { Fragment,useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
import ListTable from "../../Common/ListTable";
import requestApi from '../../services/requestApi.service';
import Loading from '../../Common/Loading';

export const TableDashboard = (props) => {
    const [tblAgenListColumns, setTblAgenListColumns] = useState([]);
    const [tablesGraphDatas, setTablesGraphDatas] = useState([]);
    const [showLoading, setShowLoading] = useState([]);

    useEffect(() => {
        if (props.tableColList !== tblAgenListColumns) {
            setTblAgenListColumns(props.tableColList);
        }
    }, [props.tableColList, tblAgenListColumns]);

    useEffect(() => {
        tablesGraphData();
    },[tblAgenListColumns]);

    const getRequestJsonObj = (subOpr) => {      
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_DASHBOARD_LIST",
            "SUBOPERATION" : subOpr,
            "isOtherApiReq" : true,
        };
        return params;
    }
    const tablesGraphData = async () => {
        setShowLoading(true);
        try {
            if(tblAgenListColumns.length > 0){
                const updatedColumns = await Promise.all(
                    tblAgenListColumns.map(async (val) => {
                        let operation = val.operation;
                        let params = getRequestJsonObj(operation);
                        let response = await requestApi.processServerRequest(params);
                        if (response.issuccess) {
                            let data = response.data;
                            return { ...val, data };
                        }
                        return val;
                    })
                );
                if(updatedColumns.length > 0){
                    setTablesGraphDatas(updatedColumns);
                }
            }
            setShowLoading(false);
        } catch (error) {
            setShowLoading(false);
            console.error("Error fetching table graph data:", error);
        }
    };
 
    return (
        <Fragment>
            <Loading showLoader={showLoading} mainContent={
                tablesGraphDatas.length > 0 && tablesGraphDatas.map((val, index) => {
                    return(
                        <>
                            <Col lg="12" className="call-log-charts">
                                <Card className={"main-card mb-3 "}>
                                    <CardBody>
                                    <span className="widget-subheading">{val.label}</span>
                                    <div className="bar mt-2">
                                        <ListTable isDashboard={true} data={val.data} columns={val.columns} />
                                    </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                    )
                })
            } />  
        </Fragment>     
    )
}