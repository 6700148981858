import cx from "classnames";
import React, { useEffect, Fragment, useRef, useState } from "react";

const CratioSearchDropDown = (props) => {
  const [isDropDownShown, setIsDropDownShown] = useState(false);
  const isSearchRequired = props.issearchRequired;
  const initialOptions = props.options;
  const defaultVal = props.defaultValue;
  const [filteredOptions, setFilteredOptions] = useState(props.options);
  const [selectedOption, setSelectedOption] = useState(props.options[0]);
  const filterHolderRef = useRef(null);
  const lastItemEl = useRef(null);

  useEffect(() => {
    (async () => {
      for (let index in filteredOptions) {
        let option = filteredOptions[index];
        if (option.value == defaultVal) {
          setSelectedOption(option);
          break;
        }
      }
      
    })();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    setFilteredOptions(initialOptions);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const showDropDownList = async (e) => {
    e.preventDefault();
    setIsDropDownShown(!isDropDownShown);
    /* if (lastItemEl.current) {
      setTimeout(
        () =>
          lastItemEl.current.scrollIntoView({
            inline: "center",
            behavior: "smooth",
          }),
        100
      );
    } */
  };

  const handleClickOutside = (event) => {
    if (
      isDropDownShown &&
      filterHolderRef.current &&
      !filterHolderRef.current.contains(event.target)
    ) {
      setIsDropDownShown(false);
    }
  };

  const onFilteredSearch = async (e) => {
    let searchvalue = e.target.value;
    let filteredOptions = [];
    initialOptions.map((option, index) => {
      let label = option.label;
      if (label.toLowerCase().includes(searchvalue.toLowerCase())) {
        filteredOptions.push(option);
      }
    });
    setFilteredOptions(filteredOptions);
  };
  const onOptionChange = async (e, option) => {
    setSelectedOption(option);
    setIsDropDownShown(!isDropDownShown);
    if (props.onChangeEvent) {
      props.onChangeEvent(e, option, props.attributes);
    }
  };

  return (
    <div
      className={cx("custom-view-options " + props.customClass)}
      {...props.attributes}
      data-value={selectedOption.value}
    >
      <ct-dropbtn-box className="ct-dropbtn-box">
        <div className="custom-view-popover pR arrow-left-corner t-f-tl">
          <a
            onClick={(e) => {
              showDropDownList(e);
            }}
            className={cx(`ct-dropbtn-link`, {
              "popover-opened": isDropDownShown,
            })}
          >
            <span className="ct-dropbtn-txt">
              <span>{selectedOption.label}</span> <i className="caret"></i>
            </span>
          </a>
          <popper-content>
            <div
              className={cx("ngxp__container ngxp__animation", {
                "popup-show": isDropDownShown,
              })}
              aria-hidden="true"
              role="popper"
            >
              <div className="ngxp__inner">
                <div className="custom-view-popover-body">
                  <div className="popover-view-content">
                    <div ref={filterHolderRef}>
                      <div
                        className={cx("ct-dropbtn-search-block", {
                          hide: !isSearchRequired,
                        })}
                      >
                        <input
                          type="text"
                          onKeyUp={(e) => {
                            onFilteredSearch(e);
                          }}
                          name="searchFilter"
                          autoComplete="off"
                          className="form-control ng-pristine ng-valid ng-touched"
                          placeholder="Search Options"
                        />
                      </div>
                      <div>
                        <div className="popover-list">
                          <ul>
                            {filteredOptions.map((option, index) => {
                              return (
                                <li
                                  onClick={(e) => {
                                    onOptionChange(e, option);
                                  }}
                                  className={cx(
                                    "show-on-hover template-list-items",
                                    {
                                      selected:
                                        option.value == selectedOption.value,
                                    }
                                  )}
                                >
                                  <span>{option.label}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div ref={lastItemEl}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ngxp__arrow"></div>
            </div>
          </popper-content>
        </div>
      </ct-dropbtn-box>
    </div>
  );
};

export default CratioSearchDropDown;
