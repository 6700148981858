import React, { Fragment } from 'react';
import { connect } from "react-redux";
import Common from '.';
import aiData from "../../assets/utils/images/agent_no_records.png"

const NoRecordFound = (props) => {
    let { title, titleContent, titleBody, img, content, isOnlyContent } = props;
    let noRecordTitle = "No records found.";
    content = "No matching results found."; 

    if(!img){
        img = aiData;
    }
    if (isOnlyContent) {
        return <Fragment>
            <div className='no-records-container'>
                <div className='d-grid align-center'>
                    <img src={img} />
                    <span className='no-record-title'>{noRecordTitle}</span>
                        <span className='no-record-desc hide'>{content}</span>
                </div>
            </div>
        </Fragment>
    }
    return (
        <Fragment>
            <Common
                mainLoader={false}
                title={title}
                titleContent={titleContent}
                titleBody={titleBody}
                mainContent={
                    <div className='no-records-container'>
                        <div className='d-grid align-center'>
                            <img src={img} />
                            <span className='no-record-title'>{noRecordTitle}</span>
                            <span className='no-record-desc hide'>{content}</span>
                        </div>
                    </div>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(NoRecordFound);
