import React, { Fragment } from "react";
import {
    InputGroup,
    FormGroup,
    Label,
    Form,
    Col,
    Row,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
  } from "reactstrap";
import DatePicker from "react-datepicker";
import { DateFilterOptions } from "../Components/Dashboard/Constant";

class DateFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            selectedOption: {},
            starterror:false,
            enderror:false,
            onfirstnotneed: props.onfirstnotneed || false, // Default to false if undefined
        };
    }

    componentDidMount = () => {
        let defaultSelectIndex = this.props.defaultSelectIndex;
        let selectedOption = DateFilterOptions[defaultSelectIndex] || DateFilterOptions[0];
        if(!this.state.onfirstnotneed){
            this.handleDateFilter(selectedOption, selectedOption['value'])
        }else{
            this.setState({selectedOption:selectedOption});
        }
    }

    handleChangeStart = (date) => {
        if (date > this.state.endDate) {
            this.setState({starterror:true});
            return;
        }
        if (this.state.endDate > date) {
            this.setState({enderror:false});
        }
        this.setState({ startDate: date,starterror:false });
    };

    handleChangeEnd = (date) => {
        if (date < this.state.startDate) {
            this.setState({enderror:true})
            return;  
        }
        if (this.state.startDate  < date) {
            this.setState({starterror:false});
        }
        this.setState({ endDate: date ,enderror:false});
    };

    handleDateFilter = (option, name) => {
        let startDate = new Date();
        let endDate = new Date();
    
        // Helper functions for date calculations
        const getYesterday = () => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        };
    
        const getLastNDays = (days) => {
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - days);
            return { startDate, endDate };
        };
    
        const getStartAndEndOfMonth = (offset = 0) => {
            const now = new Date();
            const startMonth = new Date(now.getFullYear(), now.getMonth() - offset, 1);
            const endMonth = new Date(now.getFullYear(), now.getMonth() - offset + 1, 0);
            return { startDate: startMonth, endDate: endMonth };
        };
    
        switch (name) {
            case "custom":
                this.handleModal();
                break;
            case "yesterday":
                startDate = endDate = getYesterday();
                break;
            case "last_7_days":
                ({ startDate, endDate } = getLastNDays(6));
                break;
            case "last_30_days":
                ({ startDate, endDate } = getLastNDays(29));
                break;
            case "this_month":
                ({ startDate, endDate } = getStartAndEndOfMonth(0));
                break;
            case "last_month":
                ({ startDate, endDate } = getStartAndEndOfMonth(1));
                break;
            default:
                // Handle default case or do nothing
                break;
        }
        if(name !== "custom"){
            this.setState({ startDate, endDate , selectedOption : option});
            this.props.handleDateChange(startDate, endDate);
        }else{
            this.setState({selectedOption : option});
        }
    }

    getDateBasedonNoofDays = (noofdays = 7) => {
        const dates = [];
        for (let i = 0; i < noofdays; i++) {
            const date = new Date();
            date.setDate(date.getDate() - i); // Subtract i days from the current date
            dates.push(new Date(date)); // Push the adjusted date into the array
        }
        return dates;
    }

    handleModal = (name) => {
        if(name === "submit"){
            let {startDate, endDate,starterror,enderror} = this.state;
            if (!starterror && !enderror) {
                this.props.handleDateChange(startDate, endDate);
            } else {
                return;
            }
        }
        this.setState((prevState) => ({
            isModalOpen: !prevState.isModalOpen,
            startDate: new Date(),
            endDate: new Date(),
            enderror:false,
            starterror:false,
        }));
    }

    getModalContent = () => {
        let { isModalOpen } = this.state;
        return (
            <>
                <Modal isOpen={isModalOpen} >
                    <ModalHeader  toggle={e => {this.handleModal()}}>
                        Choose Date Range
                    </ModalHeader>
                    <ModalBody>
                        <div className="date-option-container">
                            <Fragment>
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleEmail" className="me-sm-2">
                                                    Start Date
                                                </Label>
                                                <InputGroup>
                                                    <div className="input-group-text">
                                                        <i class="fi fi-rr-calendar-days "></i>
                                                    </div>
                                                    <DatePicker 
                                                        selected={this.state.startDate} 
                                                        selectsStart 
                                                        className="form-control"
                                                        startDate={this.state.startDate} 
                                                        endDate={this.state.endDate} 
                                                        onChange={this.handleChangeStart}
                                                        dateFormat="dd-MMM-yyyy"
                                                    />
                                                </InputGroup>
                                                <div>
                                                    {this.state.starterror && <em className="error">Please provide a valid Start Date.</em>}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="examplePassword" className="me-sm-2">
                                                    End Date
                                                </Label>
                                                <InputGroup>
                                                    <div className="input-group-text">
                                                        <i class="fi fi-rr-calendar-days "></i>
                                                    </div>
                                                    <DatePicker 
                                                        selected={this.state.endDate} 
                                                        selectsEnd 
                                                        className="form-control"
                                                        startDate={this.state.startDate} 
                                                        endDate={this.state.endDate} 
                                                        onChange={this.handleChangeEnd}
                                                        dateFormat="dd-MMM-yyyy"
                                                    />
                                                </InputGroup>
                                                <div>
                                                    {this.state.enderror && <em className="error">Please provide a valid End Date</em>}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Fragment>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-secondary theme-bg-g" onClick={e => {this.handleModal("submit")}}>
                            Ok
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
    

    render(){
        let {isModalOpen, selectedOption} = this.state;
        return(
            <>
                <Fragment>
                    <UncontrolledDropdown>
                        <DropdownToggle className="p-0" color="link">
                            <Button className="btn-icon date-filter-toggle theme-bg-g" color="link">
                                <i className="pe-7s-filter btn-icon-wrapper"></i>
                                    {selectedOption['label']}
                            </Button>
                        </DropdownToggle>
                        <DropdownMenu end className="dropdown-menu-right dropdown-menu-rounded">
                            {
                                DateFilterOptions && 
                                DateFilterOptions.map((option, index) => {
                                    let label = option.label;
                                    let value = option.value;
                                    return (
                                        <>
                                            <DropdownItem onClick={e => this.handleDateFilter(option, value)}>{label}</DropdownItem>
                                        </>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Fragment>
                {
                    isModalOpen && this.getModalContent()
                }
            </>
        )
    }
}

export default DateFilter;
