import React ,{ Fragment } from "react";
import "./index.css";
import { connect } from "react-redux";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import Loading from "../../Common/Loading";
import NoRecordFound from "../Common/NoRecordFound";
import noDataimg from "../../assets/utils/images/agent_no_records.png"
import { CardBody, Card, } from "reactstrap";

const ABTestingTable = (props) =>{

    let {tableData = [] ,tableConfig = [] , Loader = false ,dateRange = []} = props;
    
    const getTable = () => {
        const tableColumn = tableConfig.columns;
        const subColumn =  tableConfig.subHeader;
        let tablelength = tableData.length;
        if (tablelength <= 0) {
            return (
                <NoRecordFound 
                    img={noDataimg}
                    isOnlyContent={true}
                    content={"No Agent Found To Compare."}
                />
            );
        }   
        return (
            <div className="call-log-charts">
                <Card className="main-card mb-3 ">
                    <CardBody>
                        <span className="widget-subheading">{tableConfig.label}</span>
                        <div className="call-table mt-4">
                            {/* Table Header */}
                            <div className="table-row">
                                {tableColumn.map((item, index) => (
                                    <div key={index} className={`table-cell header ${index !== 0 ? 'table-cell-center-content' : ''}`}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                            {/* Date Range*/}
                            {dateRange.map((date, index) => (
                                <div className="table-row" key={index}>
                                    <div className="table-cell header">{date.name}</div>
                                    <div className="table-cell table-cell-center-content">{date.A}</div>
                                    <div className="table-cell table-cell-center-content">{date.B}</div>
                                </div>
                            ))}
                            {/* Empty Row */}
                            {emptyRow()}
                            {/* Table Body */}
                            {tableData.map((dataArray, index) => (
                                <Fragment key={index}>
                                    {/* Subheader Row */}
                                    <div className="table-row">
                                        <div className="table-cell header">{subColumn[index]}</div>
                                        <div className="table-cell"></div>
                                        <div className="table-cell"></div>
                                    </div>
                                    {/* Data Rows */}
                                    {dataArray.map((data, rowIndex) => (
                                    <div key={rowIndex} className="table-row">
                                            <div className="table-cell">{data.name}</div>
                                            <div className="table-cell table-cell-center-content">{data.A}</div>
                                            <div className="table-cell table-cell-center-content">{data.B}</div>
                                        </div>
                                    ))}
                                    {/* Empty Row */}
                                    {index < tablelength - 1 && emptyRow()}
                                </Fragment>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    };

    const emptyRow = () =>{
        return(
            <div className="table-row">
                <div className="table-cell"></div>
                <div className="table-cell"></div>
                <div className="table-cell"></div>
            </div>
        );
    }
    
    
    return (
        <Loading
            showLoader=
            {
                Loader
            }
            mainContent=
            {
                getTable()
            }
        />
    );
}

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ABTestingTable);