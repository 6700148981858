import React, { useEffect, Fragment, useRef, useState } from "react";
import cx from "classnames";
import { Input } from "reactstrap";

const CratioMobileInput = (props) => {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        (async () => {})();
    });

    useEffect(() => {
        setValue(props.value);
      }, [props.value]);

    const onChange = async (e) => {
        if(e.target.validity.valid){
            let name = e.target.name;
            let value = e.target.value;
            if (props.onChange) {
                props.onChange(e, {name: name, value: value}, props.attributes);
            }
            setValue(value);
        }
    };
    return (
        <div className={cx(props.customClass)} {...props.attributes}>
            <Input
                className={`${props.inputClass}`}
                pattern="[0-9]*"
                type={"tel"}
                value={value}
                autoComplete={"off"}
                onChange={(e) => onChange(e)}
                {...props.controls}
            />
        </div>
    );
};

export default CratioMobileInput;
