import React, { Fragment, useEffect } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { getLoginUserInfo, validateLogin } from '../../Common/Appcommon';
import { useTheme } from '../../Context/ThemeContext';
const Login = (props) => {
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        (async () => {
            await validateLogin(props.history);
            let themeOption = getLoginUserInfo("Account_Type");
            if(themeOption !== "" && themeOption !== null && (themeOption === "dark-theme" || themeOption === "light-theme")){
                toggleTheme(themeOption);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);


    return (
        <Fragment>
            <div >
                Loading
            </div>

        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
