const recordModifyOptions_1 = [
  {
    value: "Untouched Records",
    label: "Untouched Records",
  },{
    value: "Touched Records",
    label: "Touched Records",
  }
];

const recordModifyOptions_2 = [
  {
    value: "user_and_system",
    label: "User & System",
  },
  {
    value: "user_or_system",
    label: "User or System",
  },
  {
    value: "user",
    label: "User",
  },
  {
    value: "system",
    label: "System",
  },
  {
    value: "user_only",
    label: "User Only",
  },
  {
    value: "system_only",
    label: "System Only",
  },
];

const dateOperators = [
  {
    value: "After",
    label: "Due In",
  },
  {
    value: "is changed",
    label: "Is Changed?",
  },
  {
    value: "Before",
    label: "Overdue by",
  },
  /*{
    value: "on_date",
    label: "On",
  },*/
  {
    value: "Above",
    label: "Is Above",
  },
  {
    value: "Below",
    label: "Is Below",
  },
  /*{
    value: "before_date",
    label: "Before",
  },
  {
    value: "after_date",
    label: "After",
  },*/
  {
    value: "Custom",
    label: "Between",
  },
  {
    value: "Today",
    label: "Today",
  },
  {
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    value: "Tomorrow",
    label: "Tomorrow",
  },
  {
    value: "Current week",
    label: "Current Week",
  },
  {
    value: "Current Month",
    label: "Current Month",
  },
  {
    value: "this_year",
    label: "Current Year",
  },
  {
    value: "Last Week",
    label: "Last Week",
  },
  {
    value: "Last Month",
    label: "Last Month",
  },
  {
    value: "Next Week",
    label: "Next Week",
  },
  {
    value: "Next Month",
    label: "Next Month",
  },
  {
    value: "Date is empty",
    label: "Is Empty",
  },
  {
    value: "Date is not empty",
    label: "Is Not Empty",
  },
];

const modify_dateOperators = [
  {
    value: "in_the_last",
    label: "Overdue by",
  },
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "current_week",
    label: "Current Week",
  },
  {
    value: "current_month",
    label: "Current Month",
  },
  {
    value: "current_year",
    label: "Current Year",
  },
  {
    value: "last_week",
    label: "Last Week",
  },
  {
    value: "last_month",
    label: "Last Month",
  },
];

const durationOperators = [
  {
    value: "Days",
    label: "Days",
  },
  // {
  //   value: "Weeks",
  //   label: "Weeks",
  // },
  {
    value: "Months",
    label: "Months",
  },
  {
    value: "Year",
    label: "Year",
  },
];

const activities_operators = [
  {
    value: "Without Any Open Task",
    label: "Without Any Open Task",
  },
  {
    value: "Without Any Task",
    label: "Without Any Task",
  },
  {
    value: "With Overdue Task",
    label: "With Overdue Task",
  },
  {
    value: "Task Due",
    label: "Task Due",
  },
];

const durationOperatorsWithWeeks = [
  {
    value: "Days",
    label: "Days",
  },
  {
    value: "Weeks",
    label: "Weeks",
  },
  {
    value: "Months",
    label: "Months",
  }
];

const durationWithMin = [
  {
    value: "Mins",
    label: "Mins",
  },
  {
    value: "Hours",
    label: "Hours",
  },
  {
    value: "Days",
    label: "Days",
  },
  {
    value: "Weeks",
    label: "Weeks",
  },
  {
    value: "Months",
    label: "Months",
  }
];

const durationDateTime = [
  {
    value: "Min",
    label: "Mins",
  },
  {
    value: "Hours",
    label: "Hours",
  },
  {
    value: "Days",
    label: "Days",
  },
  {
    value: "Months",
    label: "Months",
  }
];

const activities_date_operators_1 = [
  {
    value: "in_the_last",
    label: "Overdue by",
  },
  {
    value: "on_date",
    label: "On",
  },
  {
    value: "before_date",
    label: "Before",
  },
  {
    value: "after_date",
    label: "After",
  },
  {
    value: "between",
    label: "Between",
  },
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "tommorrow",
    label: "Tommorrow",
  },
  {
    value: "this_week",
    label: "Current Week",
  },
  {
    value: "this_month",
    label: "Current Month",
  },
  {
    value: "this_year",
    label: "Current Year",
  },
  {
    value: "last_week",
    label: "Last Week",
  },
  {
    value: "last_month",
    label: "Last Month",
  },
  {
    value: "till_today",
    label: "Till Today",
  },
];

const activities_date_operators_2 = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "tommorow",
    label: "Tommorrow",
  },
  {
    value: "due_in",
    label: "Due In",
  },
  {
    value: "today_and_overdue",
    label: "Today and Overdue",
  },
];

const activities_operators_1 = [
  {
    value: "activities",
    label: "Activities",
  },
  {
    value: "tasks",
    label: "Tasks",
  },
  {
    value: "call",
    label: "Call",
  },
];

const calls_operators_1 = [
  {
    value: "Without Any Call",
    label: "Without Any Call",
  },
  {
    value: "With Call",
    label: "With Call",
  },
];

const picklist_operators = [
  {
    value: "is",
    label: "Is",
  },
  {
    value: "is not",
    label: "Isn't",
  },
  {
    value: "is empty",
    label: "Is Empty",
  },
  {
    value: "is not empty",
    label: "Is Not Empty",
  },
  {
    value: "is changed",
    label: "Is Changed?",
  },
];

const reference_operators = [
  {
    value: "is",
    label: "Is",
  },
  {
    value: "is not",
    label: "Isn't",
  },
];

const user_operators = [
  {
    value: "is",
    label: "Is",
  },
  {
    value: "is not",
    label: "Isn't",
  }
];

const numeric_operators = [
  {
    value: "is",
    label: "is",
  },
  {
    value: "is not",
    label: "is not",
  },
  {
    value: "is changed",
    label: "Is Changed?",
  },
  {
    value: "is less than",
    label: "is less than",
  },
  {
    value: "is less than or equal to",
    label: "is less than or equal to",
  },
  {
    value: "is greater than",
    label: "is greater than",
  },
  {
    value: "is greater than or equal to",
    label: "is greater than or equal to",
  },
  // {
  //   value: "between",
  //   label: "Between",
  // },
  // {
  //   value: "not_between",
  //   label: "Not Between",
  // },
  {
    value: "is empty",
    label: "Is Empty",
  },
  {
    value: "is not empty",
    label: "Is Not Empty",
  },
];

const text_operators = [
  {
    value: "is",
    label: "Is",
  },
  {
    value: "is not",
    label: "Isn't",
  },
  {
    value: "is changed",
    label: "Is Changed?",
  },
  {
    value: "begins with",
    label: "Starts With",
  },
  {
    value: "ends with",
    label: "Ends With",
  },
  {
    value: "contains",
    label: "Contains",
  },
  {
    value: "not contains",
    label: "Not Contains",
  },
  {
    value: "is empty",
    label: "Is Empty",
  },
  {
    value: "is not empty",
    label: "Is Not Empty",
  },
];

const workflow_action_list = [
  {
    value: "create_task",
    label: "Create Task",
  },
  {
    value: "send_email",
    label: "Send Email",
  },
  {
    value: "send_whatsapp",
    label: "Send WhatsApp",
  },
  {
    value: "send_sms",
    label: "Send SMS",
  },
  {
    value: "update_field",
    label: "Update Field",
  },
  {
    value: "webhook",
    label: "Send Webhook",
  },
];

const type_of_action_list = {
  "Create Task" : "create_task",
  "Update" : "update_field",
  "Send Email" : "send_email",
  "Send SMS" : "send_sms",
  "Send Whatsapp" : "send_whatsapp",
}

const owners_action_list = [
  {
    value : "record_owner",
    label : "Record Owner"
  },
  {
    value : "record_owner_manager",
    label : "Record Owner’s Manager"
  },
  {
    value : "record_createdby",
    label : "Record Created By"
  },
  {
    value : "specific_user",
    label : "Specific User"
  }
];

const recipients_option_list = [
  
  {
    value : "@Assigned to:Emailid",
    label : "Record Owner"
  },
  {
    value : "@Assigned to:Superiors",
    label : "Record Owner’s Manager"
  },
  {
    value : "@Created User:Emailid",
    label : "Record Created By"
  }
];

const recipients_mobile_option_list = [
  
  {
    value : "@Assigned to:MobileNo",
    label : "Record Owner"
  },
  {
    value : "@Assigned to:Superiors",
    label : "Record Owner’s Manager"
  },
  {
    value : "@Created User:MobileNo",
    label : "Record Created By"
  }
];


const duration_option_list = [
  {
    value: "immediate", label: "Immediately"
  },
  {
    value: "mins", label: "Mins"
  },
  {
    value: "hrs", label: "Hours"
  },
  {
    value: "days", label: "Days"
  }
];

let user_action_list = [
  {value : "Add", label : "When a %FORMLABEL% is created" },
  {value : "Update", label : "When a %FORMLABEL% is updated" },
  {value : "Add/Update", label : "When a %FORMLABEL% is created or updated" }             
];

const repeat_by_list = [
  //{value : "Mins", label : "Mins"},
  {value : "Hours", label : "Hours"},
  {value : "Daily", label : "Daily"},
  {value : "Weekly", label : "Weekly"},
  {value : "Monthly", label : "Monthly"},
];

const weekly_by_list = [
  {value : "Sunday", label : "Sunday"},
  {value : "Monday", label : "Monday"},
  {value : "Tuesday", label : "Tuesday"},
  {value : "Wednesday", label : "Wednesday"},
  {value : "Thursday", label : "Thursday"},
  {value : "Friday", label : "Friday"},
  {value : "Saturday", label : "Saturday"},
];

const date_count_list = [
  {value : "1", label : "1"},
  {value : "2", label : "2"},
  {value : "3", label : "3"},
  {value : "4", label : "4"},
  {value : "5", label : "5"},
  {value : "6", label : "6"},
  {value : "7", label : "7"},
  {value : "8", label : "8"},
  {value : "9", label : "9"},
  {value : "10", label : "10"},
  {value : "11", label : "11"},
  {value : "12", label : "12"},
  {value : "13", label : "13"},
  {value : "14", label : "14"},
  {value : "15", label : "15"},
  {value : "16", label : "16"},
  {value : "17", label : "17"},
  {value : "18", label : "18"},
  {value : "19", label : "19"},
  {value : "20", label : "20"},
  {value : "21", label : "21"},
  {value : "22", label : "22"},
  {value : "23", label : "23"},
  {value : "24", label : "24"},
  {value : "25", label : "25"},
  {value : "26", label : "26"},
  {value : "27", label : "27"},
  {value : "28", label : "28"},
  {value : "29", label : "29"},
  {value : "30", label : "30"},
  {value : "31", label : "31"},

];


const custom_filter_options = [
  [ "", "", "Record_Action", "Record Action", "custom_filter", ],
  ["", "", "Activities", "Activities", "custom_filter"],
  ["", "", "Calls", "Calls", "custom_filter"],
]

const subtable_options = [
  { value: "includes", label: "Include" },
  { value: "excludes", label: "Exclude" }
]

module.exports = {
  recordModifyOptions_1,
  recordModifyOptions_2,
  dateOperators,
  modify_dateOperators,
  durationOperators,
  activities_operators,
  activities_operators_1,
  activities_date_operators_1,
  activities_date_operators_2,
  picklist_operators,
  numeric_operators,
  text_operators,
  reference_operators,
  user_operators,
  durationOperatorsWithWeeks,
  durationWithMin,
  calls_operators_1,
  workflow_action_list,
  owners_action_list,
  recipients_option_list,
  duration_option_list,
  user_action_list,
  type_of_action_list,
  custom_filter_options,
  repeat_by_list,
  weekly_by_list,
  date_count_list,
  recipients_mobile_option_list,
  durationDateTime,
  subtable_options
};
