export const FieldTypeOptions = [
    {
        label : "Text",
        value : "Text"
    },
    {
        label : "Int",
        value : "Int"
    },
    {
        label : "Date",
        value : "date"
    },
    {
        label : "URL",
        value : "Hyperlink"
    },
    {
        label : "CheckBox",
        value : "CheckBox"
    },
    {
        label : "Dropdown",
        value : "ComboBox"
    },
];

export const ContactStatusOptions = [
    { value: 'Subscribed', label: 'Subscribed' },
    { value: 'Unsubscribed', label: 'Unsubscribed' }
];