import React from 'react';
import { getPickListItems, parseJSON } from "../../../Common/Appcommon";
import Select from "react-select";
import cx from "classnames";
import requestApi from "../../../services/requestApi.service";

class CratioDropdownField extends React.Component {
    constructor(props) {
        super(props);
        let { value, defaultValue, attributes } = this.props;
        let fieldsOptions = [];
        let defaultOption = [];
        let isTagField = false;
        let tagFormname = "";
        if (attributes === undefined || attributes['custom_property'] === undefined || attributes['custom_property'] === "") {
            if (defaultValue !== "" && defaultValue !== undefined) {
                let pickListDetials = defaultValue.split(",");
                let pickListName = pickListDetials[0];
                let pickListItems = getPickListItems(pickListName);
                pickListItems.map((listItem, index) => {
                    fieldsOptions.push({ value: listItem, label: listItem });
                })
                if (value === undefined || value === pickListDetials[1] || value === "") {
                    defaultOption = [{ value: pickListDetials[1], label: pickListDetials[1] }];
                } else {
                    defaultOption = [{ value: value, label: value }];
                }
            }
        } else if (attributes !== undefined && attributes['custom_property'] !== undefined && attributes['custom_property'] !== "") {
            isTagField = true;
            if (attributes['tag_formname'] !== undefined) {
                tagFormname = attributes['tag_formname'];
            }
            if (value !== "" && value !== undefined) {
                defaultOption = parseJSON(value);
            }
        }
        this.state = {
            selectedOption: defaultOption,
            fieldsOptions: fieldsOptions,
            isTagField: isTagField,
            tagFormname: tagFormname,
            isAddNewList: false,
            newLinkData: [],
        }
    }

    componentDidMount = async () => {
        if (this.state.isTagField) {
            await this.getTagList();
        }
    }

    getTagList = async (isNeedSelected = true) => {
        let { tagFormname } = this.state;
        let { isNew, formId, baseForm } = this.props;
        let params = {
            ENTITY: "WHATSAPPPROCESS",
            OPERATION: "GET_ALL_TAGS",
            FORMNAME: tagFormname
        };

        if (!isNew && formId !== "" && isNeedSelected) {
            params['isNew'] = isNew;
            params['formId'] = formId;
            params['baseForm'] = baseForm;
        }
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let data = response.data;
            let { options, selected } = data;
            if (isNeedSelected) {
                this.setState({
                    fieldsOptions: options,
                    selectedOption: selected
                })
            } else {
                this.setState({
                    fieldsOptions: options
                })
            }
        }
    }

    onHandleDropdownChange = (selectionOption) => {
        this.setState({
            selectedOption: selectionOption
        })
        this.props.onChange(false, selectionOption, this.props.attributes);
    }

    onAddMoreOption = (e) => {
        this.setState({
            isAddNewList: true
        })
    }

    saveNewLinkInfo = async () => {
        let { tagFormname, newLinkData, selectedOption } = this.state;
        let params = {
            ENTITY: "WHATSAPPPROCESS",
            OPERATION: "CREATE_TAG",
            FORMNAME: tagFormname,
            FORMINPUT: newLinkData['name']
        };
        let response = await requestApi.processServerRequest(params);
        if (response.issuccess) {
            let newLinkId = response.data;
            selectedOption.push({ value: newLinkId, label: params['FORMINPUT'] });
            this.setState({
                selectedOption: selectedOption,
                isAddNewList: false,
                newLinkData: []
            });
            await this.getTagList(false);
        }
    }

    cancelNewLinkInfo = async (e) => {
        this.setState({
            isAddNewList: false
        })
    }

    onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let { newLinkData } = this.state;
        newLinkData[name] = value;
        this.setState({ newLinkData: newLinkData });
    }

    render() {
        let { isTagField, isAddNewList } = this.state;
        let isMulti = false;
        if (this.props.isMulti !== undefined) {
            isMulti = this.props.isMulti;
        }
        return (
            <div className={cx(this.props.customClass)} {...this.props.attributes}>
                <Select value={this.state.selectedOption}
                    onChange={(e) => {
                        this.onHandleDropdownChange(e);
                    }} options={this.state.fieldsOptions}
                    className={`basic-multi-select ${this.props.inputClass}`}
                    classNamePrefix="wa-select"
                    isMulti={isMulti}
                    menuPlacement="top"
                />
                {isTagField && <span>Select contact list to assign to contact or <a className='cursor-pointer' onClick={(e) => this.onAddMoreOption(e)}>Click here</a> to add new.</span>}
                {isAddNewList && <div className='link-container d-flex'>
                    <div className='input-container'>
                        <input name="name" autoComplete='off' onChange={(e) => this.onInputChange(e)} className='form-control' placeholder='Enter name' type="text" />
                    </div>
                    <div className='btn-container align-center ml-1'>
                        <a onClick={(e) => { this.saveNewLinkInfo(e) }} className='theme-bg-g btn btn-dark'>Save</a>
                        <a onClick={(e) => { this.cancelNewLinkInfo(e) }} className='theme-bg-g btn btn-dark ml-1'>Cancel</a>
                    </div>
                </div>}
            </div>
        );
    }
}
export default CratioDropdownField;
