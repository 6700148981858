import React, { Component,Fragment } from 'react';
import { Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Label, } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {isMobile} from 'react-device-detect';
import cx from "classnames";
import Common from '../Common';
import ListTable from '../../Common/ListTable';
import aiData from "../../assets/utils/images/agent_no_records.png"
import { setSessionValue, showToast, prepareCustomTableData, confirmBox} from '../../Common/Appcommon';
import requestApiService from '../../services/requestApi.service';
import Loading from '../../Common/Loading';
import NoRecordFound from '../Common/NoRecordFound';
import TooltipItem from '../../Common/ToolTip';
import { Icon } from '@iconify/react';

class ContactsLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
            formname: "table_81",
            searchStr: "",
            data: [], // Assuming data is fetched and managed here
            columns: [], // Define columns for your table
            showDetailed: false,
            showLoader: false,
            ListName : '',
            Listid : '',
            Edit : false,
            isModalOpen: false,
            showButtonLoading: false,
    };
  }

  setShowLoaderStatus = async (status = false) => {
    this.setState({
        showLoader: status
    })
}
  componentDidMount = async () => {
    await this.setShowLoaderStatus(true);
    await this.getLists();
    await this.setShowLoaderStatus();

  };
  

  getLists = async (searchStr) => {
    const { formname } = this.state;
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "GET_LISTS_TAGS",
            "FORMNAME": formname,
            "SearchStr": searchStr,
        }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            let data = formresponse.data;
            let lists = data[0] ?? [];
            let columndetails = data[1] ?? [];
            await this.prepareListData(lists, columndetails);
        }
  }




  handleContactList = (row) => () => {
    let name = '';
    let edit = false;
    let listid = '';
    if(row['Name']){
      name = row['Name'];
      edit = true;
      listid = row['data_row']['list_tag_id'];
    }
    this.setState({
        isModalOpen: true, // Open the modal
        errorMessage: false,
        showButtonLoading: false,
        Edit : edit,
        ListName : name,
        Listid : listid
    });
};

closeModal = (name) => {
    this.setState((prevState) => ({
        isModalOpen: !prevState.isModalOpen,
        errorMessage: '',
        showButtonLoading: false,
        ListName : '',
        Edit : '',
        Listid: ''
    }));
}

prepareListData = async (formData, listFields) => {
  let data = [];
  let columns = [];
  let tableData = await prepareCustomTableData(formData, listFields, false, false, this.onViewAccountSettings);
  data = tableData.data;
  columns = [
          {
            name: <div style={{ margin: "auto" }}>Name</div>,
            sortable: false, // Example
            selector: (data) => data["Name"],
            style: {
              padding: 0,
              margin: 0,
              justifyContent: "center",
            },
          },
          {
            name: <div style={{ margin: "auto" }}>Total Contacts</div>,
            selector: (data) => data["contact_count"],
            sortable: false,
            style: {
              padding: 0,
              margin: 0,
              justifyContent: "center",
            },
          },
      ]; 

  columns.push(
      {
        name: (
          <div style={{margin: 'auto'}}>
              Action
          </div>
        ),
        selector: 'access',
        minWidth: '100px !important',
        maxWidth: '0% !important',
        margin: '0',
        cell: (row) => (
              <div>
                  <Button onClick={this.handleContactList(row)} className="theme-bg-info">
                        <TooltipItem
                            id={'1'}
                            text={"Edit list"}
                            content={<Icon icon="bx:edit-alt"/>}
                        />
                    </Button>
                    <Button onClick={() => this.deleteList(row)} className="user-account-btn theme-bg-danger mt-1">
                        <TooltipItem
                            id={'2'}
                            text={"Delete list"}
                            content={<i class="fi fi-rs-trash"></i>}
                        />
                    </Button>
              </div>
          ),
          style: {
              padding: 0,
              margin: 'auto',
              justifyContent: 'center'
          }
      },

  );

 this.setState({
      data, columns
  });
}

  backToViewAction = () => {
    this.setState({ showDetailed: false });
  };

  getModalContent = () => {
    let { isModalOpen, errorMessage, showButtonLoading,Edit } = this.state;
    return (
        <>

            <Modal isOpen={isModalOpen}>
                <ModalHeader toggle={this.closeModal}>
                    Add New List
                </ModalHeader>
                <ModalBody>
                    <Fragment>
                        <Label>List Name</Label>
                        <Input className="mb-2" onChange={e => { this.handleInputChange(e) }} maxLength={100} required value={this.state.ListName}></Input>
                    </Fragment>
                    <div>
                        {errorMessage && <em className="error">{errorMessage}</em>}</div>
                </ModalBody>
                <ModalFooter>
                    <Loading showLoader={showButtonLoading} mainContent={
                        <Fragment>
                          {Edit === true &&
                          <Button className='container-footer-save theme-bg-g' onClick={e => { this.editList(e) }}>
                          Edit List
                          </Button>
                          }
                          {Edit === false &&
                              <Button className='container-footer-save theme-bg-g' onClick={e => { this.addList(e) }}>
                                  Create New List
                              </Button>
                          }
                        </Fragment>
                    } />
                </ModalFooter>
            </Modal>
        </>
    );
}


handleInputChange = (e) => {
    let value = e.target.value;
    const regex = /^[a-zA-Z0-9 _-]*$/;
    if (regex.test(value)) {
        this.setState({
            ListName: value,
            errorMessage: (value !== undefined && value !== "") ? '' : this.state.errorMessage
        });
    } else {
        this.setState({
            errorMessage: "Special characters are not allowed."
        });
    }
}

addList = async (e) => {
    e.preventDefault();
    let { ListName } = this.state;
    const { formname } = this.state;
    if (ListName === "" || ListName === undefined) {
        this.setState({
            errorMessage: "List Name is required."
        })
    } else {
        await this.setShowLoaderStatus(true);
        this.setState({
            errorMessage: "",
            showButtonLoading: true,
        })
        let params = {
            "ENTITY": "AIPROCESSOR",
            "OPERATION": "ADD_AND_UPDATE_LISTS_TAGS",
            "FORMNAME": formname,
            "list_tag_name": ListName,
            "check": 'CREATE_LISTS_TAGS'
          }
        let formresponse = await requestApiService.processServerRequest(params, "WebV2");
        if (formresponse.issuccess) {
            this.closeModal();
            await this.getLists();
        }
        await this.setShowLoaderStatus();
    }
}


editList = async (e) => {
  e.preventDefault();
  let { ListName,Listid } = this.state;
  const { formname } = this.state;
  if (ListName === "" || ListName === undefined || Listid === undefined || Listid === "") {
      this.setState({
          errorMessage: "List Name is required."
      })
  } else {
      this.setState({
          errorMessage: "",
          showButtonLoading: true,
      })
      let params = {
          "ENTITY": "AIPROCESSOR",
          "OPERATION": "ADD_AND_UPDATE_LISTS_TAGS",
          "FORMNAME": formname,
          "list_tag_name": ListName,
          "list_tag_id":Listid,
          "check": 'UPDATE_LISTS_TAGS'
      }
      let formresponse = await requestApiService.processServerRequest(params, "WebV2");
      if (formresponse.issuccess) {
          this.closeModal();
          await this.getLists();
      }
  }
}

 deleteList = async (row) =>  {
  let confirm = await confirmBox("Are you sure you want to delete?", "Press Delete to confirm", 'warning', 'Delete', 'Cancel');
  if (!confirm) {
      return;
  }
  const { formname } = this.state;
  let params = {
      "ENTITY": "AIPROCESSOR",
      "OPERATION": "DELETE_LISTS_TAGS",
      "FORMNAME": formname,
      "list_tag_id": row['data_row']['list_tag_id']
  };
  let response = await requestApiService.processServerRequest(params, "WebV2");
  if (response.issuccess) {
      showToast("List deleted successfully.");
      await this.getLists();
  }
}


handleSearch = (e) => {
  let searchStr = e.target.value;
      this.setState({
          searchStr: searchStr
      })
      this.getLists(searchStr);
};

  getTitleBody = (showDetailed) => {
    if (showDetailed) {
      return "";
    }
    return (
      <div className="d-flex align-center justify-end">
        <Col
          md={7}
          className={cx("", {
            hide: isMobile,
          })}
        >
          <div className="le-form-row">
            <div className="input-with-icon">
              <span className="search-icon">
                <i className="icon-color fi fi-rs-search"></i>
              </span>
              <Input id="userName" autoComplete="off" placeholder="Search by name" 
              onChange={(e) => {
                  this.handleSearch(e)
              }} value={this.state.searchStr} className="search-input"/>
            </div>
          </div>
        </Col>
        <Col md={5} className="pl-2">
          <Button onClick={this.handleContactList({})} size="lg" className="theme-bg-g compact-padding" color="dark">
            <FontAwesomeIcon icon={faPlus} />
            Add List
          </Button>
        </Col>
      </div>
    );
  };


  render() {
    const { data, columns, showDetailed, isModalOpen } = this.state;

    return (
      <Fragment>
        <Common
          title={"Lists"}
          titleContent={<span>{"Lists"}</span>}
          titleBody={this.getTitleBody(showDetailed)}
          mainContent={
            <Fragment>
              <div className="d-grid w-100">
                {!showDetailed && (
                  <div className="list">
                    <Fragment>
                      
                      {!this.state.showLoader ? (
                        data.length === 0 ? (
                          <NoRecordFound
                            img={aiData}
                            isOnlyContent={true}
                            content={"No Users Found."}
                          />
                        ) : (
                          <div className="col-md-12">
                          <ListTable data={data} columns={columns} />
                          </div>
                        )
                      ) : (
                        <Loading showLoader={this.state.showLoader} />
                      )}
                    </Fragment>
                  </div>
                )}
                {showDetailed && <div className="col-md-12"></div>}
              </div>
              {isModalOpen && this.getModalContent()}
            </Fragment>
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsLists);


