import React, { Fragment } from "react";
import Dashboard from "../Dashboard/index";
import AgencyDashBoard from "../Agency/AgencyDashBoard";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { isAgentAccount } from "../../Common/Appcommon";

export const  MainDashboard = () =>{
    let agent = isAgentAccount();
    return(
        <Fragment>
            {
                agent ? 
                (
                    <AgencyDashBoard/>
                )
                :
                ( 
                    <Dashboard/>
                )
            }
        </Fragment>

    );
}


const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);