import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { formatDate, getLicenseInfo } from "../../Common/Appcommon";


const CancelPage = (props) => {
    let {customFunctions} = props;
    
    const handleBtnClick = async (name) => {
        if(name === "confirm_cancel"){
            let res = await customFunctions(name);
            if(res){
                customFunctions("cancel_btn");
            }
        }else{
            customFunctions(name);
        }
    }
    
    let license_end_date = getLicenseInfo("license_end_date");
    if(license_end_date !== ""){
        license_end_date = formatDate(license_end_date, "mmm dd,yyyy");
    }

    return (
        <Fragment>
            <div className="m-3 cancel-page">
                <div className={`listview-container templates-listview kv-main-wrapper h100per content`}>
                    <ul>We're sorry to hear you’re considering canceling your subscription.</ul>
                    <ul>Your cancellation will take effect at the end of your current billing period on  <strong className="error">{license_end_date}</strong>.</ul>
                    <ul>We truly appreciate the opportunity to serve you. If there's anything more we can do to help, please contact our support team.</ul>
                    <ul>Thank you for being with us!</ul>
                    <ul className="cancel-subscription-btn-container">
                        <Button 
                            className="mt-3 m-1 me-2 btn-icon cancel-sub-btn" 
                            onClick={e => handleBtnClick("confirm_cancel")}>
                                Finish Cancellation
                        </Button>
                        <Button 
                            className="mt-3 m-1 me-2 btn-icon le-button theme-bg-g" 
                            onClick={e => handleBtnClick("cancel_btn")}>
                                Cancel
                        </Button>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default CancelPage;