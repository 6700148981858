import React, { Fragment } from "react";
import { setAppCustomTitle } from "../../../reducers/ThemeOptions";
import { connect } from "react-redux";
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input, Row, Col, Card, CardBody
} from 'reactstrap';
import requestApi from '../../../services/requestApi.service';
import { parseJSON, showToast, maskKey, confirmBox } from '../../../Common/Appcommon';

class Stripe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publishableKey: '',
            secretKey: '',
            displaySecret: '',
            displayPublic: '',
            isValid: false,
            account_id : '',
            confirm:false,
            modal: false,
            errors: {},
        };
    }

    componentDidMount = async () => {
        await this.getStripeDetails();
    };

    toggle = () => {
        this.setState(prevState => ({ modal: !prevState.modal, errors: {},publishableKey : "",secretKey:"" ,confirm:false}));
    };

    getStripeDetails = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_STRIPE_DETAILS",
        };
        const response = await requestApi.processServerRequest(params, "WebV2");
        if (response.issuccess && response.data.length > 0) {
            const formResponse = response.data[0];
            const publickey = parseJSON(atob(formResponse[1]));
            const secretKey = parseJSON(atob(formResponse[2]));

            this.setState({
                account_id: formResponse[0],
                isValid: true,
                displayPublic: maskKey(publickey),
                displaySecret: maskKey(secretKey),
            });
        }
    };


    setStripeData = async () => {
        const { account_id, publishableKey, secretKey, confirm } = this.state;
    
        // Basic validation
        const errors = {};
        if (!publishableKey) errors.publishableKey = "Publishable key is required.";
        if (!secretKey) errors.secretKey = "Secret key is required.";

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return; // Exit if there are errors
        }

        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "SAVE_STRIPE_DETAILS",
            accountid: account_id,
            publicKey: publishableKey,
            secretKey: secretKey,
            confirm: confirm,
        };
        const response = await requestApi.processServerRequest(params, "WebV2");
        console.log(response);
        if (response.issuccess) {
            showToast("Success! Saved successfully!");
            await this.getStripeDetails();
        } else {
            if (!confirm && response.errormsg === "Changing Stripe Details") {
                const userConfirmed = await confirmBox(
                    "Changing the Stripe account will affect the customer subscription",
                    "Press Update to confirm.",
                    'warning',
                    'Update',
                    'Cancel'
                );
                if (!userConfirmed) {
                    return;
                }
                this.setState({ confirm: true }, this.setStripeData);
                return;
            }
            // Handle other errors
            showToast(response.errormsg || "Error! Unable to save.", 'top-right', 'error');
        }
        this.toggle();
    };

    handleChange = (e) => {
        const { id, value } = e.target;
        this.setState({ [id]: value, errors: { ...this.state.errors, [id]: null } });
    };


    getModalContent = () => {
        const { publishableKey, secretKey, errors } = this.state;

        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle} className="heading">
                    Connect with Stripe
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Publishable Key</Label>
                        <Input
                            type="text"
                            id="publishableKey"
                            value={publishableKey}
                            onChange={this.handleChange}
                            placeholder="Enter Publishable Key"
                            required
                            className={errors.publishableKey ? 'is-invalid' : ''}
                            autoComplete="off"
                        />
                        {errors.publishableKey && <div className="invalid-feedback">{errors.publishableKey}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label>Secret Key</Label>
                        <Input
                            type="text"
                            id="secretKey"
                            value={secretKey}
                            onChange={this.handleChange}
                            placeholder="Enter Secret Key"
                            required
                            className={errors.secretKey ? 'is-invalid' : ''}
                            autoComplete="off"
                        />
                        {errors.secretKey && <div className="invalid-feedback">{errors.secretKey}</div>}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button className="theme-bg-g" onClick={this.setStripeData}>
                        Confirm Stripe Account
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { isValid, modal, displayPublic, displaySecret } = this.state;

        return (
            <Fragment>
                <div className="d-container container m-auto max-width-65">
                    <div className="agency-details w-100">
                        <Card className='card-br'>
                            <CardBody className='pb-6'>
                                <Row className="dns-row">
                                    <Col md={9}>
                                        <span className="heading">Stripe</span>
                                        <span className="header-desc ml-5">
                                            Connect your Stripe account to start accepting payments.
                                        </span>
                                    </Col>
                                    <Col md={3}>
                                        <Button className="theme-bg-g" onClick={this.toggle}>
                                            {!isValid ? ('Connect with Stripe') : ('Modify Stripe Connection')}
                                        </Button>
                                    </Col>
                                </Row>

                                {isValid && (
                                    <div className="card-fields d-grid">
                                        <div className="cs-domain">
                                            <Row className="g-0s mt-2">
                                                <Col md={10}>
                                                    <Label for="Publickey" className="header-desc">Publishable Key</Label>
                                                    <Input
                                                        type="text"
                                                        id="Publickey"
                                                        value={displayPublic}
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="w-auto mt-4">
                                                <Col md={10}  >
                                                    <Label for="Secretkey" className="heading">Secret Key</Label>
                                                    <Input
                                                        type="text"
                                                        id="Secretkey"
                                                        value={displaySecret}
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )}
                                {modal && this.getModalContent()}

                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
    setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);
