export const filterOptions = {
    campaign: [
        {
            label: "All Active",
            value: "all"
        },
        {
            label: "Draft",
            value: "draft"
        },
        {
            label: "Scheduled",
            value: "scheduled"
        },
        {
            label: "Progress",
            value: "progress"
        },
        {
            label: "Completed",
            value: "completed"
        },
    ],
    automation: [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Active",
            value: "active"
        },
        {
            label: "Inactive",
            value: "inactive"
        },
    ]
};

export const multiDial = [
    {
        value: "off",
        label: "Off",
    },
    {
        value: "2_times",
        label: "Dial 2 times in a row",
    },
    {
        value: "3_times",
        label: "Dial 3 times in a row",
    },
];

export const userActionList = [
    { value: "Add", label: "When a Contact is created" },
    { value: "Update", label: "When a Contact is updated" },
    { value: "Add/Update", label: "When a Contact is created or updated" },
];

export const variableList = [
    {
        name: "min",
        type: "number",
        label: "Send After",
        placeholder: "Ex: 10",
        requiremsg: "This field is required",
        colMd: 3
    },
    {
        name: "hours",
        type: "options",
        label: "",
        placeholder: "Ex: Hours",
        requiremsg: "This field is required",
        colMd: 3,
        options: [
            {
                label: "Minutes",
                value: "minute",
            },
            {
                label: "Hours",
                value: "hour",
            },
            {
                label: "Days",
                value: "day",
            },
        ]
    },
    {
        name: "multi_dial",
        label: "Multi-dial",
        type: "options",
        requiremsg: "This field is required",
        options: multiDial,
        colMd: 5,
    },
    {
        name: "",
        label: "Remove",
        icon: "fi fi-rr-circle-xmark",
        type: "icon",
        colMd: 1,
    },
];

export const durationList = [
    {
        label: "Minutes",
        value: "minutes",
        min: 1,
    },
    {
        label: "Hours",
        value: "hours",
        min: 60,
    },
    {
        label: "Day",
        value: "day",
        min: 1440,
    }
]