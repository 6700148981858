import React, { Fragment,useEffect, useState } from 'react';
import { graphAgenListColumns } from "./constant";
import { TableDashboard } from '../../Dashboard/TableDashboard';
import { AgencyCallDetails } from '../../Dashboard/agencyCallDetails';
export const Graphdashboard = () => {
    return (
        <>
            {<AgencyCallDetails/>}
            {
                graphAgenListColumns && graphAgenListColumns.map((val) => {
                    if (val.type === "table") {
                        let valArr = [];
                        valArr.push(val);
                        return <TableDashboard tableColList={valArr} />;
                    }
                })
            }
        </>
    )
}