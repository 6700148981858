import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import DateFilter from "../../Common/DateFilter";
import { Button, Input } from "reactstrap";
import { setAppCustomTitle } from "../../reducers/ThemeOptions";
import { connect, connectAdvanced } from "react-redux";
import Loading from "../../Common/Loading";
import {
  populateFormDetails,
  getReferenceList,
  showToast,
  isMobile,
} from "../../Common/Appcommon";
import { subDays } from "date-fns";

const Filter = (props) => {
  const { CallTypeOption = [] } = props;
  const startDate = subDays(new Date(), 30);
  const endDate = new Date();
  const [dateFilter, setDateFilter] = useState({
    Start_DateTime: [startDate, endDate],
    noofdays: 30,
  });
  const [defAgntDrpDwnLst, setDefAgntDrpDwnLst] = useState({});
  const [defCallType, setDefCallType] = useState(CallTypeOption[0]);
  const [agentDropDownList, setAgentDropDownList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [compareAgent1, setCompareAgent1] = useState("");
  const [compareAgent2, setCompareAgent2] = useState("");
  const [compareAgentOption, setCompareAgentOption] = useState({});
  const [callogvalue1, setCallLogValue1] = useState([]);
  const [callogvalue2, setCallLogValue2] = useState([]);
  const [callogFilter1, setCallLogFilter1] = useState([]);
  const [callogFilter2, setCallLogFilter2] = useState([]);
  let mobile = props.neeedbottomfilter ?? isMobile();

  const {
    needCallFilter,
    needDateFilter,
    needAgentFilter,
    getdate,
    getAgentvalue,
    getcallType,
    callogFilters,
    needAgentCompareFilter = false,
    getCompareAgentvalue,
    callLogOption1,
    callLogOption2,
  } = props;
 
  // Fetch agent names
  const fetchAgentDetails = async () => {
    setShowLoading(true);
    let formDetail = await populateFormDetails("table_37");
    let fDetail = formDetail[1];
    let refDetail = fDetail.filter(
      (record) => record[2] === "table_250_0_table_250_id"
    )[0];
    if (refDetail && refDetail[35] !== undefined) {
      let res = await getReferenceList(refDetail[35]);
      if (res.issuccess) {
        let optionList = res.data;
        let dropDown = optionList[0];
        let allAgentsOption = { value: "all_agent", label: "All Agents" };
        dropDown.unshift(allAgentsOption);
        setDefAgntDrpDwnLst(allAgentsOption);
        setAgentDropDownList(dropDown);
        if (needAgentCompareFilter) {
          dropDown.shift();
          let limitdropDownlist = dropDown; //.slice(0, 4)
          limitdropDownlist.map((data, index) => {
            if (data.value === "1") {
              setCompareAgent1(dropDown[index]);
            } else if (data.value === "2") {
              setCompareAgent2(dropDown[index]);
            }
          });
          setCompareAgentOption(limitdropDownlist);
        }
      }
    }
    setShowLoading(false);
  };

  // Handle date changes
  const handleDateChange = (startDate, endDate) => {
    if (startDate && endDate) {
      let dateobj = {};
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const numberOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
      dateobj["Start_DateTime"] = [startDate, endDate];
      dateobj["noofdays"] = numberOfDays;
      setDateFilter(dateobj);
      if (getdate && !needAgentCompareFilter) {
        getdate(dateobj);
      }
    }
  };

  // Handle agent selection
  const onAgentSelectionChange = (selectedOption) => {
    setDefAgntDrpDwnLst(selectedOption);
    if (getAgentvalue) {
      getAgentvalue(selectedOption);
    }
  };

  // Handle call type selection
  const onCallTypeSelectionChange = (selectedOption) => {
    setDefCallType(selectedOption);
    if (getcallType) {
      getcallType(selectedOption);
    }
  };

  const exportCSV = (e) => {
    props.onClickExports(e);
  }

  // Handle Compare Agent Selection
  const handleCompareAgentChange = (event, agentNumber) => {
    if (
      (agentNumber === 1 && event.value === compareAgent2?.value) ||
      (agentNumber === 2 && event.value === compareAgent1?.value)
    ) {
      showToast(
        "Selecting the same agent for comparison is not allowed.",
        "top-right",
        "error"
      );
      return;
    }
    if (agentNumber === 1) {
      setCompareAgent1(event);
    } else if (agentNumber === 2) {
      setCompareAgent2(event);
    }
  };

  const handleCallogFilters1 = (value) => {
    if(value !== '' || value !== undefined){
        setCallLogValue1(value);
        setCallLogFilter1(value.label);
    }else {
        setCallLogValue1(null);
        setCallLogFilter1(null);
    }
  };
  const handleCallogFilters2 = (value) => {
    console.log(value)
    if(value !== undefined && value !== null){
        if (value.label === "Recipients") {
            setCallLogFilter2("total_calls");
          } else if (value.label === "Call Initiated") {
            setCallLogFilter2("total_calls");
          } else if (value.label === "Connected Calls") {
            setCallLogFilter2("connected_calls");
          } else if (value.label === "Not Connected Calls") {
            setCallLogFilter2("not_connected_calls");
          } else if (value.label === "Goal Achieved") {
            setCallLogFilter2("goals_achieved");
          } else if (value.label === "Goal Not Achieved") {
            setCallLogFilter2("goals_not_achieved");
          }
          setCallLogValue2(value);
    } 
    setCallLogValue2(value);
    //setCallLogFilter2(value);
  };

  const handleSearchClick = () => {
    let value1 = compareAgent1.value;
    let value2 = compareAgent2.value;
    if (getCompareAgentvalue) {
      getCompareAgentvalue([value1, value2], dateFilter);
    }
  };

  const searchCallLogs = () => {
    if (
      (callogFilter1 !== "" && callogFilter1 !== null) ||
      (callogFilter2 !== "" && callogFilter2 !== null)
    ) {
      let autocampaign = callogFilter1;
      let statusfilter = callogFilter2;
      let filterValues = {
        status: statusfilter,
        whatsapp: autocampaign,
        date: dateFilter,
      };
      props.getCallLogs("", filterValues);
    }
  };
  // Fetch agent names once on mount
  useEffect(() => {
    fetchAgentDetails();
    handleSearchClick();
  }, []);

  return (
    <Loading
      showLoader={showLoading}
      mainContent={
        <Fragment>
          <div className={`${mobile ? "Mobile" : "Web"}`}>
            <div className={`filter-container custome-filter`}>
              <div className="d-flex justify-content-end">
                {/* Call Type Dropdown */}
                {needCallFilter && (
                  <div className="me-2 filter-width">
                    <Select
                      name="selected_form"
                      id="selected_form"
                      className="basic-select"
                      classNamePrefix="sub-select wa-select"
                      value={defCallType}
                      onChange={onCallTypeSelectionChange}
                      options={CallTypeOption}
                    />
                  </div>
                )}

                {/* Agent Dropdown */}
                {needAgentFilter && (
                  <div className="me-2 filter-width">
                    <Select
                      name="selected_form"
                      id="selected_form"
                      className="basic-select"
                      classNamePrefix="sub-select wa-select"
                      value={defAgntDrpDwnLst}
                      onChange={onAgentSelectionChange}
                      options={agentDropDownList}
                    />
                  </div>
                )}
                {needAgentCompareFilter && (
                  <>
                    <div className="me-2 filter-width">
                      <Select
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={compareAgent1}
                        onChange={(e) => handleCompareAgentChange(e, 1)}
                        options={compareAgentOption}
                      />
                    </div>

                    <div className="me-2 filter-width">
                      <Select
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={compareAgent2}
                        onChange={(e) => handleCompareAgentChange(e, 2)}
                        options={compareAgentOption}
                      />
                    </div>
                  </>
                )}
                {/* Call Log Filters */}
                {callogFilters && (
                  <>
                    <div className="me-2 filter-calllogs-width">
                      <div className="input-with-icon">
                        <span className="search-icon">
                          <i className="icon-color fi fi-rs-search"></i>
                        </span>
                        <Input
                          id="userName"
                          className="search-input"
                          autoComplete="off"
                          placeholder="Search by name"
                          onChange={(e) => {
                            this.handleSearch(e);
                          }}
                          value={""}
                        />
                      </div>
                    </div>
                    <div className="me-1 filter-calllogs-width">
                      <Select
                        placeholder="All"
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={callogvalue1}
                        onChange={(value) => handleCallogFilters1(value)}
                        options={callLogOption1.map(({ value, lable }) => ({
                          value,
                          label: lable,
                        }))}
                        isClearable={true}
                      />
                    </div>
                    <div className="me-1 filter-calllogs-width">
                      <Select
                        placeholder="All"
                        name="selected_form"
                        id="selected_form"
                        className="basic-select"
                        classNamePrefix="sub-select wa-select"
                        value={callogvalue2}
                        onChange={(e) => handleCallogFilters2(e)}
                        options={callLogOption2.map(({ value, lable }) => ({
                          value,
                          label: lable,
                        }))}
                        isClearable={true}
                      />
                    </div>

                    <div className="me-2">
                      <DateFilter
                        handleDateChange={handleDateChange}
                        defaultSelectIndex={2}
                        onfirstnotneed={true}
                      />
                    </div>
                    <div className="me-2 mt-n1">
                      <Button
                        className="btn btn-secondary theme-bg-g btn-hover-shine"
                        onClick={e => exportCSV(e)}
                      >
                        <i className="fi fi-br-search me-2"></i>Downloaded as CSV
                      </Button>
                    </div>
                    <div className="me-2 mt-n1">
                      <Button
                        className="btn btn-secondary theme-bg-g btn-hover-shine"
                        onClick={searchCallLogs}
                      >
                        <i className="fi fi-br-search me-2"></i>Search
                      </Button>
                    </div>
                  </>
                )}
                {/* Date Filter */}
                {needDateFilter && (
                  <div className="me-2">
                    <DateFilter
                      handleDateChange={handleDateChange}
                      defaultSelectIndex={3}
                      onfirstnotneed={true}
                    />
                  </div>
                )}
                {needAgentCompareFilter && (
                  <div className="me-2">
                    <Button
                      className="btn btn-secondary theme-bg-g btn-hover-shine"
                      onClick={handleSearchClick}
                    >
                      <i className="fi fi-br-search me-2"></i>Generate
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      }
    />
  );
};
const mapStateToProps = (state) => ({
  appCustomTitle: state.ThemeOptions.appCustomTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setAppCustomTitle: (title) => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
