import React, { Fragment, useEffect, useMemo, useState } from 'react';

const CallsSummary = (props) => {
    let { rowdata, lablename, nameLabelMapper } = props;
    let [parsedSummary, setParsedSummary] = useState("");

    const mappedCallSummary = useMemo(() => {
        return rowdata?.[nameLabelMapper?.["Call_Summary"]];
    }, [rowdata, nameLabelMapper]);

    useEffect(() => {
        const handler = setTimeout(() => {
            try {
                if (mappedCallSummary) {
                    let sumdata = JSON.parse(mappedCallSummary);
                    setParsedSummary(sumdata);
                }else{
                    setParsedSummary("");
                }
            } catch (error) {
                console.error("Failed to parse Call_Summary:", error);
                setParsedSummary(""); // Reset the state on error
            }
        }, 100); // Debouncing with 300ms

        return () => clearTimeout(handler); // Cleanup the timeout
    }, [mappedCallSummary]);

    return (

        <Fragment>
            <div className='call-summary-container'>
                <div>
                    <span className='call-summary-label'>{lablename}</span>
                </div>
                {
                    parsedSummary && parsedSummary !== "" ? (
                        <div className='call-summary-content'>
                            <ul>
                                <li>{parsedSummary}</li>
                            </ul>
                        </div>
                    ) : (
                        <div className='call-summary-content'>No Summary Found</div>
                    )
                }
            </div>

        </Fragment>
    );
}


export default CallsSummary;