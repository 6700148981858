import React, { Fragment } from "react";
import { useHistory } from 'react-router-dom';

const GobackButton = (props) => {
    const history = useHistory();
    let {className, customFunction, name,  label} = props;

    const goBack = (name) => {
        if(name === ""){
            history.goBack();
        }else{
            customFunction(name);
        }
    };

    if(!label){
        label = "Go Back"
    }
    if(!name){
        name = ""
    }
    if(!className){
        className = ""
    }

    return (
        <Fragment>
            <div className={"m-3 " + className} style={{gap: "8px", display: "flex"}}>
                <a className='cursor-pointer mr-3' onClick={(e)=>{goBack(name)}}>
                    <span className='d-flex align-center'>
                        <i class="fi fi-br-arrow-left pr-1"></i> <span>Go Back</span>
                    </span>
                </a>
            </div>
        </Fragment>
    );
}

export default GobackButton;