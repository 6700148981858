import React, { Fragment } from "react";
import cx from "classnames";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSearch: false,
      searchTerm: "",
      moduleName :""
    };
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ searchTerm: "" ,activeSearch : false});
      this.props.onSearch("");
    }
  }

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
    this.props.onSearch(event.target.value); 
  };

  handleClearSearch = () => {
    this.setState({ searchTerm: "" }, () => {
      this.props.onSearch("");  
    });
  };
  render() {
    let moduleName = this.props.moduleName;
    return (
      <Fragment>
        <div className={cx("search-wrapper ", { 
            active: this.state.activeSearch,
            "search-phonenumbers": this.props.moduleName === 'PhoneNumber'
          })}>
          <div className={cx("input-holder", { "ms-2": this.props.moduleName !== 'PhoneNumber' })}>
            <input
              type="text"
              className="search-input"
              placeholder="Type to search"
              value={this.state.searchTerm}
              onChange={this.handleSearchChange}
            />
            <button
              onClick={() => this.setState({ activeSearch: !this.state.activeSearch })}
              className="search-icon theme-c-g"
            >
              <i class="search-icon-size fi fi-rr-assessment"></i>
            </button>
          </div>
          <button
            onClick={() => {
              this.setState({ activeSearch: !this.state.activeSearch });
              this.handleClearSearch(); 
            }}
            className="btn-close ms-2"
          />
        </div>
      </Fragment>
    );
  }
}

export default SearchBox;
