import React, { Fragment, createRef } from 'react';
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import Common from '../Common';
import UsageDashboard from "./UsageDashboard";
import "./assets/index.css";
import requestApi from '../../services/requestApi.service';
import { parseJSON, getCredentials, confirmBox, showToast, getOrganizationInfo, getAgencyInfo, getCurrentPlanInfo } from '../../Common/Appcommon';

class Usage extends React.Component {
    formname = "table_37";
    agency_appid = getAgencyInfo("appid");
    accountid = getOrganizationInfo("Account_ID");
    constructor(props){
        super(props);
        this.state = {
            formData : [],
            dateFilter : [],
            summary: [],
            summaryData : [],
            showFormLoader : true,
            mainLoader: true,
            licenseInfo : [],
            paymentDetails : {},
            currentPlanInfo: {},
            modalOpen: false,
            stripePromise: null,
            currentPage: 1,
            refSubmitBtn: createRef(),
            headerBtnName: ""
        };
    }

    componentDidMount = async () => {
        await this.processLoadData();
    };

    processLoadData = async () => {
        this.getLiceneInfo();
        let currentplan = await getCurrentPlanInfo();
        this.getSummaryBoxValue();
        let stripe = await getCredentials();
        this.setState({
            stripePromise: stripe,
            mainLoader: false,
            currentPlanInfo: currentplan
        })
    };

    getSummaryBoxValue = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_USAGE_SUMMARY",
            FORMNAME: this.formname,
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                this.setState({
                    summaryData: data
                });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }

    processMainLoader = () => {
        this.setState(prevState => ({
            mainLoader: !prevState.mainLoader
        }))
    }

    handleSubmitClick = () => {
        if (this.state.refSubmitBtn.current) {
            this.state.refSubmitBtn.current.click(); 
        }
    };

    getUsageDataForListView = async () => {
        let { dateFilter } = this.state;
        if(dateFilter.length === 0){
            dateFilter[0] = (() => { const date = new Date(); date.setDate(date.getDate() - 29); return date; })();
            dateFilter[1] = new Date();
        }
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_USAGE_LIST_DATA",
            FORMNAME: this.formname,
            datefilter: dateFilter,
            isOtherApiReq: true,
        };
    
        try {
            this.setState({ showFormLoader: true });
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data;
                this.setState({ formData: data, showFormLoader: false });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }   
    
    handleDateChange = async (startDate, endDate) => {
        if (startDate && endDate) {
            this.setState({ dateFilter: [startDate, endDate] }, async () => {
                await this.getUsageDataForListView();
            });
        }
    }

    getLiceneInfo = async () => {
        const params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_LICENSE_INFO",
            isOtherApiReq: true,
        };
    
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                let data = response.data
                let payment_details = data[0]['paymentdetails'];
                if(payment_details === ""){
                    payment_details = {};
                }else{
                    payment_details = parseJSON(payment_details)
                }
                this.setState({
                    paymentDetails: payment_details,
                    licenseInfo: data,
                    showFormLoader: false
                });
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
    }

    customFunctions = async (name, pageNo = 0) => {
        const stateUpdates = {
            update_btn: { currentPage: pageNo, headerBtnName: "Update Plan" },
            cancel_btn: { currentPage: pageNo, headerBtnName: "" },
            trail_page: { currentPage: pageNo, headerBtnName: "" },
            trail_payment: { currentPage: pageNo, headerBtnName: "Subscribe Plan" },
            sucess_payment: { currentPage: pageNo, headerBtnName: "" },
            hideHeaderBtn: { headerBtnName: "" },
        };

        if (name === "confirm_cancel") {
            const confirm = await confirmBox(
                "Are you sure you want to cancel?",
                "Press 'OK' to confirm your subscription cancellation."
            );
    
            if(confirm){
                this.setState({ mainLoader: true });
    
                const isCancelled = await this.processCancelRequest();
                if(isCancelled) {
                    showToast("Cancelled Successfully");
                    await this.getLiceneInfo();
                }
                this.setState({
                    mainLoader: false,
                })
            }
            return confirm;
        }
    
        if (stateUpdates[name]) {
            this.setState(stateUpdates[name]);
            if (name === "sucess_payment") {
                await this.processLoadData();
            }
        }
    };
    

    toggleModal = () => {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen
        }));
    }
    
    processCancelRequest = async () => {
        let cancelRequest = false;
        const params = {
            ENTITY: "STRIPE_PROCESSOR",
            OPERATION: "CANCEL_CARD",
            accountid: this.accountid,
            agency_appid: this.agency_appid,
            isOtherApiReq: true,
        };
        try {
            let response = await requestApi.processServerRequest(params);
            if (response.issuccess) {
                cancelRequest = true;
            }
        } catch (error) {
            console.error("Error fetching dashboard summary:", error);
        }
        return cancelRequest;
    }

    render() {
        let {formData, summaryData, showFormLoader, mainLoader, paymentDetails, currentPlanInfo, licenseInfo, currentPage, refSubmitBtn, headerBtnName } = this.state;
        let bodyHeader = "Billing & Usage";
        if(currentPage === 3){
            bodyHeader = "Cancel Subscription";
        }else if(currentPage === 2){
            bodyHeader = "Manage Subscription";
        }else if(currentPage === 4){
            bodyHeader = "Subscription";
        }else{
            headerBtnName = "";
        }

        return (
            <Fragment>
                {
                    <Common
                        title={"Usage"}
                        titleContent={
                            <span>{bodyHeader}</span>
                        } 
                        titleBody={
                            <Fragment>
                                {
                                    headerBtnName !== "" && 
                                    <Button 
                                        onClick={e => this.handleSubmitClick()} 
                                        className="theme-bg-g m-1">
                                            {headerBtnName}
                                    </Button>
                                }
                            </Fragment>
                        }
                        mainLoader={mainLoader}
                        mainContent={
                            <UsageDashboard
                                showFormLoader={showFormLoader}
                                summaryData={summaryData}
                                formData={formData}
                                handleDateChange={this.handleDateChange}
                                currentPlanInfo={currentPlanInfo}
                                paymentDetails={paymentDetails}
                                licenseInfo={licenseInfo}
                                customFunctions={this.customFunctions}
                                refSubmitBtn={refSubmitBtn}
                                processMainLoader={this.processMainLoader}
                            />
                        } 
                    />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usage);
