import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import { Container } from 'reactstrap';
import UsageSummary from "./UsageSummary";
import ListTable from "../../Common/ListTable";
import DateFilter from "../../Common/DateFilter";
import Loading from "../../Common/Loading";
import CurrentPlan from "./CurrentPlan";
import PricingPage from "../Subscription/PricingPage";
import CancelPage from "./CancelPage";
import ManageSubscription from "../Subscription/ManageSubscription";
import { getPlanPricing } from "../../Common/Appcommon";

const UsageDashboard = (props) => {
    const {formData, summaryData, showFormLoader, currentPlanInfo, paymentDetails, licenseInfo, customFunctions, refSubmitBtn, processMainLoader} = props;

    /**
     * 1 - home page
     * 2 - manange subscription page
     * 3 - cancel subscription page
     * 4 - subscription page
     * 5 - plans empty for this customer
     */
    const [currentPage, setCurrentPage] = useState(1);
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const plans = await getPlanPricing();
                setPlans(plans);
            } catch (error) {
                console.error("Error fetching plans:", error);
            }
        };
    
        fetchPlans();
    }, []);
    

    useEffect(() => {
        const checkPaymentDetails = async () => {
            let paymentlen = Object.keys(paymentDetails).length;
            if (paymentlen === 0 || paymentDetails.plan_id === undefined) {
                setCurrentPage(4);
                customFunctions("trail_page", 4);
            } else {
                setCurrentPage(1);
                customFunctions("trail_page", 1);
            }
        };
    
        checkPaymentDetails();
    }, [paymentDetails]);

    const columns = [
        {
            name: (
                <div>
                    S.No
                </div>
            ),
            selector: (row, index) => index + 1,
            sortable: true,
            style: {
                padding: 0,
                margin: 0,
                justifyContent: 'center'
            },
            width: "60px !important",
        },
        {
            name: (
                <div>
                    Date
                </div>
            ),
            selector: row => row["date"],
            sortable: true,
        },
        {
            name: (
                <div>
                    Total Calls
                </div>
            ),
            id: "total_calls",
            selector: row => row["total_calls"],
            sortable: true,
        },
        {
            name: (
                <div>
                    Total Minutes
                </div>
            ),
            id: "minutes",
            selector: row => row["minutes"],
            sortable: true,
        },
      
        {
            name: (
                <div>
                    Cost
                </div>
            ),
            selector: row => row["amount"],
            sortable: true,
        }
    ];

    const customFunction = (name) => {
        if(name === "cancel_btn"){
            let pageNo = currentPage === 3 ? 1 : 3;
            customFunctions(name, pageNo);
            setCurrentPage(pageNo);
        }else if(name === "update_btn"){
            let pageNo = currentPage === 2 ? 1 : 2;
            customFunctions(name, pageNo);
            setCurrentPage(pageNo);
        }else if(name === "confirm_cancel"){
            return customFunctions(name);
        }else if(name === "sucess_payment"){
            customFunctions(name, 1);
            setCurrentPage(1);
        }else if(name === "showSubBtn" || name === "trail_payment"){
            return customFunctions(name, 4);
        }else if(name === "trail_page"){
            let pageNo = 4;
            setCurrentPage(pageNo);
            customFunctions(name, pageNo);
        }else if(name === "hideHeaderBtn"){
            customFunctions(name);
        }
    }


    const UsageSummaryPageContainer = () => {
        return(
            <div className="usage-dashboard-container">
                <div className="usage-summary-container">
                    <UsageSummary summaryData={summaryData} />
                </div>
                {
                    plans.length > 0 && 
                    <div className="usage-summary-container">
                        <CurrentPlan 
                            currentPlanInfo={currentPlanInfo} 
                            paymentDetails={paymentDetails}
                            licenseInfo={licenseInfo}
                            customFunctions={customFunction} 
                        />
                    </div>
                }
                <div className="usage-list-view">
                    <div className="list-view-header mb-3 mt-4 ms-2">
                        <div className="usage-list-header mt-3 fw-bold">
                            Usage Log
                        </div>
                        <div className="date-filter">
                            <DateFilter handleDateChange={props.handleDateChange} defaultSelectIndex={3}/>
                        </div>
                    </div>
                    <div className="list-view-box ms-1 mr-1 mb-5">
                        <Loading 
                            showLoader={showFormLoader} 
                            mainContent={
                                <div className="usage-report ms-3 me-3">
                                    <ListTable columns={columns} data={formData} />
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }

    const processCustomeFunction = (name) => {
        customFunction(name)
    }

    return(
        <Fragment>
            <Container fluid>
                {
                    (currentPage === 1 || plans.length === 0) && UsageSummaryPageContainer()
                }
                {
                    currentPage === 2 && 
                        <ManageSubscription 
                            customFunction={processCustomeFunction} 
                            isUsagePage={true} 
                            selectedPlan={currentPlanInfo} 
                            refSubmitBtn={refSubmitBtn} 
                            processMainLoader={processMainLoader} 
                            noofMonths={paymentDetails.noofMonths} 
                        />
                }
                {
                    currentPage === 3 && 
                        <CancelPage 
                            customFunctions={customFunction} 
                            licenseInfo={licenseInfo}
                        />
                }
                {
                    currentPage === 4 && 
                        <PricingPage 
                            refSubmitBtn={refSubmitBtn} 
                            isTrail={true} 
                            currentPlanInfo={currentPlanInfo} 
                            customFunction={processCustomeFunction} 
                            processMainLoader={processMainLoader}
                        />
                }
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageDashboard);
