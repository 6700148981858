import React, { useState, useEffect } from "react";
import Select from "react-select";
import requestApi from "../../../services/requestApi.service";
import cx from "classnames";

const CratioLookup = (props) => {
  const defaultVal = props.value != undefined ? props.value : "";
  const referenceDetails = props.refdetails ? props.refdetails : [];
  const [initialOptions, setFieldOptions] = useState({});
  const [selectedOption, setDefaultOption] = useState({});
  useEffect(() => {
    (async () => {
      await getReferenceList("");
    })();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const getReferenceList = async (prefix) => {
    if (referenceDetails.length > 0) {
      let params = {
        ENTITY: "FORMPROCESS",
        OPERATION: "GET_ALL_REFERENCE",
        REFMODULENAME: referenceDetails[0],
        REFFORMNAME: referenceDetails[1],
        REFFIELDNAME: referenceDetails[2],
        REFNTHINSTANCE: referenceDetails[3],
        DEFAULTDATA: selectedOption[0] !== undefined ? selectedOption[0].value : defaultVal,
        SEARCHVALUE: prefix,
      };

      let response = await requestApi.processServerRequest(
        params,
        "WebV2",
        "get"
      );
      if (response.issuccess) {
        let data = response.data;
        setFieldOptions(data[0]);
        if (data[1].label != "") {
          setDefaultOption([data[1]]);
          props.onChangeEvent(false, data[1], props.attributes);
        } else if (data[0].length > 0) {
          setDefaultOption([data[0][0]]);
          props.onChangeEvent(false, data[0][0], props.attributes);
        }
      }
    }
  };

  const handleKeyupAction = async (inputValue) => {
    if (inputValue.length >= 3) {
      await getReferenceList(inputValue);
    }
  };

  const onChange = (selectedItem) => {
    setDefaultOption([selectedItem]);
    if (props.onChangeEvent) {
      props.onChangeEvent(false, selectedItem, props.attributes);
    }
  };

  let dataValue = "";
  if (selectedOption != undefined && selectedOption[0] != undefined) {
    dataValue = selectedOption[0].value;
    if(props.valueProp != undefined && props.valueProp == "label"){
      dataValue = selectedOption[0].label;
    }
  }

  return (
    <div
      className={cx(props.customClass)}
      {...props.attributes}
      data-value={dataValue}
    >
      <Select
        value={selectedOption}
        onChange={(selectedItem) => onChange(selectedItem)}
        onInputChange={(e) => {
          handleKeyupAction(e);
        }}
        options={initialOptions}
        className={`basic-multi-select ${props.inputClass}`}
        classNamePrefix="wa-select"
        menuPosition="fixed"
      />
    </div>
  );
};

export default CratioLookup;
