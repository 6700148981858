import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container, Form, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from"react-select";
import Switch from "react-switch";
import {
    setAppCustomTitle
} from "../../reducers/ThemeOptions";
import Common from "../Common";
import { 
    getTimeZonesList, 
    populateFormDetails, 
    parseJSON, 
    getObjectByValue,
    prepareSearchConditionComponent,
    getContactLinkedData,
    isMobile,
    confirmBox,
    showToast
} from "../../Common/Appcommon";
import requestApiService from "../../services/requestApi.service";
import { userActionList } from "./assets/Constant";
import VariationContainer from "./VariationContainer";
import VariablesContainer from "./VariablesContainer";
import SearchPanel from "../Search/SearchPanel";

const OutboundOperations = (props) => {
    const contactform = "table_74";
    //const listform = "table_81";
    const tagname = "table_82";
    const formname = "table_83";
    const matchAll = true;
    const { type } = props;
    const { formId } = useParams();
    const subBtnRef = useRef();
    const getVariationRef = useRef();
    const getVariablesRef = useRef();
    let searchActionCB = false;
    const [contactFields, setContactFields] = useState([]);
    const [contactFormFields, setContactFormFields] = useState([]);
    const [formData, setFormData] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [numberList, setNumberList] = useState([]);
    const [sComponents, setSearchComponents] = useState([]);
    const [mainLoader, setMainLoader] = useState(true);
    const [knowledgeInfo, setKnowledgeInfo] = useState();
    const [tagsOptions, setTagsOptions] = useState([]);
    const [isSendSetLive, setIsSendSetLive] = useState(false);
    //const [listOptions, setListOptions] = useState([]);
    const { handleSubmit, control, formState: { errors } } = useForm();

    const customStyles = {
        menu: (base) => ({
          ...base,
          zIndex: 1050, // Adjust based on your layout
        }),
    };

    useEffect(() => {
        const fetchTimeZones = async () => {
            let numbersList = await loadPhoneNumbers();
            setNumberList(numbersList);
            let formdetails = await loadContactFormDetails();
            let timezones = getTimeZonesList();
            setTimeZones(timezones);
            await getFieldOptions();
            if(formId){
                await loadRowData(numbersList, formdetails);
            }else{
                loadDefaultData(timezones)
            }
            setMainLoader(false);
        };
        fetchTimeZones();
    }, []);

    const getFieldOptions = async () => {
        //let listOptions = await getContactLinkedData(listform) ?? [];
        let tagOptions = await getContactLinkedData(tagname) ?? [];
        //setListOptions(listOptions);
        setTagsOptions(tagOptions);
    }

    const loadRowData = async (numbersList, formdetails) => {
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_FORM_RECORD",
            FORMNAME: formname,
            FORMID: formId,
            type: type
        }
        let response = await requestApiService.processServerRequest(params);
        if(response.issuccess){
            let data = response.data;
            if(Object.keys(data).length > 0){
                let Followups = data.Followups;
                let Work_Hours = data.Work_Hours;
                let Additional_Configs = data.Additional_Configs;
                let filters = data.filters ?? "";
                Followups = parseJSON(Followups);
                Work_Hours = parseJSON(Work_Hours);
                let System_Prompt = null;
                if(data.phone_number && data.phone_number.System_Prompt){
                    System_Prompt = data.phone_number.System_Prompt;
                }
                setKnowledgeInfo(System_Prompt);
                Additional_Configs = parseJSON(Additional_Configs);
                data["trigger_at"] = getObjectByValue(userActionList, data.triggerAt) ?? "";
                data["phone_number"] = getObjectByValue(numbersList, data.table_247_0_table_247_id) ?? "";
                let searchcomponent = [];
                if(filters !== ""){
                    searchcomponent = prepareSearchConditionComponent(filters, formdetails);
                }
                
                let mergedData = { ...data, ...Followups, ...Work_Hours, ...Additional_Configs };
                setFormData(mergedData);
                setSearchComponents(searchcomponent);

            }else{
                // If data is empty, load the 'new' URL
                window.location.hash = `#/${type}/new`;
            }
        }
    }

    const loadDefaultData = (timeZones) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ["time_zone"]: timeZones[0] ?? {},
            ["start_time"]: "10:00",
            ["end_time"]: "08:00",
        }));
    }
    
    const loadContactFormDetails = async () => {
        let skipFields = [
            `${contactform}_id`,
            'Lead_ID',
            'wsapp_recent_activity',
            'wsapp_convo_status',
            'wsapp_unread_count',
            'wsapp_optin_status',
            'wsapp_recent_msg_id',
            'wsapp_enabled',
            'wsapp_blocked',
            'wsapp_opt',
            'wsapp_last_delivered',
            'wsapp_last_read',
            'wsapp_last_replied',
            'wsapp_last_sent_msg_id',
            'wsapp_last_sent_msg_id',
            'wsapp_completed_campaign'
        ];
        let fieldList = [
            {value: "fallback", label: "Use Fallback"}
        ];
        let formDetail = await populateFormDetails(contactform);
        let fieldDetails = formDetail[1];
        fieldDetails && fieldDetails.map((field) => {
            let fieldName = field[2];
            let fieldLabel = field[3];
            let fieldType = field[4];
            let isConfigHide = parseInt(field[10]);
            let isViewHide = parseInt(field[11]);
            if (!isConfigHide && !isViewHide) {
                if (fieldType !== 'subtable' && fieldType !== 'reference_entityid' && fieldType !== "reference_group" && fieldType !== "form_history") {
                    if (!skipFields.includes(fieldName)) {
                        if (fieldLabel !== undefined && fieldName !== undefined) {
                            fieldList.push({
                                value: fieldName, label: fieldLabel
                            })
                        }
                    }
                }
            }
        });
        setContactFields(fieldList);
        setContactFormFields(formDetail);
        return formDetail;
    }

    const loadPhoneNumbers =  async () =>{
        let response = [];
        let params = {
            ENTITY: "AIPROCESSOR",
            OPERATION: "GET_PHONENO_OPTIONS",
            FORMNAME: formname,
        }
        let result = await requestApiService.processServerRequest(params);
        if(result.issuccess){
            response = result.data;
        }
        return response;   
    }

    const handleSwitchChange = (name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: !prevFormData[name],
        }));
    };

    const handleSelectChange = (name, option, formname = "") => {
        if(name === "phone_number"){
            setKnowledgeInfo(option.System_Prompt ?? null);
        }
        if(formname !== ""){
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: {
                    ...(prevFormData[name] || {}), // Ensure `prevFormData[name]` is an object
                    [formname]: option, // Add or update the nested property
                },
            }));
        }else{
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: option,
            }));
        }
    };

    const processSendandSetLive = async () => {
        let isValidSearch = await searchActionCB("validateInput");
        if(!isValidSearch){
            let filters = await searchActionCB("getSearchString", {
                modeOfSearch: matchAll ? "AND" : "OR",
            });
            if(filters !== ""){
                let params = {
                    ENTITY: "AIPROCESSOR",
                    OPERATION: "GET_CONTACTS_BY_FILTERS",
                    FORMNAME: contactform,
                    filters: filters,
                    countonly: true
                }
                let response = await requestApiService.processServerRequest(params);
                if(response.issuccess){
                    let contactcount = response.data;
                    setIsSendSetLive(true);
                    let title = "You are ready to go!";
                    let message = "You have " + contactcount + " recipient(s) to send this message currently, but once you set live it will send to the recipient(s) who meets the audience condition.";
                    if (contactcount === 0) {
                        title = "The estimated recipient count is zero.";
                        message = "You must include at least one active recipient to send or schedule this campaign.";
                    }
                    let confirm = await confirmBox(title, message,'','Send','Cancel');
                    if(confirm && contactcount > 0){
                        if (subBtnRef.current) {
                            subBtnRef.current.click();
                        }
                    }else{
                        setIsSendSetLive(false);
                    }
                }
            }
        }
    }

    const handleBtnClick = async (e) => {
        let { name } = e.target;
        if(name === "cancel"){
            window.location.hash = `#/${type}s`;
        }else if(name === "save"){
            if (subBtnRef.current) {
                subBtnRef.current.click();
            }
        }else if(name === "send"){
            await processSendandSetLive();
        }
    }

    const onInputChange = (e) => {
        let {name, value} = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const getActionKeys = (obj) => {
        return Object.keys(obj)
            .filter(key => key.startsWith("action_"))
            .reduce((acc, key) => {
                acc[key] = obj[key];
                return acc;
            }, {});
    };

    const constructFormData = () => {
        let Work_Hours = {};
        let formdata = {};
        let add_conf = {};
    
        for (let key in formData) {
            if (key === "time_zone" || key === "start_time" || key === "end_time" || key === "isWorkHoursActive") {
                Work_Hours[key] = formData[key];
            } else if (key === "goal_complete" || key === "goal_incomplete") {
                add_conf[key] = formData[key];
            } else {
                formdata[key] = formData[key];
            }
        }

        // Merge additional configurations from `getActionKeys`
        add_conf = { ...add_conf, ...getActionKeys(formdata) };
        // Add `Work_Hours` and `Additional_Configs` to `formdata`
        formdata["Work_Hours"] = Work_Hours;
        formdata["Additional_Configs"] = add_conf;

        return formdata;
    };

    const onSearchClickRegister = (callback) => {
        searchActionCB = callback;
    }

    const saveFormData = async () => {
        let formdata = constructFormData();
        if(getVariationRef.current){
            let variationvalues = getVariationRef.current.returnVariationValues();
            formdata["Followups"] = variationvalues;
        }
        if(getVariablesRef.current){
            let variablesvalues = getVariablesRef.current.returnVariablesValues();
            formdata["Knowledge_Variables"] = variablesvalues;
        }
        let isValidSearch = await searchActionCB("validateInput");
        if(!isValidSearch){
            let filters = await searchActionCB("getSearchString", {
                modeOfSearch: matchAll ? "AND" : "OR",
            });
            if(filters !== ""){
                formdata["filters"] = filters;
            }
            
            let params = {
                ENTITY: "AIPROCESSOR",
                OPERATION: "SAVE_OUTBOUND_DETAILS",
                FORMINPUT: formdata,
                type: type,
                FORMNAME: formname,
                FORMID: formId,
                isSendSetLive: isSendSetLive,
            }

            let response = await requestApiService.processServerRequest(params);
            if(response.issuccess){
                let msg = "Saved Successfully";
                if(type === "campaign" && isSendSetLive){
                    msg = "Scheduled Successfully";
                }else if(type === "automation" && isSendSetLive){
                    if(formData.Status === "Inactive"){
                        msg = "Start Successfully";
                    }else if(formData.Status === "Active"){
                        msg = "Stoped Successfully";
                    }
                }
                showToast(msg);
                window.location.hash = `#/${type}s`;
            }
        }        
    }

    /**
     * Renders a container with operation buttons.
     * Buttons and their properties (label, icon, etc.) are dynamically generated using a map.
     * 
     * @function getButtonsContainer
     * @returns {JSX.Element} A fragment containing the operation buttons.
     */
    const getButtonsContainer = () => {
        let buttonsConfig = [
            { name: "cancel", icon: "fi fi-rr-left", label: "Cancel", customClass: "ms-auto" },
            { name: "save", icon: "fi fi-rr-disk", label: "Save & Close", customClass: "" },
            { name: "send", icon: "fi fi-rr-paper-plane", label: "Start / Schedule Campaign", customClass: "" },
        ];
        if(type === "automation"){
            buttonsConfig = [
                { name: "cancel", icon: "fi fi-rr-left", label: "Cancel", customClass: "ms-auto" },
                { name: "save", icon: "fi fi-rr-disk", label: "Save & Close", customClass: "" },
                { name: "send", icon: "fi fi-rr-paper-plane", label: "Save & Start Automation", customClass: "" },
            ];
        }

        return (
            <Fragment>
                <div className="operation-buttons-container d-flex">
                    {buttonsConfig.map((button, index) => (
                        <div className={button.customClass} key={index}>
                            <Button 
                                name={button.name}
                                onClick={(e) => handleBtnClick(e)} 
                                className={"btn-hover-shine theme-bg-g custom-display me-3 "}
                            >
                                <i className={button.icon}></i>
                                <span>{button.label}</span>
                            </Button>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
    
    const CustomComponent = (name) => {
        if(name === "Followups"){
            let mainContent = parseJSON(formData["Followups"] ?? "");
            let variations = mainContent && mainContent["variations"] ? mainContent["variations"] : [];
            let isEnabled = mainContent && mainContent["followup_enabled"] ? mainContent["followup_enabled"] : false;
            return (
                <VariationContainer 
                    control={control} 
                    ref={getVariationRef}
                    isEnabled={isEnabled}
                    variationsList={variations}
                    maxCount={5}
                />
            )
        }else if(name === "Knowledge" && knowledgeInfo){
            const regex = /\{\{(.*?)\}\}/g;
            const matches = [...knowledgeInfo.matchAll(regex)];
            const values = matches.map(match => match[1]);
            const formdata = formData["Knowledge_Variables"] && formData["Knowledge_Variables"] !== "null" ? formData["Knowledge_Variables"] : "";

            return (
                <VariablesContainer
                    control={control} 
                    ref={getVariablesRef}
                    formdata={parseJSON(formdata)}
                    list={values}
                    formFields={contactFields}
                />
            )
        }else if(name === "filters"){
            const hasFields = contactFormFields && contactFormFields[1];
            const isFormInvalid = !formId || (formId && sComponents.length > 0);
            if(hasFields && isFormInvalid){
                return (
                    <SearchPanel
                        fieldDetails={contactFormFields[1]}
                        onSearchClickRegister={onSearchClickRegister}
                        preSearchComponents={sComponents}
                        forceFilter={true}
                        filterType={"trigger"}
                        actionType={"user"}
                        type={"outbound"}
                    />
                )
            }
        }
        return null;
    }


    const getMainContainer = () => {
        let stepindex = 0;
        return(
            <Fragment>
                <Container fluid>
                    <div className="outbound-config">
                        <Form onSubmit={handleSubmit(saveFormData)}>
                            <div className="m-3 vertical-timeline-without-image">
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                        { stepindex = stepindex + 1 }
                                        <div className="outbound-stepnumber">{stepindex}</div>
                                        <span className="ms-3">{type === "campaign" ? "Name your Campaign" : "Name your Automation"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"Name"}>Name</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={`col-md-12`}>
                                                <Controller
                                                    name={"Name"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["Name"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) => 
                                                        <input
                                                            {...field}
                                                            type={"text"}
                                                            name={"Name"}
                                                            className="form-control"
                                                            placeholder={"Name ..."}
                                                            value={formData["Name"] || ""}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.value)
                                                                onInputChange({
                                                                    target: { name: "Name", value: e.target.value },
                                                                })}
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    }
                                                />
                                                {
                                                    errors["Name"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["Name"].message}</em>
                                                    )
                                                }
                                            </div>
                                        </Row>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"Description"}>Description</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={`col-md-12`}>
                                                <Controller
                                                    name={"Description"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <textarea
                                                            {...field}
                                                            name={"Description"}
                                                            className="form-control"
                                                            placeholder={"Enter Description..."}
                                                            value={formData["Description"] || ""}
                                                            onChange={(e) =>
                                                                onInputChange({
                                                                    target: { name: "Description", value: e.target.value },
                                                                })
                                                            }
                                                            autoComplete="off"
                                                        />
                                                        )
                                                    }
                                                />
                                                {
                                                    errors["Description"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["Description"].message}</em>
                                                    )
                                                }
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                                {
                                    type === "automation" && 
                                    <div className="outbound-section row mt-3">
                                        <div className="group-header d-flex">
                                            { stepindex = stepindex + 1 }
                                            <div className="outbound-stepnumber">{stepindex}</div>
                                            <span className="ms-3">{"Set action type and trigger preference"}</span>
                                        </div>
                                        <div className="ms-4">
                                            <Row>
                                                <Col lg={10}>
                                                    <label className="mt-2 mb-1" htmlFor={"Name"}>Choose a Trigger</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className={`col-md-12`}>
                                                    <Controller
                                                        name={"Name"}
                                                        control={control}
                                                        rules={{
                                                            required: !formData["Name"] ? "This field is required" : false,
                                                        }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={userActionList}
                                                                onChange={(e) => handleSelectChange("trigger_at", e)}
                                                                value={formData["trigger_at"]}
                                                                classNamePrefix="wa-select"
                                                                className="basic-select"
                                                                menuPlacement={"auto"}
                                                                styles={customStyles} 
                                                            />
                                                            )
                                                        }
                                                    />
                                                    {
                                                        errors["trigger_at"] && (
                                                            <em className="error invalid-feedback d-flex">{errors["trigger_at"].message}</em>
                                                        )
                                                    }
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                            { stepindex = stepindex + 1 }
                                        <div className="outbound-stepnumber">{stepindex}</div>
                                        <span className="ms-3">{type === "campaign" ? "Who will receive this campaign" : "Set condition to perform trigger action"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"filters"}>Record matching certain conditions</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={`col-md-12`}>
                                                {
                                                    CustomComponent("filters")
                                                }
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                        { stepindex = stepindex + 1 }
                                        <div className="outbound-stepnumber">{stepindex}</div>
                                        <span className="ms-3">{"Set Up a Phone Number and an AI Agent"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"phone_number"}>Choose a Phone Number</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <Controller
                                                    name={"phone_number"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["phone_number"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={numberList}
                                                            onChange={(e) => handleSelectChange("phone_number", e)}
                                                            value={formData["phone_number"]}
                                                            classNamePrefix="wa-select"
                                                            className="basic-select"
                                                            menuPlacement={"auto"}
                                                            styles={customStyles} 
                                                        />
                                                        )
                                                    }
                                                />
                                                {
                                                    errors["phone_number"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["phone_number"].message}</em>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                {
                                                    CustomComponent("Knowledge")
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                        { stepindex = stepindex + 1 }
                                        <div className="outbound-stepnumber">{stepindex}</div>
                                        <span className="ms-3">{"Schedule Follow-ups"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={12}>
                                                {
                                                    CustomComponent("Followups")
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                        { stepindex = stepindex + 1 }
                                        <div className="outbound-stepnumber">{stepindex}</div>
                                        <span className="ms-3">{"Working Hours"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"Work_Hours"}>Working Hours (Set the working hours for your assistant. All calls are only sent during these hours.)</label>
                                            </Col>
                                            <Col lg={1}>
                                                <Switch
                                                    onColor={"#034737"}
                                                    onChange={() => handleSwitchChange("isWorkHoursActive")} 
                                                    checked={formData["isWorkHoursActive"]}
                                                    checkedIcon={
                                                        <div className='off-switch-btn ms-1'>
                                                            On
                                                        </div>
                                                    }
                                                    uncheckedIcon={
                                                        <div className='on-switch-btn'>
                                                            Off
                                                        </div>
                                                    }
                                                    width={60}
                                                    height={20}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Controller
                                                    name={"time_zone"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["time_zone"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={timeZones}
                                                            onChange={(e) => handleSelectChange("time_zone", e)}
                                                            value={formData["time_zone"]}
                                                            classNamePrefix="wa-select"
                                                            className="basic-select"
                                                            menuPlacement={"auto"}
                                                            styles={customStyles} 
                                                            isDisabled={!formData["isWorkHoursActive"]}
                                                        />
                                                        )
                                                    }
                                                />
                                                {
                                                    errors["time_zone"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["time_zone"].message}</em>
                                                    )
                                                }
                                            </Col>
                                            <Col lg={3}>
                                                <Controller
                                                    name={"start_time"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["start_time"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="time"
                                                            options={timeZones}
                                                            onChange={(e) => onInputChange(e)}
                                                            value={formData["start_time"]}
                                                            className="form-control"
                                                            menuPlacement={"auto"}
                                                            disabled={!formData["isWorkHoursActive"]}
                                                            autoComplete="off"
                                                        />
                                                        )
                                                    }
                                                />
                                                {
                                                    errors["start_time"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["start_time"].message}</em>
                                                    )
                                                }
                                            </Col>
                                            <Col lg={3}>
                                                <Controller
                                                    name={"end_time"}
                                                    control={control}
                                                    rules={{
                                                        required: !formData["end_time"] ? "This field is required" : false,
                                                    }}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="time"
                                                            options={timeZones}
                                                            onChange={(e) => onInputChange(e)}
                                                            value={formData["end_time"]}
                                                            className="form-control"
                                                            menuPlacement={"auto"}
                                                            disabled={!formData["isWorkHoursActive"]}
                                                            autoComplete="off"
                                                        />
                                                        )
                                                    }
                                                />
                                                {
                                                    errors["end_time"] && (
                                                        <em className="error invalid-feedback d-flex">{errors["end_time"].message}</em>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                        { stepindex = stepindex + 1 }
                                        <div className="outbound-stepnumber">{stepindex}</div>
                                        <span className="ms-3">{"Actions"}</span>
                                    </div>
                                    <div className="ms-4">
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"action_1"}>Auto un-subscribe contact if call sending fails.</label>
                                            </Col>
                                            <Col lg={1}>
                                                <Switch
                                                    onColor={"#034737"}
                                                    onChange={() => handleSwitchChange("action_1")} 
                                                    checked={formData["action_1"]}
                                                    checkedIcon={
                                                        <div className='off-switch-btn ms-1'>
                                                            On
                                                        </div>
                                                    }
                                                    uncheckedIcon={
                                                        <div className='on-switch-btn'>
                                                            Off
                                                        </div>
                                                    }
                                                    width={60}
                                                    height={20}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <label className="mt-2 mb-1" htmlFor={"action_2"}>Automatically add tags to contacts as per the Call Status.</label>
                                            </Col>
                                            <Col lg={1}>
                                                <Switch
                                                    onColor={"#034737"}
                                                    onChange={() => handleSwitchChange("action_2")} 
                                                    checked={formData["action_2"]}
                                                    checkedIcon={
                                                        <div className='off-switch-btn ms-1'>
                                                            On
                                                        </div>
                                                    }
                                                    uncheckedIcon={
                                                        <div className='on-switch-btn'>
                                                            Off
                                                        </div>
                                                    }
                                                    width={60}
                                                    height={20}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            formData["action_2"] &&
                                            <Row>
                                                <Col lg={6}>
                                                    <label className="mt-2 mb-1" htmlFor={"goal_complete"}>Goal Completed</label>
                                                    <Controller
                                                        name={"goal_complete"}
                                                        control={control}
                                                        rules={{
                                                            required: !formData["goal_complete"] || !formData["goal_complete"][tagname] ? "This field is required" : false,
                                                        }}
                                                        render={({ field }) => 
                                                            <Select
                                                                {...field}
                                                                options={tagsOptions}
                                                                onChange={(e) => handleSelectChange("goal_complete", e, tagname)}
                                                                value={formData["goal_complete"] && formData["goal_complete"][tagname] ? formData["goal_complete"][tagname] : false}
                                                                classNamePrefix="wa-select"
                                                                className="basic-select"
                                                                menuPlacement={"auto"}
                                                                isMulti
                                                                styles={customStyles} 
                                                            />
                                                        }
                                                    />
                                                    {
                                                        errors["goal_complete"] && (
                                                            <em className="error invalid-feedback d-flex">{errors["goal_complete"].message}</em>
                                                        )
                                                    }
                                                </Col>
                                                <Col lg={6}>
                                                    <label className="mt-2 mb-1" htmlFor={"goal_incomplete"}>Goal Incompleted</label>
                                                    <Controller
                                                        name={"goal_incomplete"}
                                                        control={control}
                                                        rules={{
                                                            required: !formData["goal_incomplete"] || !formData["goal_incomplete"][tagname] ? "This field is required" : false,
                                                        }}
                                                        render={({ field }) => 
                                                            <Select
                                                                {...field}
                                                                options={tagsOptions}
                                                                onChange={(e) => handleSelectChange("goal_incomplete", e, tagname)}
                                                                value={formData["goal_incomplete"] && formData["goal_incomplete"][tagname] ? formData["goal_incomplete"][tagname] : false}
                                                                classNamePrefix="wa-select"
                                                                className="basic-select"
                                                                menuPlacement={"auto"}
                                                                isMulti
                                                                styles={customStyles} 
                                                            />
                                                        }
                                                    />
                                                    {
                                                        errors["goal_incomplete"] && (
                                                            <em className="error invalid-feedback d-flex">{errors["goal_incomplete"].message}</em>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </div>
                                <div className="outbound-section row mt-3">
                                    <div className="group-header d-flex">
                                        <div className="outbound-step-text">END</div>
                                    </div>
                                </div>
                            </div>
                            <button className="hide" type={"submit"} ref={subBtnRef}>save</button>
                        </Form>
                    </div>
                </Container>
            </Fragment>
        )
    }
    
    let title = "";
    if(formId){
        if(type === "automation"){
            title = "Automations";
        }else{
            title = "Update Campaign";
        }
    }else{
        if(type === "automation"){
            title = "New Automations";
        }else{
            title = "Create Campaign";
        }
    }
    
    return (
        <Fragment>
            <Common 
                title={title} 
                titleContent={
                    <span>{title}</span>
                }
                titleBody={
                    !isMobile() && 
                    getButtonsContainer()
                }
                mainLoader={mainLoader}
                mainContent={
                    <div className="m-3 outbound-config-container w-100">
                        {
                            isMobile() && 
                            getButtonsContainer()
                        }
                        {
                            getMainContainer()
                        }
                    </div>
                }
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    appCustomTitle: state.ThemeOptions.appCustomTitle
});

const mapDispatchToProps = dispatch => ({
    setAppCustomTitle: title => dispatch(setAppCustomTitle(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboundOperations);